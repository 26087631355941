import * as React from "react";
import {
  Alignment,
  Button,
  Callout,
  Checkbox,
  Intent,
  Navbar,
  TextArea,
} from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import { KunciContext } from "../../../inc/kunci";
import { ApiDataContext } from "../../../provider/apiData";
import CenteredSpinner from "../../../components/CenteredSpinner";
import {
  getActionKeyLabelSuffix,
  KeyboardActions,
} from "../../../inc/keyboard";
import { LayoutContext } from "../../../provider/layout";
import { IReadHitWindowProps } from "../index";
import { components } from "../../../types/openapi";

import "./index.scss";
import { getName } from "../../../inc/matchItem";
import useSearchTopicMap from "../../../hooks/useSearchTopicMap";
import { getStoredMatchItemHitActionResult } from "../../../inc/matchItemHit";
import useEmail from "../../../hooks/useEmail";
import useChatMessage from "../../../hooks/useChatMessage";
import useIsCurrentClaimReadOnly from "../../../hooks/useIsCurrentClaimReadOnly";
import { useLocation, useParams } from "react-router-dom";

const Form = ({ processHit }: IReadHitWindowProps) => {
  const {
    matchItemMap,
    currentClaimedMatchItemHit,
    currentSearchTopicClaim,
    setCurrentClaimedMatchItemHit,
    profile,
  } = React.useContext(ApiDataContext);
  const isReadOnly = useIsCurrentClaimReadOnly();
  const generateEml = useEmail();
  const generateChatMessage = useChatMessage();
  const { searchTopicMap } = useSearchTopicMap();
  const location = useLocation();
  const matchItem =
    matchItemMap && currentClaimedMatchItemHit
      ? matchItemMap[currentClaimedMatchItemHit.hit.matchItemId]
      : undefined;

  const { isCommentOpen, setIsCommentOpen, isLast, setIsLast } =
    React.useContext(LayoutContext);
  const { auth } = React.useContext(KunciContext);
  const { userRoles } = auth?.jwt as components["schemas"]["Jwt"];
  const params = useParams();

  const searchTopic =
    searchTopicMap &&
    currentClaimedMatchItemHit &&
    currentClaimedMatchItemHit.hit.searchTopicId
      ? searchTopicMap[currentClaimedMatchItemHit.hit.searchTopicId]
      : null;

  const versionAction = params.version
    ? currentClaimedMatchItemHit?.actions.find(
        (action) => `${action.version}` === params.version
      )
    : currentClaimedMatchItemHit?.actions[0];
  const setComment = React.useCallback(
    (comment: string) => {
      if (!currentClaimedMatchItemHit || !profile || !versionAction) {
        return;
      }
      setCurrentClaimedMatchItemHit({
        ...currentClaimedMatchItemHit,
        actions: [
          {
            ...versionAction,
            comment,
          },
        ],
      });
    },
    [
      currentClaimedMatchItemHit,
      versionAction,
      setCurrentClaimedMatchItemHit,
      profile,
    ]
  );

  if (matchItem === undefined) {
    return <div className="mosaic-window__disabled-overlay" />;
  }

  if (
    !currentClaimedMatchItemHit ||
    !searchTopicMap ||
    !profile ||
    !matchItem
  ) {
    return <CenteredSpinner />;
  }

  const searchTermCount = currentClaimedMatchItemHit.searchTerms.filter(
    (searchTerm) => searchTerm.searchTerm.indexOf("*") === -1
  ).length;

  const isAdministrator = userRoles.indexOf("admin") >= 0;
  const isMonitoringPlusUser = userRoles.indexOf("monitoringPlusUser") >= 0;
  const isPowerUser = userRoles.indexOf("powerUser") >= 0;
  const isMonitoringPlus = location.pathname.indexOf("/monitoringPlus") >= 0;
  const isDeleteRequest = location.pathname.indexOf("/deleteRequest") >= 0;

  if (!profile) {
    return <CenteredSpinner />;
  }

  return (
    <div
      className={`routes__read-matchItemHit__window__form routes__read-matchItemHit__window__form--${getStoredMatchItemHitActionResult(
        currentClaimedMatchItemHit.hit,
        currentClaimedMatchItemHit.actions
      )}`}
    >
      {searchTopic ? (
        <>
          <div className="routes__read-matchItemHit__window__form__matchItemHit-context">
            <div>
              <dl>
                <dt>Publicatie</dt>
                <dd>{matchItem ? getName(matchItem) : null}</dd>
                <dt>Omschrijving</dt>
                <dd>{searchTopic.description}</dd>
                {searchTermCount ? <dt>Gevonden</dt> : null}
                {searchTermCount ? <dd>{searchTermCount}</dd> : null}
              </dl>
            </div>
            {searchTopic.comment ? (
              <div>
                <dl>
                  <dt>Commentaar</dt>
                  <dd>{searchTopic.comment}</dd>
                </dl>
              </div>
            ) : null}
          </div>
          {processHit ? (
            <div
              style={{
                display: "flex",
                height: 38,
                margin: 8,
                justifyContent: "space-between",
              }}
            >
              <Button
                icon="tick"
                intent={Intent.SUCCESS}
                text={`Goed${getActionKeyLabelSuffix(
                  profile,
                  KeyboardActions.HIT_APPROVE
                )}`}
                large={true}
                disabled={
                  ["approved", "approving"].indexOf(
                    getStoredMatchItemHitActionResult(
                      currentClaimedMatchItemHit.hit,
                      currentClaimedMatchItemHit.actions
                    )
                  ) >= 0 ||
                  (!isAdministrator && !isMonitoringPlusUser && isReadOnly)
                }
                onClick={() => processHit("approved")}
              />
              {currentSearchTopicClaim ? (
                <Button
                  icon="help"
                  intent={Intent.WARNING}
                  text={`Nog geen oordeel ${getActionKeyLabelSuffix(
                    profile,
                    KeyboardActions.HIT_SKIP
                  )}`}
                  large={true}
                  onClick={() => processHit("none" as any)}
                />
              ) : (
                <Button
                  icon="help"
                  intent={Intent.WARNING}
                  text={`In de wacht ${getActionKeyLabelSuffix(
                    profile,
                    KeyboardActions.HIT_SKIP
                  )}`}
                  large={true}
                  onClick={() => processHit("skipped", versionAction?.comment)}
                  disabled={isReadOnly || !versionAction?.comment}
                  title={
                    versionAction?.comment
                      ? undefined
                      : "Alleen mogelijk na plaatsen opmerking"
                  }
                />
              )}
              <Button
                icon="error"
                intent={Intent.DANGER}
                text={`Fout${getActionKeyLabelSuffix(
                  profile,
                  KeyboardActions.HIT_REJECT
                )}`}
                large={true}
                disabled={isReadOnly}
                onClick={() => processHit("rejected")}
              />
            </div>
          ) : null}
        </>
      ) : !processHit ? (
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          Zoekopdracht bij verwijderverzoek niet gevonden
        </div>
      ) : currentClaimedMatchItemHit.hit.searchTopicId ? (
        <div style={{ padding: 20 }}>
          <Callout
            title="Zoekopdracht niet gevonden"
            className="m-5"
            intent={Intent.WARNING}
          >
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              Verwerken niet mogelijk
            </div>
            <Button
              className="m-5"
              disabled={isReadOnly}
              intent={Intent.PRIMARY}
              large
              onClick={() => {
                processHit("isError", `SearchTopic not found`);
              }}
            >
              Doorgaan
            </Button>
          </Callout>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <Button
              className="m-5"
              intent={Intent.PRIMARY}
              disabled={isReadOnly}
              large
              onClick={() => {
                processHit("isNoAction");
              }}
            >
              Doorgaan
            </Button>
          </div>
        </div>
      )}
      {isMonitoringPlus ? null : (
        <Navbar style={{ height: 40, padding: 8 }}>
          <Navbar.Group align={Alignment.LEFT}>
            {isDeleteRequest ? null : (
              <Checkbox
                label={`Laatste actie${getActionKeyLabelSuffix(
                  profile,
                  KeyboardActions.HIT_MARK_LAST
                )}`}
                style={{ marginBottom: 0 }}
                checked={isLast}
                onChange={() => {
                  setIsLast(!isLast);
                }}
              />
            )}
          </Navbar.Group>
          {isAdministrator || isPowerUser || isDeleteRequest ? (
            <Navbar.Group align={Alignment.CENTER}>
              <Button
                style={{ marginLeft: 50 }}
                icon="chat"
                onClick={() => generateChatMessage()}
                text="Chat accountmanager"
              />
              <Button
                style={{ marginLeft: 50 }}
                icon="envelope"
                onClick={() => generateEml()}
                text="Mail accountmanager"
              />
            </Navbar.Group>
          ) : null}
          <Navbar.Group align={Alignment.RIGHT}>
            <Popover2
              enforceFocus={false}
              position="auto"
              usePortal={true}
              isOpen={isCommentOpen}
              onClose={() => {
                setIsCommentOpen(false);
              }}
              content={
                <form
                  onKeyDown={(e) => {
                    if (e.ctrlKey && e.key === "Enter") {
                      setIsCommentOpen(false);
                    }
                  }}
                >
                  <TextArea
                    autoFocus={true}
                    disabled={!versionAction}
                    value={
                      isReadOnly
                        ? currentClaimedMatchItemHit.actions
                            .reduce<string[]>((prev, action) => {
                              if (action.comment) {
                                prev.push(action.comment);
                              }
                              return prev;
                            }, [])
                            .join(",")
                        : versionAction?.comment
                    }
                    style={{ width: 800, height: 200 }}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                      setComment(e.target.value);
                    }}
                  />
                </form>
              }
            >
              <Button
                text={`Opmerking plaatsen${getActionKeyLabelSuffix(
                  profile,
                  KeyboardActions.HIT_START_COMMENT
                )}`}
                small={true}
                disabled={!versionAction}
                intent={
                  versionAction && versionAction.comment
                    ? Intent.PRIMARY
                    : Intent.NONE
                }
                onClick={() => {
                  setIsCommentOpen(true);
                }}
              />
            </Popover2>
          </Navbar.Group>
        </Navbar>
      )}
    </div>
  );
};

export default Form;
