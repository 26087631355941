import React from "react";
import { components } from "../types/openapi";
import { ApiDataContext } from "../provider/apiData";
import useSearchTopicMap from "./useSearchTopicMap";
import { KunciContext } from "../inc/kunci";

const useSearchTopicClaims = () => {
  const { searchTopicClaims, setSearchTopicClaims } =
    React.useContext(ApiDataContext);
  const { axios } = React.useContext(KunciContext);
  const { searchTopicMap } = useSearchTopicMap();

  React.useEffect(() => {
    if (!searchTopicMap || searchTopicClaims !== undefined) {
      return;
    }
    setSearchTopicClaims((searchTopicClaims) => {
      if (searchTopicClaims !== undefined) {
        return searchTopicClaims;
      }
      axios
        .get<components["schemas"]["SearchTopicClaim"][]>(
          `/monitoringPlus/searchTopicClaims`
        )
        .then((res) => {
          setSearchTopicClaims(
            res.data.sort((a, b) => {
              const searchTopicA = searchTopicMap[a.searchTopicId] as
                | components["schemas"]["SearchTopic"]
                | undefined;
              const searchTopicB = searchTopicMap[b.searchTopicId] as
                | components["schemas"]["SearchTopic"]
                | undefined;

              if (!searchTopicA) {
                console.log("Missing", a);
                return 1;
              }
              if (!searchTopicB) {
                console.log("Missing", b);
                return -1;
              }

              if (searchTopicA.customerId === searchTopicB.customerId) {
                return searchTopicA.searchTopicName.toLowerCase() <
                  searchTopicB.searchTopicName.toLowerCase()
                  ? -1
                  : 1;
              }
              return searchTopicA.customerId < searchTopicB.customerId ? -1 : 1;
            })
          );
        });
      return null;
    });
  }, [axios, searchTopicClaims, searchTopicMap, setSearchTopicClaims]);

  return searchTopicClaims;
};

export default useSearchTopicClaims;
