import * as React from "react";
import { ApiDataContext } from "../../../provider/apiData";
import CenteredSpinner from "../../../components/CenteredSpinner";
import ClippingPanel from "./ClippingPanel";
import PagePanel from "./PagePanel";
import { Tabs, Tab, Slider, Button } from "@blueprintjs/core";
import { Intent } from "@blueprintjs/core/lib/esnext";
import "./index.scss";
import { IReadHitWindowProps } from "../index";
import { usePublicationIssue } from "../../../hooks/usePublicationIssue";

const MINIMAL_IMAGE_WIDTH = 100;

const HitSource = ({ onForcedTitleCheckSubmit }: IReadHitWindowProps) => {
  const {
    currentClaimedMatchItemHit,
    currentSearchTopicClaim,
    matchItemMap,
    profile,
    setCurrentClaimedMatchItemHit,
  } = React.useContext(ApiDataContext);
  const [selectedTabId, setSelectedTabId] = React.useState<"clipping" | "page">(
    "clipping"
  );

  const matchItem =
    matchItemMap && currentClaimedMatchItemHit
      ? matchItemMap[currentClaimedMatchItemHit.hit.matchItemId]
      : undefined;
  const publicationIssue = usePublicationIssue(matchItem?.publicationIssueId);
  const [imageWidth, setImageWidth] = React.useState(MINIMAL_IMAGE_WIDTH);

  React.useEffect(() => {
    const wheelHandler = (e: WheelEvent) => {
      if (e.ctrlKey) {
        setImageWidth((imageWidth) => {
          const newImageWidth = Math.round(imageWidth - e.deltaY);
          return newImageWidth > MINIMAL_IMAGE_WIDTH
            ? newImageWidth
            : MINIMAL_IMAGE_WIDTH;
        });
        e.stopPropagation();
        e.preventDefault();
      }
    };
    window.addEventListener("wheel", wheelHandler, { passive: false });
    return () => {
      window.removeEventListener("wheel", wheelHandler);
    };
  }, []);

  if (matchItem === undefined) {
    return <div className="mosaic-window__disabled-overlay" />;
  }
  if (!matchItem || !currentClaimedMatchItemHit) {
    return <CenteredSpinner />;
  }
  return (
    <div
      className="read-match-item-hit__window__hit-source"
      ref={(hitSourceWindowRef) => {
        if (hitSourceWindowRef && imageWidth === MINIMAL_IMAGE_WIDTH) {
          const newImageWidth = hitSourceWindowRef.offsetWidth - 50;
          setImageWidth(
            newImageWidth > MINIMAL_IMAGE_WIDTH
              ? newImageWidth
              : MINIMAL_IMAGE_WIDTH
          );
        }
      }}
    >
      <div style={{ overflow: "auto", flex: 1 }}>
        <div
          style={{
            paddingTop: 12,
            paddingLeft: 16,
            paddingRight: 16,
            display: "flex",
            alignItems: "center",
            position: "sticky",
            height: 39,
            top: 0,
            backgroundColor: "white",
          }}
        >
          <Slider
            min={100}
            max={5000}
            stepSize={100}
            labelStepSize={1000}
            labelRenderer={false}
            onChange={setImageWidth}
            value={imageWidth}
          />
          {matchItem.sourceUrl ? (
            <Button
              intent={Intent.SUCCESS}
              style={{ padding: "0 2rem", margin: "0 10px" }}
              onClick={() => {
                window.open(matchItem.sourceUrl);
              }}
            >
              Open&nbsp;URL
            </Button>
          ) : null}
          {!currentSearchTopicClaim &&
          matchItem.mediaType === "print" &&
          !onForcedTitleCheckSubmit ? (
            <div>
              <Button
                intent={Intent.DANGER}
                style={{ marginLeft: 10 }}
                small
                onClick={() => {
                  if (!profile) {
                    return;
                  }
                  setCurrentClaimedMatchItemHit({
                    ...currentClaimedMatchItemHit,
                    actions: currentClaimedMatchItemHit.actions.map((action) =>
                      action.userName === profile.userName
                        ? {
                            ...action,
                            matchItemHitActionResult: "reclipRequest",
                          }
                        : action
                    ),
                  });
                }}
              >
                Rapporteer&nbsp;onjuist&nbsp;knipsel
              </Button>
            </div>
          ) : null}
        </div>
        <div
          style={{
            paddingLeft: 16,
            paddingRight: 16,
            backgroundColor: "white",
          }}
        >
          <Tabs
            onChange={setSelectedTabId as any}
            selectedTabId={selectedTabId}
          >
            <Tab
              id="clipping"
              title="Knipsel"
              panel={<ClippingPanel imageWidth={imageWidth} />}
            />
            {publicationIssue?.pages.length ? (
              <Tab
                id="page"
                title="Pagina's"
                panel={<PagePanel imageWidth={imageWidth} />}
              />
            ) : null}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default HitSource;
