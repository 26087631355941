import { Intent } from "@blueprintjs/core";
import React from "react";
import { ApiDataContext } from "../provider/apiData";
import { components } from "../types/openapi";
import { useNavigate } from "react-router-dom";
import toaster from "../inc/toaster";
import { TUserProfile } from "../types/extendedOpenapi";
import { HITWINDOWTYPE } from "../components/mosaicWindows";
import { KunciContext } from "../inc/kunci";

const useProfile = () => {
  const { profile, setProfile } = React.useContext(ApiDataContext);
  const { axios } = React.useContext(KunciContext);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (profile !== undefined) {
      return;
    }
    axios
      .get<components["schemas"]["UserProfile"]>("/user/profile")
      .then((res) => {
        const profile = res.data;
        let appData = (profile.appData || {}) as TUserProfile["appData"];
        if (!appData.theme) {
          appData.theme = {
            font: "system",
            fontSize: 14,
            isDark: false,
            columnCount: 2,
            highlightColor1: "#ADD8E6",
            highlightColor2: "#FFFF00",
          };
        }
        if (!appData.keymap) {
          appData.keymap = {};
        }
        if (!appData.manageHitNode) {
          appData.manageHitNode = {
            direction: "row",
            first: {
              direction: "column",
              first: {
                direction: "row",
                first: {
                  direction: "row",
                  first: HITWINDOWTYPE.READ_RESULT,
                  second: HITWINDOWTYPE.TEXT,
                  splitPercentage: 15,
                },
                second: HITWINDOWTYPE.SEARCHTOPIC,
                splitPercentage: 85,
              },
              second: HITWINDOWTYPE.FORM,
              splitPercentage: 75,
            },
            second: HITWINDOWTYPE.HITSOURCE,
            splitPercentage: 70,
          };
        }
        if (!appData.readHitNode) {
          appData.readHitNode = {
            direction: "row",
            first: {
              direction: "column",
              first: {
                direction: "row",
                first: HITWINDOWTYPE.TEXT,
                second: HITWINDOWTYPE.SEARCHTOPIC,
                splitPercentage: 85,
              },
              second: HITWINDOWTYPE.FORM,
              splitPercentage: 75,
            },
            second: HITWINDOWTYPE.HITSOURCE,
            splitPercentage: 70,
          };
        }
        if (!appData.monitoringPlusNode) {
          appData.monitoringPlusNode = {
            direction: "row",
            first: {
              direction: "column",
              first: {
                direction: "row",
                first: {
                  direction: "row",
                  first: HITWINDOWTYPE.MONITORING_PLUS,
                  second: HITWINDOWTYPE.TEXT,
                  splitPercentage: 15,
                },
                second: HITWINDOWTYPE.SEARCHTOPIC,
                splitPercentage: 85,
              },
              second: HITWINDOWTYPE.FORM,
              splitPercentage: 75,
            },
            second: HITWINDOWTYPE.HITSOURCE,
            splitPercentage: 70,
          };
        }
        if (!appData.deleteRequestNode) {
          appData.deleteRequestNode = {
            direction: "row",
            first: {
              direction: "column",
              first: {
                direction: "row",
                first: {
                  direction: "row",
                  first: HITWINDOWTYPE.DELETE_REQUEST,
                  second: HITWINDOWTYPE.TEXT,
                  splitPercentage: 15,
                },
                second: HITWINDOWTYPE.SEARCHTOPIC,
                splitPercentage: 85,
              },
              second: HITWINDOWTYPE.FORM,
              splitPercentage: 75,
            },
            second: HITWINDOWTYPE.HITSOURCE,
            splitPercentage: 70,
          };
        }
        if (!appData.forcedTitleCheckNode) {
          appData.forcedTitleCheckNode = {
            direction: "row",
            first: 4,
            second: 2,
            splitPercentage: 50,
          };
        }
        setProfile({
          ...profile,
          appData,
        } as TUserProfile);
      })
      .catch((err) => {
        // token invalid or expired?
        if (err.response && err.response.status === 403) {
          navigate("/logout");
        }
        toaster.show({
          message: `Ophalen gebruikersprofiel mislukt: ${err.message}`,
          intent: Intent.DANGER,
        });
      });
  }, [axios, navigate, profile, setProfile]);

  return profile;
};

export default useProfile;
