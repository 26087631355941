import React from "react";
import { Button, MenuItem, Spinner } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import { components } from "../../../types/openapi";
import useSearchTopicMap from "../../../hooks/useSearchTopicMap";

interface ISearchTopicIdControl {
  onChange: (newValue: string) => void;
  value?: string;
}

const SearchTopicIdControl = ({ onChange, value }: ISearchTopicIdControl) => {
  const { searchTopicMap } = useSearchTopicMap();
  if (!searchTopicMap) {
    return <Spinner />;
  }
  const valueSearchTopic = value ? searchTopicMap[value] : undefined;

  return (
    <Select<components["schemas"]["SearchTopic"]>
      filterable={true}
      items={(
        Object.values(searchTopicMap).filter(
          (searchTopic) => searchTopic?.isForMonitoringPlus
        ) as components["schemas"]["SearchTopic"][]
      ).sort((a, b) => (a.customerId < b.customerId ? -1 : 1))}
      itemRenderer={(
        searchTopic: components["schemas"]["SearchTopic"],
        { handleClick, handleFocus, modifiers }
      ) => {
        return (
          <MenuItem
            selected={modifiers.active}
            disabled={modifiers.disabled}
            label={`${searchTopic.customerId} - ${searchTopic.description}`}
            text={`${searchTopic.customerId} - ${searchTopic.description}`}
            key={searchTopic.searchTopicId}
            onClick={handleClick}
            onFocus={handleFocus}
          />
        );
      }}
      itemPredicate={(query, searchTopic, _index, exactMatch) => {
        const normalizedTitle = searchTopic.description.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
          return normalizedTitle === normalizedQuery;
        } else {
          return normalizedTitle.indexOf(normalizedQuery) >= 0;
        }
      }}
      noResults={
        <MenuItem
          disabled={true}
          label="Niet gevonden."
          text="Niet gevonden."
        />
      }
      onItemSelect={(searchTopic) => onChange(searchTopic.searchTopicId)}
    >
      <Button
        text={
          valueSearchTopic
            ? valueSearchTopic.description
            : "Kies een zoekopdracht"
        }
        rightIcon="double-caret-vertical"
      />
    </Select>
  );
};

export default SearchTopicIdControl;
