import {
  Button,
  Checkbox,
  FormGroup,
  H1,
  HTMLTable,
  Icon,
  Intent,
  MenuItem,
} from "@blueprintjs/core";
import React from "react";
import { RGBColor, SketchPicker } from "react-color";
import { useNavigate } from "react-router-dom";
import { Select } from "@blueprintjs/select";
import { ApiDataContext } from "../../provider/apiData";
import Keyboard from "./Keyboard";
import { getKeyBindings, getKeys, KeyboardActions } from "../../inc/keyboard";
import actions from "../../inc/keyboard/actions.json";
import { t } from "../../inc/i18n";
import toaster from "../../inc/toaster";
import { TUserProfile } from "../../types/extendedOpenapi";

import "./index.scss";
import { KunciContext } from "../../inc/kunci";

function componentToHex(c: number) {
  var hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}

function rgbToHex(rgb: RGBColor) {
  return (
    "#" + componentToHex(rgb.r) + componentToHex(rgb.g) + componentToHex(rgb.b)
  );
}

function hexToRgb(hex: string): RGBColor | null {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

const Profile = () => {
  const { profile, setProfile } = React.useContext(ApiDataContext);
  const { axios } = React.useContext(KunciContext);
  const navigate = useNavigate();
  const [selectedKeymapActionId, setSelectedKeymapAction] =
    React.useState<keyof typeof KeyboardActions>();
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [highlightColor1, setHighlightColor1] = React.useState<RGBColor>(
    hexToRgb(profile?.appData.theme.highlightColor1 || "") || {
      r: 173,
      g: 216,
      b: 230,
    }
  );
  const [highlightColor2, setHighlightColor2] = React.useState<RGBColor>(
    hexToRgb(profile?.appData.theme.highlightColor2 || "") || {
      r: 255,
      g: 255,
      b: 0,
    }
  );

  const updateAndSaveUserProfile = React.useCallback(
    (profile: TUserProfile) => {
      setProfile(profile);
      setIsEditMode(false);
      axios
        .put(`/user/profile`, profile)
        .then(() => {
          toaster.show({
            message: `Uw voorkeuren zijn opgeslagen`,
            intent: Intent.SUCCESS,
          });
        })
        .catch((err) => {
          toaster.show({
            message: `Opslaan gebruikersprofiel mislukt: ${err.message}`,
            intent: Intent.DANGER,
          });
        });
      return;
    },
    [axios, setProfile]
  );

  React.useEffect(() => {
    const keydownHandler = (e: KeyboardEvent) => {
      if (isEditMode && profile) {
        e.preventDefault();
        if (["Shift", "Control", "Alt"].indexOf(e.key) >= 0) {
          return;
        }
        updateAndSaveUserProfile({
          ...profile,
          appData: {
            ...profile.appData,
            keymap: {
              ...profile.appData.keymap,
              [selectedKeymapActionId as string]: getKeys(e),
            },
          },
        });
      }
    };

    document.addEventListener("keydown", keydownHandler);
    return () => {
      document.removeEventListener("keydown", keydownHandler);
    };
  }, [isEditMode, profile, selectedKeymapActionId, updateAndSaveUserProfile]);

  if (!profile) {
    return null;
  }
  const keymap = getKeyBindings(profile);
  return (
    <div style={{ padding: 20 }}>
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1, padding: 20 }}>
          <H1>Speedreader Voorkeuren</H1>
          <FormGroup label="Lettertype" labelFor="font">
            <Select
              filterable={false}
              items={
                [
                  "system",
                  "monospace",
                  "sans-serif",
                  "serif",
                ] as TUserProfile["appData"]["theme"]["font"][]
              }
              itemRenderer={(font, { handleClick }) => (
                <MenuItem
                  key={font}
                  label={`${font}${font === "system" ? " (standaard)" : ""}`}
                  text={`${font}${font === "system" ? " (standaard)" : ""}`}
                  onClick={handleClick}
                />
              )}
              onItemSelect={(font) => {
                if (!profile) {
                  return;
                }
                updateAndSaveUserProfile({
                  ...profile,
                  appData: {
                    ...profile.appData,
                    theme: {
                      ...profile.appData.theme,
                      font,
                    },
                  },
                });
              }}
            >
              <Button
                text={profile.appData.theme.font}
                rightIcon="double-caret-vertical"
              />
            </Select>
          </FormGroup>
          <FormGroup label="Lettergrootte" labelFor="fontSize">
            <Select
              filterable={false}
              items={[8, 10, 12, 14, 16, 18, 20]}
              itemRenderer={(fontSize, { handleClick }) => (
                <MenuItem
                  key={fontSize}
                  label={`${fontSize}px${
                    fontSize === 14 ? " (standaard)" : ""
                  }`}
                  text={`${fontSize}px${fontSize === 14 ? " (standaard)" : ""}`}
                  onClick={handleClick}
                />
              )}
              onItemSelect={(fontSize) => {
                if (!profile) {
                  return;
                }
                updateAndSaveUserProfile({
                  ...profile,
                  appData: {
                    ...profile.appData,
                    theme: {
                      ...profile.appData.theme,
                      fontSize,
                    },
                  },
                });
              }}
            >
              <Button
                text={`${profile.appData.theme.fontSize}px`}
                rightIcon="double-caret-vertical"
              />
            </Select>
          </FormGroup>
          <FormGroup
            label="Aantal kolommen text-weergave"
            labelFor="columnCount"
          >
            <Select
              filterable={false}
              items={[1, 2, 3, 4]}
              itemRenderer={(columnCount, { handleClick }) => (
                <MenuItem
                  key={columnCount}
                  label={`${columnCount}${
                    columnCount === 2 ? " (standaard)" : ""
                  }`}
                  text={`${columnCount}${
                    columnCount === 2 ? " (standaard)" : ""
                  }`}
                  onClick={handleClick}
                />
              )}
              onItemSelect={(columnCount) => {
                if (!profile) {
                  return;
                }
                updateAndSaveUserProfile({
                  ...profile,
                  appData: {
                    ...profile.appData,
                    theme: {
                      ...profile.appData.theme,
                      columnCount,
                    },
                  },
                });
              }}
            >
              <Button
                text={`${profile.appData.theme.columnCount || 2}`}
                rightIcon="double-caret-vertical"
              />
            </Select>
          </FormGroup>
          <FormGroup label="Donkere modus" labelFor="isDark">
            <Checkbox
              checked={profile.appData.theme.isDark}
              onChange={() => {
                updateAndSaveUserProfile({
                  ...profile,
                  appData: {
                    ...profile.appData,
                    theme: {
                      ...profile.appData.theme,
                      isDark: !profile.appData.theme.isDark,
                    },
                  },
                });
              }}
            >
              <Icon icon="lightbulb" />
            </Checkbox>
          </FormGroup>
          <FormGroup
            label="Arceerkleur zoekterm met exacte match"
            labelFor="highlightColor1"
          >
            <SketchPicker
              disableAlpha={true}
              color={highlightColor1}
              onChangeComplete={(res) => setHighlightColor1(res.rgb)}
            />
            <Button
              style={{ marginTop: 4 }}
              disabled={
                rgbToHex(highlightColor1) ===
                profile?.appData.theme.highlightColor1
              }
              onClick={() => {
                updateAndSaveUserProfile({
                  ...profile,
                  appData: {
                    ...profile.appData,
                    theme: {
                      ...profile.appData.theme,
                      highlightColor1: rgbToHex(highlightColor1),
                    },
                  },
                });
              }}
            >
              Opslaan
            </Button>
          </FormGroup>
          <FormGroup
            label="Arceerkleur zoekterm met wildcard"
            labelFor="highlightColor2"
          >
            <SketchPicker
              disableAlpha={true}
              color={highlightColor2}
              onChangeComplete={(res) => setHighlightColor2(res.rgb)}
            />
            <Button
              style={{ marginTop: 4 }}
              disabled={
                rgbToHex(highlightColor2) ===
                profile?.appData.theme.highlightColor2
              }
              onClick={() => {
                updateAndSaveUserProfile({
                  ...profile,
                  appData: {
                    ...profile.appData,
                    theme: {
                      ...profile.appData.theme,
                      highlightColor2: rgbToHex(highlightColor2),
                    },
                  },
                });
              }}
            >
              Opslaan
            </Button>
          </FormGroup>
          <FormGroup label="Voorkeuren toetsenbord" labelFor="keymap">
            <HTMLTable bordered={true} striped={true} interactive={true}>
              <tbody>
                {Object.keys(actions).map((actionId) => {
                  const action =
                    actions[actionId as keyof typeof KeyboardActions];
                  const keys =
                    profile.appData.keymap[actionId] || action.defaultKeys;
                  return (
                    <tr
                      className={
                        actionId === selectedKeymapActionId
                          ? "selected-keymap-action"
                          : ""
                      }
                      key={actionId}
                      onClick={() => {
                        if (selectedKeymapActionId === actionId) {
                          setIsEditMode(!isEditMode);
                          return;
                        }
                        setIsEditMode(false);
                        setSelectedKeymapAction(
                          actionId as keyof typeof KeyboardActions
                        );
                      }}
                    >
                      <td>{action.description}</td>
                      <td
                        className={
                          isEditMode ? "selected-keymap-action__edit-td" : ""
                        }
                      >
                        {keys.map((key) => t(`KEY_${key}`)).join(" + ")}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </HTMLTable>
          </FormGroup>
        </div>
        <div
          style={{
            flex: 1,
            backgroundColor: selectedKeymapActionId
              ? "lightgray"
              : "transparent",
          }}
        >
          {selectedKeymapActionId ? (
            <Keyboard activeKeys={keymap[selectedKeymapActionId] || []} />
          ) : null}
        </div>
      </div>
      <Button
        intent={Intent.PRIMARY}
        onClick={() => {
          navigate("/");
        }}
      >
        Terug
      </Button>
    </div>
  );
};

export default Profile;
