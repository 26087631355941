import React from "react";
import { Button, Callout } from "@blueprintjs/core";
import { ApiDataContext } from "../../provider/apiData";
import CenteredSpinner from "../../components/CenteredSpinner";
import { Link } from "react-router-dom";
import { KunciContext } from "../../inc/kunci";
import { Intent } from "@blueprintjs/core/lib/esm/common/intent";
import toaster from "../../inc/toaster";
import { components } from "../../types/openapi";
import SchemaTable, { IColumnConfig } from "../../components/SchemaTable";
import openapi from "../../openapi.json";
import { SchemaObject } from "openapi3-ts";
import usePublicationTypeMap from "../../hooks/usePublicationTypeMap";
import useDomainTypeMap from "../../hooks/useDomainTypeMap";
import PublicationCell from "./PublicationCell";
import DomainCell from "./DomainCell";
import useCheckPreferences from "../../hooks/useCheckPreferences";

const CheckPreferences = () => {
  const { profile, setCheckPreferences } = React.useContext(ApiDataContext);
  const { auth, axios } = React.useContext(KunciContext);
  const { userRoles } = auth?.jwt as components["schemas"]["Jwt"];
  const domainTypeMap = useDomainTypeMap();
  const publicationTypeMap = usePublicationTypeMap();
  const checkPreferences = useCheckPreferences();

  const data = React.useMemo(
    () => (checkPreferences ? Object.values(checkPreferences) : []),
    [checkPreferences]
  );

  const config = React.useMemo<{
    [propName: string]: IColumnConfig<components["schemas"]["CheckPreference"]>;
  }>(
    () => ({
      publicationTypeId: {
        renderData: (
          checkPreference: components["schemas"]["CheckPreference"]
        ) => {
          return publicationTypeMap && checkPreference.publicationTypeId
            ? publicationTypeMap[checkPreference.publicationTypeId]
                .publicationType
            : "";
        },
      },
      publicationId: {
        align: "left",
        renderCell: PublicationCell,
      },
      domainTypeId: {
        renderData: (
          checkPreference: components["schemas"]["CheckPreference"]
        ) => {
          if (!domainTypeMap) {
            return "⌛";
          }
          return domainTypeMap && checkPreference.domainTypeId
            ? domainTypeMap[checkPreference.domainTypeId].domainType
            : "";
        },
      },
      domainId: {
        align: "left",
        renderCell: DomainCell,
      },
      checkPercentage: {
        renderData: (
          checkPreference: components["schemas"]["CheckPreference"]
        ) => `${checkPreference.checkPercentage}%`,
      },
      checkPreferenceId: {
        order: 999,
        width: 220,
        title: (
          <Button
            intent="danger"
            disabled={!data.length}
            onClick={() => {
              if (
                window.confirm(
                  "Weet je zeker dat je ALLE voorkeuren wilt verwijderen?"
                )
              ) {
                Promise.all(
                  data.map((checkPreference) =>
                    axios.request({
                      method: "delete",
                      url: `/checkPreference/crud/${checkPreference.checkPreferenceId}`,
                    })
                  )
                )
                  .then(() => {
                    toaster.show({
                      message: `Voorkeuren verwijderd`,
                      intent: Intent.SUCCESS,
                    });
                  })
                  .catch((err) => {
                    toaster.show({
                      message: `Verwijderen voorkeur mislukt: ${err.message}`,
                      intent: Intent.DANGER,
                    });
                  });
                setCheckPreferences({});
              }
            }}
          >
            Alles verwijderen
          </Button>
        ),
        renderCell: (checkPreference) => (
          <>
            <Link to={`/checkPreference/${checkPreference.checkPreferenceId}`}>
              <Button intent={Intent.PRIMARY} text="Bewerken" small={true} />
            </Link>
            <Button
              intent={Intent.DANGER}
              text="Verwijderen"
              style={{ marginLeft: 8 }}
              small={true}
              onClick={() => {
                if (
                  window.confirm(
                    "Weet je zeker dat je deze voorkeur wilt verwijderen?"
                  )
                ) {
                  axios
                    .request({
                      method: "delete",
                      url: `/checkPreference/crud/${checkPreference.checkPreferenceId}`,
                    })
                    .then(() => {
                      toaster.show({
                        message: `Voorkeur verwijderd`,
                        intent: Intent.SUCCESS,
                      });
                      setCheckPreferences((checkPreferences) => {
                        const newCheckPreferences = { ...checkPreferences };
                        delete newCheckPreferences[
                          checkPreference.checkPreferenceId as string
                        ];
                        return newCheckPreferences;
                      });
                    })
                    .catch((err) => {
                      toaster.show({
                        message: `Verwijderen voorkeur mislukt: ${err.message}`,
                        intent: Intent.DANGER,
                      });
                    });
                }
              }}
            />
          </>
        ),
      },
    }),
    [axios, data, domainTypeMap, publicationTypeMap, setCheckPreferences]
  );

  if (!profile || !checkPreferences) {
    return <CenteredSpinner />;
  }

  if (userRoles.indexOf("admin") === -1) {
    return (
      <Callout
        title="Pagina niet beschikbaar"
        intent={Intent.WARNING}
        style={{ margin: 8 }}
      >
        <p>U heeft niet voldoende rechten voor het openen van deze pagina.</p>
      </Callout>
    );
  }

  return (
    <div style={{ marginTop: 16 }}>
      <SchemaTable<components["schemas"]["CheckPreference"]>
        isFullScreen
        isSortable={true}
        data={data}
        isSearchable={true}
        schema={openapi.components.schemas.CheckPreference as SchemaObject}
        config={config}
      />
      <Link to="/checkPreference/new">
        <Button
          icon="plus"
          intent={Intent.SUCCESS}
          text="Voorkeur toevoegen"
          style={{ margin: 8 }}
        />
      </Link>
    </div>
  );
};

export default CheckPreferences;
