import React from "react";
import { SchemaObject } from "openapi3-ts";
import { ErrorObject } from "ajv";
import SchemaFormGroup from "./SchemaFormGroup";
import { Callout } from "@blueprintjs/core";
import { ISchemaFormControlProps } from "../index";
import { t } from "../../../inc/i18n";

interface ISchemaFormBodyProps<T> {
  className?: string;
  dataPath?: string;
  disabled?: boolean;
  errors?: ErrorObject[];
  schema: SchemaObject;
  value: T;
  onChange: (value: T) => void;
  fieldConfigs?: Record<string, IFieldConfig<T>>;
}

export interface IFieldConfig<T> {
  addition?: (value: T) => React.ReactElement<HTMLDivElement>;
  label?: React.ReactElement;
  Control?: (props: ISchemaFormControlProps<any>) => JSX.Element;
  isDisabled?: boolean;
  isFieldset?: boolean;
  isHidden?: boolean;
  minDate?: Date;
  maxDate?: Date;
  order?: number;
  options?: React.ReactElement<HTMLOptionElement>[];
}

export default function SchemaFormBody<T>({
  className,
  dataPath = "",
  disabled,
  schema,
  onChange,
  value,
  errors = [],
  fieldConfigs = {},
}: ISchemaFormBodyProps<T>): JSX.Element | null {
  const { items, properties } = schema;

  if (value === undefined || !properties) {
    console.log("Can not render schemaFormBody", { properties, items, value });
    return null;
  }
  const globalErrors = errors
    ? errors.filter((error) => error.dataPath?.startsWith(`${dataPath}.`))
    : [];

  return (
    <div
      className={`components__schema-form-body${
        className ? ` ${className}` : ""
      }`}
    >
      {Object.keys(properties)
        .sort((a, b) => {
          const configA = fieldConfigs ? fieldConfigs[a] : undefined;
          const configB = fieldConfigs ? fieldConfigs[b] : undefined;
          const orderA = configA?.order || 0;
          const orderB = configB?.order || 0;
          return orderA - orderB;
        })
        .map((propName) => {
          const propSchema: SchemaObject = properties[propName];
          const propDataPath = `${dataPath}.${propName}`;
          const propValue = (value as any)[propName];
          const fieldConfig = fieldConfigs ? fieldConfigs[propName] : undefined;
          const propErrors = errors
            ? errors.filter((error) => error.dataPath?.startsWith(propDataPath))
            : [];
          if (propSchema.readOnly || fieldConfig?.isHidden) {
            return null;
          }
          return (
            <SchemaFormGroup
              key={propName}
              dataPath={propDataPath}
              fieldConfig={{
                ...fieldConfig,
                isDisabled: fieldConfig?.isDisabled || disabled,
              }}
              onChange={(newValue) => {
                onChange({
                  ...value,
                  [propName]: newValue,
                });
              }}
              propErrors={propErrors}
              schema={propSchema}
              value={propValue}
            />
          );
        })}
      {globalErrors.length && globalErrors[0].message ? (
        <Callout intent="danger">{t(globalErrors[0].message)}</Callout>
      ) : null}
    </div>
  );
}
