import React from "react";
import { components } from "../types/openapi";
import { KunciContext } from "../inc/kunci";

const useDomain = (requiredDomainId?: string) => {
  const { axios } = React.useContext(KunciContext);
  const [domain, setDomain] = React.useState<
    components["schemas"]["Domain"] | null
  >();
  React.useEffect(() => {
    if (!requiredDomainId || domain !== undefined) {
      return;
    }
    setDomain(null);
    axios
      .request<components["schemas"]["Domain"][]>({
        method: "get",
        url: `/domain/source`,
        params: {
          domainIds: requiredDomainId,
        },
      })
      .then((res) => {
        if (res.data.length) {
          setDomain(res.data[0]);
        }
      });
  }, [axios, domain, requiredDomainId, setDomain]);

  return domain;
};

export default useDomain;
