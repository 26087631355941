import { Button, Classes, Dialog, InputGroup } from "@blueprintjs/core";
import * as React from "react";
import { useSortBy, useTable } from "react-table";
import { FixedSizeList } from "react-window";
import { KunciContext } from "../../../../inc/kunci";
import { Intent } from "@blueprintjs/core/lib/esnext";
import { ApiDataContext } from "../../../../provider/apiData";
import toaster from "../../../../inc/toaster";
import { LayoutContext } from "../../../../provider/layout";
import { components } from "../../../../types/openapi";
import useSearchTopicMap from "../../../../hooks/useSearchTopicMap";

import "./index.scss";

interface ISearchTopicDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const SearchTopicDialog = ({ onClose, isOpen }: ISearchTopicDialogProps) => {
  const { windowOuterWidth } = React.useContext(LayoutContext);
  const { matchItemMap, currentClaimedMatchItemHit, profile, setMatchItemMap } =
    React.useContext(ApiDataContext);
  const { axios } = React.useContext(KunciContext);
  const { searchTopicMap } = useSearchTopicMap();
  const [search, setSearch] = React.useState("");

  const matchItem =
    matchItemMap && currentClaimedMatchItemHit
      ? matchItemMap[currentClaimedMatchItemHit.hit.matchItemId]
      : undefined;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: React.useMemo(
          () => [
            {
              Header: "Omschrijving",
              accessor: "description",
            },
            {
              Header: "Opmerkingen",
              accessor: "comment",
            },
          ],
          []
        ),
        data: React.useMemo<components["schemas"]["SearchTopic"][]>(() => {
          if (!matchItem || !searchTopicMap) {
            return [];
          }
          const searchParts = search.toLowerCase().split(" ");
          return Object.keys(searchTopicMap)
            .filter((searchTopicId) => {
              const searchTopic = searchTopicMap[searchTopicId];
              if (
                (matchItem.allSearchTopicIds &&
                  matchItem.allSearchTopicIds.indexOf(searchTopicId) >= 0) ||
                !searchTopic
              ) {
                return false;
              }
              const {
                comment,
                searchTopicName,
                description,
                isForMonitoringPlus,
              } = searchTopic;
              if (isForMonitoringPlus) {
                return false;
              }
              const lowerCaseTitleDescription =
                `${searchTopicName}${description}${comment}`.toLowerCase();
              return (
                searchParts.filter(
                  (part) => lowerCaseTitleDescription.indexOf(part) >= 0
                ).length === searchParts.length
              );
            })
            .map(
              (searchTopicId) => searchTopicMap[searchTopicId]
            ) as components["schemas"]["SearchTopic"][];
        }, [matchItem, search, searchTopicMap]),
      },
      useSortBy
    );

  const isPossible = React.useCallback(
    (searchTopic: components["schemas"]["SearchTopic"]) => {
      if (!matchItem) {
        return false;
      }
      return !(
        (matchItem.publicationId &&
          searchTopic.exceptionPublicationIds &&
          searchTopic.exceptionPublicationIds.indexOf(
            matchItem.publicationId
          ) >= 0) ||
        (matchItem.publicationTypeId &&
          searchTopic.exceptionPublicationTypeIds &&
          searchTopic.exceptionPublicationTypeIds.indexOf(
            matchItem.publicationTypeId
          ) >= 0) ||
        (matchItem.domainId &&
          searchTopic.exceptionDomainIds &&
          searchTopic.exceptionDomainIds.indexOf(matchItem.domainId) >= 0)
      );
    },
    [matchItem]
  );

  const RenderRow = React.useCallback(
    ({ index, style }: any) => {
      const row = rows[index];
      prepareRow(row);
      const searchTopic = searchTopicMap
        ? searchTopicMap[row.original.searchTopicId]
        : null;

      const addSearchTopic = (searchTopicId: string) => {
        const searchTopic = searchTopicMap
          ? searchTopicMap[searchTopicId]
          : null;
        if (!matchItem || !searchTopic) {
          return;
        }

        if (!isPossible(searchTopic)) {
          window.alert(
            matchItem.publicationId
              ? `Deze zoekopdracht is niet toegestaan voor deze publicatie`
              : `Deze zoekopdracht is niet toegestaan voor deze site`
          );
          return;
        }

        if (!window.confirm("Weet je zeker dat je deze wilt toevoegen?")) {
          return;
        }

        const { matchItemId, allSearchTopicIds } = matchItem;
        axios
          .request<components["schemas"]["ProcessResult"]>({
            url: `/matchItemHit/searchTopic`,
            method: "post",
            params: {
              matchItemId,
              searchTopicId,
            },
          })
          .then((res) => {
            if (!res.data.success) {
              throw new Error("Update matchItem failed");
            }
            setMatchItemMap({
              ...matchItemMap,
              [matchItem.matchItemId]: {
                ...matchItem,
                allSearchTopicIds: [
                  ...(allSearchTopicIds || []),
                  searchTopicId,
                ],
              },
            });
          })
          .catch((err) => {
            console.log(err);
            toaster.show({ message: err.message, intent: Intent.DANGER });
          });
        onClose();
      };

      const classNames = ["table-row"];
      classNames.push(`table-row--${index % 2 ? "odd" : "even"}`);
      if (searchTopic) {
        classNames.push(
          `table-row--${isPossible(searchTopic) ? "possible" : "impossible"}`
        );
      }
      return (
        <div
          className={classNames.join(" ")}
          {...row.getRowProps({
            style,
          })}
        >
          {row.cells.map((cell) => (
            <div
              className={`table-row-column table-row-column--${cell.column.id}`}
              {...cell.getCellProps()}
            >
              {/* eslint-disable-next-line */}
              <a
                onClick={(e) => {
                  addSearchTopic(row.original.searchTopicId);
                  e.preventDefault();
                }}
              >
                {cell.render("Cell")}
              </a>
            </div>
          ))}
        </div>
      );
    },
    [
      axios,
      rows,
      prepareRow,
      searchTopicMap,
      matchItem,
      isPossible,
      onClose,
      setMatchItemMap,
      matchItemMap,
    ]
  );

  if (!profile) {
    return null;
  }

  return (
    <Dialog
      icon="info-sign"
      onClose={onClose}
      isOpen={isOpen}
      title="Zoekopdracht toevoegen"
      className={profile.appData.theme.isDark ? Classes.DARK : ""}
      style={{ width: windowOuterWidth - 100 }}
    >
      <InputGroup
        leftIcon="filter"
        onChange={(e: any) => {
          setSearch(e.currentTarget.value);
        }}
        placeholder="Zoeken..."
        small={true}
        value={search}
        autoFocus={true}
      />

      <div className={Classes.DIALOG_BODY}>
        <div role="table" className="searchTopicTable" {...getTableProps()}>
          <div className="table-header">
            {headerGroups.map((headerGroup) => (
              <div className="table-row" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <div
                    className={`table-row-column table-row-column--${column.id}`}
                    {
                      // @ts-ignore
                      ...column.getHeaderProps(column.getSortByToggleProps())
                    }
                  >
                    {column.render("Header")}
                    <span>
                      {
                        //
                        // @ts-ignore
                        column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""
                      }
                    </span>
                  </div>
                ))}
                <div className="table-row-column table-row-column--actions" />
              </div>
            ))}
          </div>
          <div {...getTableBodyProps()}>
            <FixedSizeList
              height={500}
              itemCount={rows.length}
              itemSize={40}
              width={windowOuterWidth - 140}
              style={{ overflowY: "scroll" }}
            >
              {RenderRow}
            </FixedSizeList>
          </div>
        </div>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={onClose}>Sluiten</Button>
        </div>
      </div>
    </Dialog>
  );
};

export default SearchTopicDialog;
