import * as React from "react";
import { Button, Callout } from "@blueprintjs/core";
import { ApiDataContext } from "../../provider/apiData";
import CenteredSpinner from "../../components/CenteredSpinner";
import _ from "lodash";
import { Intent } from "@blueprintjs/core/lib/esm/common/intent";
import toaster from "../../inc/toaster";
import { AxiosError } from "axios";
import { KunciContext } from "../../inc/kunci";
import { components } from "../../types/openapi";
import { useLocation, useNavigate } from "react-router-dom";

const Dashboard = () => {
  const { claimNextHit, currentSearchTopicClaim, profile, submitClaimedHits } =
    React.useContext(ApiDataContext);
  const [isInProgress, setIsInProgress] = React.useState(false);
  const { auth } = React.useContext(KunciContext);
  const location = useLocation();

  const { userRoles } = auth?.jwt as components["schemas"]["Jwt"];
  const isDeleteRequestUser = userRoles.indexOf("deleteRequestUser") >= 0;
  const isMonitoringPlusUser = userRoles.indexOf("monitoringPlusUser") >= 0;
  const isReader =
    _.intersection(
      ["printReader", "printChecker", "webReader", "webChecker", "headliner"],
      userRoles
    ).length > 0;
  const navigate = useNavigate();

  if (!profile) {
    return <CenteredSpinner />;
  }

  if (!isReader && !isMonitoringPlusUser) {
    return (
      <Callout
        title="Pagina niet beschikbaar"
        intent={Intent.WARNING}
        style={{ margin: 8 }}
      >
        <p>U heeft niet voldoende rechten voor het openen van deze pagina.</p>
      </Callout>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
        height: "100%",
        flexDirection: "column",
      }}
    >
      {location.search.indexOf("timeout") >= 0 ? (
        <Callout
          title="Fout"
          style={{ width: 300, marginBottom: 20 }}
          intent={Intent.DANGER}
        >
          Artikel stond te lang open. Het lezen is afgebroken.
        </Callout>
      ) : null}
      {isReader ? (
        <Button
          text="Start Lezen"
          intent={Intent.PRIMARY}
          className="m-5"
          large={true}
          disabled={isInProgress}
          style={{ margin: 20 }}
          onClick={() => {
            setIsInProgress(true);
            if (currentSearchTopicClaim) {
              submitClaimedHits();
            }
            claimNextHit().catch((err: AxiosError) => {
              setIsInProgress(false);
              if (err.response?.status === 404) {
                toaster.show({
                  message: `😊 Er zijn geen artikelen meer 😊`,
                  intent: Intent.SUCCESS,
                });
                return;
              }

              toaster.show({
                message: `Starten mislukt: ${err.message}`,
                intent: Intent.DANGER,
              });
            });
          }}
        />
      ) : null}
      {isMonitoringPlusUser ? (
        <Button
          text="Start Monitoring plus"
          intent={Intent.SUCCESS}
          large={true}
          style={{ margin: 20 }}
          disabled={isInProgress}
          onClick={() => {
            navigate("/monitoringPlus");
          }}
        />
      ) : null}
      {isDeleteRequestUser ? (
        <Button
          text="Verwerk verwijderverzoeken"
          intent={Intent.DANGER}
          large={true}
          style={{ margin: 20 }}
          // disabled={!counters.deleteRequestsToBeProcessed || isInProgress}
          onClick={() => {
            navigate("/deleteRequests");
          }}
        />
      ) : null}
    </div>
  );
};

export default Dashboard;
