import React from "react";
import { components } from "../../types/openapi";
import SchemaTable, { IColumnConfig } from "../../components/SchemaTable";
import openapi from "../../openapi.json";
import { SchemaObject } from "openapi3-ts";
import { ApiDataContext } from "../../provider/apiData";

const SearchTopics = () => {
  const { searchTopicMap } = React.useContext(ApiDataContext);
  const data = React.useMemo<components["schemas"]["SearchTopic"][]>(
    () =>
      searchTopicMap
        ? (Object.values(searchTopicMap).filter(
            (searchTopic) => !!searchTopic
          ) as components["schemas"]["SearchTopic"][])
        : [],
    [searchTopicMap]
  );

  const config = React.useMemo<{
    [propName: string]: IColumnConfig<components["schemas"]["SearchTopic"]>;
  }>(
    () => ({
      searchTopicId: {
        hidden: true,
      },
      exceptionPublicationIds: {
        hidden: true,
      },
      exceptionPublicationTypeIds: { hidden: true },
      exceptionDomainIds: { hidden: true },
      exceptionDomainTypeIds: { hidden: true },
      frequencyWord: { hidden: true },
    }),
    []
  );

  return (
    <div style={{ paddingTop: 40 }}>
      <SchemaTable
        schema={openapi.components.schemas.SearchTopic as SchemaObject}
        data={data}
        isFullScreen
        isSearchable
        isSortable
        config={config}
      />
    </div>
  );
};

export default SearchTopics;
