import React from "react";
import { Button, MenuItem, Spinner } from "@blueprintjs/core";
import { Select2 } from "@blueprintjs/select";
import { useDebouncedCallback } from "use-debounce";
import { components } from "../../../types/openapi";
import { ISchemaFormControlProps } from "../../../components/schemaForm";
import { KunciContext } from "../../../inc/kunci";

const DomainIdControl = ({
  isDisabled,
  onChange,
  value,
}: ISchemaFormControlProps<string>) => {
  const { axios } = React.useContext(KunciContext);
  const emptyOption = React.useMemo(
    () => ({ domainId: "", name: "Kies een domein" }),
    []
  );
  const [options, setOptions] = React.useState<
    components["schemas"]["Domain"][]
  >([emptyOption]);
  const valueDomain = React.useMemo<
    components["schemas"]["Domain"] | undefined
  >(
    () =>
      options && value
        ? options.find((option) => option.domainId === value)
        : undefined,
    [options, value]
  );

  React.useEffect(() => {
    if (!value || (options?.length && options?.length > 1)) {
      return;
    }
    axios
      .request<components["schemas"]["Domain"][]>({
        method: "get",
        url: "/domain/source",
        params: { domainIds: value },
      })
      .then((res) => {
        setOptions([emptyOption, ...res.data]);
      });
  }, [axios, emptyOption, options, value]);

  const [debouncedOptionsUpdate] = useDebouncedCallback((query: string) => {
    if (query.length < 2) {
      return;
    }
    axios
      .request<components["schemas"]["Domain"][]>({
        method: "get",
        url: "/domain/source",
        params: { query },
      })
      .then((res) => {
        setOptions([
          emptyOption,
          ...res.data.sort((a, b) => {
            const aName = a.name;
            const bName = b.name;
            const aLength = aName.length;
            const bLength = bName.length;
            if (aLength !== bLength) {
              return aLength > bLength ? 1 : -1;
            }
            return aName > bName ? 1 : -1;
          }),
        ]);
      });
  }, 500);

  if (!options) {
    return <Spinner />;
  }

  return (
    <Select2
      disabled={isDisabled}
      filterable={true}
      onQueryChange={debouncedOptionsUpdate}
      items={options}
      itemRenderer={(
        domain: components["schemas"]["Domain"],
        { handleClick, handleFocus, modifiers }
      ) => {
        return (
          <MenuItem
            selected={modifiers.active}
            disabled={modifiers.disabled}
            label={domain.name}
            text={domain.name}
            key={domain.domainId}
            onClick={handleClick}
            onFocus={handleFocus}
          />
        );
      }}
      noResults={
        <MenuItem
          disabled={true}
          label="Niet gevonden."
          text="Niet gevonden."
        />
      }
      onItemSelect={(domain) => onChange(domain.domainId)}
    >
      <Button
        disabled={isDisabled}
        text={valueDomain ? valueDomain.name : emptyOption.name}
        rightIcon="double-caret-vertical"
      />
    </Select2>
  );
};

export default DomainIdControl;
