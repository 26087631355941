import React from "react";
import { Button, MenuItem, Spinner } from "@blueprintjs/core";
import { Select2 } from "@blueprintjs/select";
import { useDebouncedCallback } from "use-debounce";
import { components } from "../../../types/openapi";
import { ISchemaFormControlProps } from "../../../components/schemaForm";
import { KunciContext } from "../../../inc/kunci";

const PublicationIdControl = ({
  isDisabled,
  onChange,
  value,
}: ISchemaFormControlProps<number>) => {
  const { axios } = React.useContext(KunciContext);
  const emptyOption = React.useMemo(
    () => ({ publicationId: 0, name: "Kies een publicatie" }),
    []
  );
  const [options, setOptions] = React.useState<
    components["schemas"]["Publication"][]
  >([emptyOption]);
  const valuePublication = React.useMemo<
    components["schemas"]["Publication"] | undefined
  >(
    () =>
      options && value
        ? options.find((option) => option.publicationId === value)
        : undefined,
    [options, value]
  );

  React.useEffect(() => {
    if (!value || (options?.length && options?.length > 1)) {
      return;
    }
    axios
      .request<components["schemas"]["Publication"][]>({
        method: "get",
        url: "/publication/source",
        params: { publicationIds: value },
      })
      .then((res) => {
        setOptions([emptyOption, ...res.data]);
      });
  }, [axios, emptyOption, options, value]);

  const [debouncedOptionsUpdate] = useDebouncedCallback((query: string) => {
    if (query.length < 2) {
      return;
    }
    axios
      .request<components["schemas"]["Publication"][]>({
        method: "get",
        url: "/publication/source",
        params: { query },
      })
      .then((res) => {
        setOptions([
          emptyOption,
          ...res.data.sort((a, b) => {
            return a.name > b.name ? 1 : -1;
          }),
        ]);
      });
  }, 500);

  if (!options) {
    return <Spinner />;
  }

  return (
    <Select2
      disabled={isDisabled}
      filterable={true}
      onQueryChange={debouncedOptionsUpdate}
      items={options}
      itemRenderer={(
        publication: components["schemas"]["Publication"],
        { handleClick, handleFocus, modifiers }
      ) => {
        return (
          <MenuItem
            selected={modifiers.active}
            disabled={modifiers.disabled}
            label={publication.name}
            text={publication.name}
            key={publication.publicationId}
            onClick={handleClick}
            onFocus={handleFocus}
          />
        );
      }}
      noResults={
        <MenuItem
          disabled={true}
          label="Niet gevonden."
          text="Niet gevonden."
        />
      }
      onItemSelect={(publication) => onChange(publication.publicationId)}
    >
      <Button
        disabled={isDisabled}
        text={valuePublication ? valuePublication.name : emptyOption.name}
        rightIcon="double-caret-vertical"
      />
    </Select2>
  );
};

export default PublicationIdControl;
