import React from "react";
import "./index.scss";

interface IColumnResizerProps {
  setColumnDelta: (width: number) => void;
}

const ColumnResizer = ({ setColumnDelta }: IColumnResizerProps) => {
  const [dragStartX, setDragStartX] = React.useState(0);

  const onDragStart = React.useCallback((e: React.MouseEvent) => {
    setDragStartX(e.clientX);
  }, []);
  const onDragEnd = React.useCallback(
    (e: React.MouseEvent) => {
      setColumnDelta(e.clientX - dragStartX);
      setDragStartX(0);
    },
    [dragStartX, setColumnDelta]
  );
  return (
    <div
      className={`schema-table__column-resizer ${
        dragStartX ? "schema-table__column-resizer--dragging" : ""
      }`}
      draggable="true"
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
    >
      ⋮
    </div>
  );
};

export default React.memo(ColumnResizer);
