import React from "react";
import { KunciContext } from "../inc/kunci";
import { useParams } from "react-router-dom";
import useSearchTopicMap from "./useSearchTopicMap";
import { ApiDataContext } from "../provider/apiData";
import useMatchItemMap from "./useMatchItemMap";
import useDeleteRequests from "./useDeleteRequests";
import { convertFromHTML } from "draft-convert";
import { ChatContext, createDecorator } from "@mediainfogroep/mig-chat-app";
import { EditorState } from "draft-js";

const useChatMessage = () => {
  const { currentClaimedMatchItemHit } = React.useContext(ApiDataContext);
  const { auth } = React.useContext(KunciContext);
  const { setEditorState, chatDialogConfigHandler } =
    React.useContext(ChatContext);
  const { searchTopicMap } = useSearchTopicMap();
  const { matchItemMap } = useMatchItemMap();
  const deleteRequests = useDeleteRequests();
  const params = useParams<{ deleteRequestId: string }>();
  const deleteRequest =
    deleteRequests && params.deleteRequestId
      ? deleteRequests[params.deleteRequestId]
      : undefined;
  const matchItem =
    matchItemMap && currentClaimedMatchItemHit
      ? matchItemMap[currentClaimedMatchItemHit.hit.matchItemId]
      : undefined;

  const openChatDialogHandler = React.useCallback(() => {
    chatDialogConfigHandler("e1a8bba5ad85472898450a614f83e271");
  }, [chatDialogConfigHandler]);

  return React.useCallback(() => {
    openChatDialogHandler();
    const searchTopic =
      searchTopicMap && currentClaimedMatchItemHit?.hit.searchTopicId
        ? searchTopicMap[currentClaimedMatchItemHit.hit.searchTopicId]
        : undefined;
    const subject = `Klantnr ${searchTopic?.customerId} - ${
      searchTopic?.customerName
    } - ${deleteRequest ? "Zoekopdracht moet worden aangepast" : "Vraag"}`;
    const clippingUrl = matchItem?.clippingUrls
      ? matchItem?.clippingUrls[0]
      : undefined;

    const highlightReg = /<span.*?"highlight".*?>(.*?)<\/span>/g;
    const wildHighlightReg = /<span.*?"wild-highlight".*?>(.*?)<\/span>/g;
    const processedHighlightText = document
      .querySelector(".highlighted-clip__text")
      ?.innerHTML.replace(highlightReg, "<b><i>$1</i></b>")
      .replace(wildHighlightReg, "<b><u><i>$1</i></u></b>");

    const messageTemplate = `
      <p>Onderwerp: ${subject}</p><br/>
      <p>Hoi,</p>
      <p>Ik heb een vraag over het volgende artikel</p><br/>
      <ul><li>Klantnummer: ${
        searchTopic?.customerId
      }</li><li>Accountmanager: <span class="at-mention">@${
      searchTopic?.accountManager
    }</span></li><li>Backoffice: <span class="at-mention">@${
      searchTopic?.backOfficer
    }</span></li><li>Zoekopdracht: ${
      searchTopic ? searchTopic.searchTopicName : "RTV"
    }</li><li>Link naar artikel: <a href=${clippingUrl}>${clippingUrl}</a></li></ul><br/>
      <p>Het artikel is gevonden op de volgende matches:</p><br/>
      <h3><b>Bietjessalade met linzen, hazelnoten en geitenkaas</b></h3>
      <p>${processedHighlightText}</p><br/>
      <p>Groeten,</p>
      <p>${auth?.jwt.displayName}</p>`;

    const contentState = convertFromHTML({
      htmlToEntity: (nodeName, node, createEntity) => {
        if (nodeName === "a") {
          return createEntity("LINK", "MUTABLE", { url: node.href });
        }
        if (nodeName === "span" && node.className === "at-mention") {
          return createEntity("AT-MENTION", "IMMUTABLE", {
            text: node.textContent,
          });
        }
        if (nodeName === "span" && node.className === "highlight") {
          return createEntity("HIGHLIGHT", "IMMUTABLE", {});
        }
        if (nodeName === "span" && node.className === "wild-highlight") {
          return createEntity("WILD-HIGHLIGHT", "IMMUTABLE", {});
        }
      },
      htmlToBlock: (nodeName) => {
        if (nodeName === "blockquote") {
          return {
            type: "blockquote",
            data: {},
          };
        }
      },
    })(messageTemplate);

    const decorator = createDecorator();
    let newEditorState = EditorState.moveFocusToEnd(
      EditorState.createWithContent(contentState, decorator)
    );
    setEditorState(newEditorState);
  }, [
    auth?.jwt.displayName,
    currentClaimedMatchItemHit,
    deleteRequest,
    matchItem?.clippingUrls,
    openChatDialogHandler,
    searchTopicMap,
    setEditorState,
  ]);
};

export default useChatMessage;
