import React from "react";
import { components } from "../types/openapi";
import { ApiDataContext, IHashMap } from "../provider/apiData";
import toaster from "../inc/toaster";
import { Intent } from "@blueprintjs/core";
import { KunciContext } from "../inc/kunci";

const useDomainTypeMap = () => {
  const { domainTypeMap, setDomainTypeMap } = React.useContext(ApiDataContext);
  const { axios } = React.useContext(KunciContext);

  React.useEffect(() => {
    if (domainTypeMap !== undefined) {
      return;
    }

    setDomainTypeMap(null);
    axios
      .get<components["schemas"]["DomainType"][]>("/domain/type")
      .then((webRes) => {
        if (!webRes.data.length) {
          throw new Error("Failed to retrieve web domainTypes");
        }
        setDomainTypeMap(
          webRes.data.reduce<IHashMap<components["schemas"]["DomainType"]>>(
            (prev, domainType) => {
              prev[domainType.domainTypeId as string] = domainType;
              return prev;
            },
            {}
          )
        );
      })
      .catch((err) => {
        toaster.show({
          message: `Laden domeintypes mislukt: ${err.message}`,
          intent: Intent.DANGER,
        });
      });
  }, [axios, setDomainTypeMap, domainTypeMap]);

  return domainTypeMap;
};

export default useDomainTypeMap;
