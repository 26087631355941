import * as React from "react";
import { Button, MenuItem } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import { ApiDataContext } from "../../../../provider/apiData";
import CenteredSpinner from "../../../../components/CenteredSpinner";
import { components } from "../../../../types/openapi";
import Lightbox from "react-18-image-lightbox";
import { usePublicationIssue } from "../../../../hooks/usePublicationIssue";

interface IPagePanelProps {
  imageWidth: number;
}

const PagePanel = ({ imageWidth }: IPagePanelProps) => {
  const { currentClaimedMatchItemHit, matchItemMap } =
    React.useContext(ApiDataContext);
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedPageIndex, setSelectedPageIndex] = React.useState<number>(0);
  const [isImageLoaded, setIsImageLoaded] = React.useState(false);

  const matchItem =
    matchItemMap && currentClaimedMatchItemHit
      ? matchItemMap[currentClaimedMatchItemHit.hit.matchItemId]
      : undefined;
  const publicationIssue = usePublicationIssue(matchItem?.publicationIssueId);

  React.useEffect(() => {
    if (!publicationIssue || !matchItem) {
      return;
    }
    const defaultPageIndex = publicationIssue.pages.findIndex(
      (page) => page.pageId === matchItem.pageId
    );
    if (defaultPageIndex >= 0) {
      setSelectedPageIndex(defaultPageIndex);
    }
  }, [publicationIssue, matchItem]);

  if (!publicationIssue) {
    return null;
  }
  const { pages = [] } = publicationIssue;
  const selectedPage = pages[selectedPageIndex];
  if (!selectedPage) {
    return null;
  }
  return (
    <React.Fragment>
      <div>
        <Select<components["schemas"]["Page"]>
          filterable={false}
          items={pages}
          itemRenderer={(page, { handleClick }) => (
            <MenuItem
              key={page.pageId}
              label={`Publicatie pagina ${page.pageSequence}`}
              text={`Publicatie pagina ${page.pageSequence}`}
              onClick={handleClick}
            />
          )}
          onItemSelect={(selectedPage) => {
            setIsImageLoaded(false);
            setSelectedPageIndex(
              pages.findIndex((page) => page.pageId === selectedPage.pageId)
            );
          }}
        >
          <Button
            text={`Publicatie pagina ${selectedPage.pageSequence}`}
            rightIcon="double-caret-vertical"
          />
        </Select>
      </div>
      {!isImageLoaded ? <CenteredSpinner /> : null}
      <img
        src={selectedPage.url}
        alt={`Publicatie pagina ${selectedPage.pageSequence}`}
        style={{
          width: imageWidth,
          marginTop: 8,
          visibility: isImageLoaded ? "visible" : "hidden",
        }}
        onClick={() => {
          setIsOpen(true);
        }}
        onLoad={() => {
          setIsImageLoaded(true);
        }}
      />
      {isOpen && selectedPage ? (
        <Lightbox
          mainSrc={selectedPage.url}
          onCloseRequest={() => setIsOpen(false)}
        />
      ) : null}
    </React.Fragment>
  );
};

export default PagePanel;
