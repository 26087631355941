import { Intent } from "@blueprintjs/core";
import { KunciContext } from "../inc/kunci";
import React from "react";
import { ApiDataContext, IHashMap } from "../provider/apiData";
import toaster from "../inc/toaster";
import { components } from "../types/openapi";

const useUsers = () => {
  const { users, setUsers } = React.useContext(ApiDataContext);
  const { axios } = React.useContext(KunciContext);
  React.useEffect(() => {
    if (users !== undefined) {
      return;
    }

    setUsers(null);
    axios
      .get<components["schemas"]["User"][]>("/user/crud")
      .then((res) => {
        if (!res.data.length) {
          throw new Error("Failed to retrieve users");
        }
        setUsers(
          res.data.reduce<IHashMap<components["schemas"]["User"]>>(
            (prev, user) => {
              prev[user.userId as string] = user;
              return prev;
            },
            {}
          )
        );
      })
      .catch((err) => {
        toaster.show({
          message: `Laden gebruikers mislukt: ${err.message}`,
          intent: Intent.DANGER,
        });
      });
  }, [axios, setUsers, users]);

  return users;
};

export default useUsers;
