import React from "react";
import { KunciContext } from "../inc/kunci";
import { components } from "../types/openapi";
import { ApiDataContext } from "../provider/apiData";
import toaster from "../inc/toaster";
import { Intent } from "@blueprintjs/core";
import { WebSocketContext } from "../provider/webSocket";
import { IWebSocketMessage } from "../types/extendedOpenapi";

const useClaimedMatchItemHits = () => {
  const {
    currentSearchTopicClaim,
    claimedMatchItemHits,
    previousSearchTopicClaim,
    setClaimedMatchItemHits,
    setCurrentSearchTopicClaim,
    setNextSearchTopicClaim,
    setSearchTopicClaims,
  } = React.useContext(ApiDataContext);
  const { auth, axios } = React.useContext(KunciContext);
  const { webSocket } = React.useContext(WebSocketContext);

  React.useEffect(() => {
    if (claimedMatchItemHits !== undefined || !currentSearchTopicClaim) {
      return;
    }
    let claimType = "all";
    if (
      currentSearchTopicClaim.webCount &&
      currentSearchTopicClaim.printCount &&
      currentSearchTopicClaim.printClaimedByUserName !==
        currentSearchTopicClaim.webClaimedByUserName
    ) {
      claimType =
        currentSearchTopicClaim.printClaimedByUserName === auth?.jwt.displayName
          ? "print"
          : "web";
    }

    const userName = auth?.jwt.userName || "";

    setClaimedMatchItemHits((claimedMatchItemHits) => {
      if (claimedMatchItemHits !== undefined) {
        return claimedMatchItemHits;
      }
      const params = currentSearchTopicClaim.isForced
        ? { force: "true" }
        : undefined;
      setClaimedMatchItemHits(null);
      try {
        webSocket.send(
          JSON.stringify({
            type: "monitoringPlusSearchTopicClaimUpdate",
            userName,
          } as IWebSocketMessage)
        );
      } catch (e) {
        console.log(e);
      }
      axios
        .request<components["schemas"]["MatchItemHit"][]>({
          method: "post",
          url: `/monitoringPlus/claim/${currentSearchTopicClaim.searchTopicId}/${claimType}`,
          params,
        })
        .then((res) => {
          setClaimedMatchItemHits(res.data);
          setSearchTopicClaims(undefined);
        })
        .catch((err) => {
          toaster.show({
            message: `Mogelijk conflict: claimen artikelen mislukt: ${err.message}`,
            intent: Intent.DANGER,
          });
          setSearchTopicClaims(undefined);
          if (previousSearchTopicClaim) {
            setNextSearchTopicClaim(previousSearchTopicClaim, false);
            setClaimedMatchItemHits(undefined);
            return;
          }
          setCurrentSearchTopicClaim(undefined);
          setClaimedMatchItemHits(undefined);
        });
      return null;
    });
  }, [
    axios,
    setClaimedMatchItemHits,
    claimedMatchItemHits,
    currentSearchTopicClaim,
    auth?.jwt.displayName,
    setCurrentSearchTopicClaim,
    previousSearchTopicClaim,
    setNextSearchTopicClaim,
    webSocket,
    auth?.jwt.userName,
    setSearchTopicClaims,
  ]);

  return claimedMatchItemHits;
};

export default useClaimedMatchItemHits;
