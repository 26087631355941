import * as React from "react";
import { FixedSizeList } from "react-window";
import {
  Button,
  Checkbox,
  Icon,
  Navbar,
  NavbarGroup,
  Spinner,
} from "@blueprintjs/core";
import { KunciContext } from "../../../inc/kunci";

import { ApiDataContext } from "../../../provider/apiData";
import CenteredSpinner from "../../../components/CenteredSpinner";
import MatchItemHitRow from "./MatchItemHitRow";
import { LayoutContext } from "../../../provider/layout";
import useSearchTopicClaims from "../../../hooks/useSearchTopicClaims";
import useClaimedMatchItemHits from "../../../hooks/useClaimedMatchItemHits";
import SearchTopicButton from "./SearchTopicButton";
import useSearchTopicMap from "../../../hooks/useSearchTopicMap";
import { components } from "../../../types/openapi";

const MonitoringPlus = () => {
  const {
    currentSearchTopicClaim,
    previousSearchTopicClaim,
    setClaimedMatchItemHits,
    setCurrentSearchTopicClaim,
    submitClaimedHits,
  } = React.useContext(ApiDataContext);
  const { auth } = React.useContext(KunciContext);
  const {
    isMatchItemHitVisible,
    monitoringPlusListRef,
    setShowPrint,
    setShowStatusApproving,
    setShowStatusNone,
    setShowStatusRejecting,
    setShowWeb,
    showPrint,
    showStatusApproving,
    showStatusNone,
    showStatusRejecting,
    showWeb,
  } = React.useContext(LayoutContext);

  const { searchTopicMap } = useSearchTopicMap();
  const claimedMatchItemHits = useClaimedMatchItemHits();

  const filteredClaimedMatchItemHits = React.useMemo(
    () =>
      claimedMatchItemHits
        ? claimedMatchItemHits.filter(isMatchItemHitVisible)
        : [],
    [claimedMatchItemHits, isMatchItemHitVisible]
  );

  const fixedSizeListContainerRef = React.useRef<HTMLDivElement>(null);
  const searchTopicClaims = useSearchTopicClaims();

  React.useEffect(() => {
    if (
      !currentSearchTopicClaim &&
      searchTopicClaims &&
      claimedMatchItemHits !== null
    ) {
      const myClaim = searchTopicClaims.find(
        (searchTopicClaim) =>
          searchTopicClaim.printClaimedByUserName === auth?.jwt.displayName ||
          searchTopicClaim.webClaimedByUserName === auth?.jwt.displayName
      );
      if (myClaim) {
        setCurrentSearchTopicClaim(myClaim);
      }
    }
  }, [
    claimedMatchItemHits,
    currentSearchTopicClaim,
    auth?.jwt.displayName,
    searchTopicClaims,
    setCurrentSearchTopicClaim,
  ]);

  React.useEffect(() => {
    setShowPrint(true);
    setShowWeb(true);
    setShowStatusApproving(true);
    setShowStatusNone(true);
    setShowStatusRejecting(true);
  }, [
    setShowPrint,
    setShowStatusApproving,
    setShowStatusNone,
    setShowStatusRejecting,
    setShowWeb,
  ]);

  const processAllHits = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (window.confirm("Weet je zeker dat je alle items wilt beoordelen?")) {
        setClaimedMatchItemHits(
          (claimedMatchItemHits) =>
            (claimedMatchItemHits
              ? claimedMatchItemHits.map((claimedMatchItemHit) =>
                  isMatchItemHitVisible(claimedMatchItemHit)
                    ? {
                        ...claimedMatchItemHit,
                        monitoringPlusResult: e.currentTarget.dataset.action,
                      }
                    : claimedMatchItemHit
                )
              : claimedMatchItemHits) as components["schemas"]["MatchItemHit"][]
        );
      }
    },
    [isMatchItemHitVisible, setClaimedMatchItemHits]
  );

  const showControlsDisabled = React.useMemo(
    () => claimedMatchItemHits === null || !!previousSearchTopicClaim,
    [claimedMatchItemHits, previousSearchTopicClaim]
  );
  const showMediaTypeDisabled = React.useMemo(() => {
    if (showControlsDisabled || !currentSearchTopicClaim) {
      return true;
    }
    return (
      !currentSearchTopicClaim.printCount ||
      !currentSearchTopicClaim.webCount ||
      currentSearchTopicClaim.printClaimedByUserName !==
        auth?.jwt.displayName ||
      currentSearchTopicClaim.webClaimedByUserName !== auth?.jwt.displayName
    );
  }, [showControlsDisabled, currentSearchTopicClaim, auth?.jwt.displayName]);

  const FilteredMatchItemRow = React.useCallback(
    (props: any) => (
      <MatchItemHitRow
        {...props}
        matchItemHits={filteredClaimedMatchItemHits}
        disabled={previousSearchTopicClaim}
      />
    ),
    [filteredClaimedMatchItemHits, previousSearchTopicClaim]
  );
  const monitoringPlusResultApprovedCount = React.useMemo(
    () =>
      claimedMatchItemHits
        ? claimedMatchItemHits.filter(
            (hit) => hit.monitoringPlusResult === "approving"
          ).length
        : 0,
    [claimedMatchItemHits]
  );
  const monitoringPlusResultRejectedCount = React.useMemo(
    () =>
      claimedMatchItemHits
        ? claimedMatchItemHits.filter(
            (hit) => hit.monitoringPlusResult === "rejecting"
          ).length
        : 0,
    [claimedMatchItemHits]
  );

  if (!searchTopicMap) {
    return <CenteredSpinner />;
  }
  return (
    <div
      style={{
        margin: 16,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "hidden",
        marginLeft: 0,
        marginRight: 0,
      }}
    >
      <div
        style={{
          display: "flex",
        }}
      >
        <SearchTopicButton disabled={showControlsDisabled} />
        {currentSearchTopicClaim ? (
          <div>
            <Button
              icon="cross"
              onClick={submitClaimedHits}
              style={{ marginRight: 10 }}
              disabled={showControlsDisabled}
            />
          </div>
        ) : null}
      </div>
      {currentSearchTopicClaim ? (
        <>
          <div>
            <Navbar>
              <NavbarGroup>
                <Checkbox
                  style={{ marginBottom: 0 }}
                  disabled={showMediaTypeDisabled}
                  checked={showPrint}
                  onChange={() => setShowPrint((showPrint) => !showPrint)}
                >
                  <Icon
                    icon="manual"
                    size={16}
                    style={{ marginRight: 4, marginTop: 3 }}
                    htmlTitle={`${currentSearchTopicClaim.printCount}`}
                  />
                </Checkbox>
                <Checkbox
                  checked={showWeb}
                  disabled={showMediaTypeDisabled}
                  onChange={() => setShowWeb((showWeb) => !showWeb)}
                  style={{ marginBottom: 0, marginLeft: 16 }}
                >
                  <Icon
                    icon="globe-network"
                    size={16}
                    style={{ marginRight: 4, marginTop: 3 }}
                    htmlTitle={`${currentSearchTopicClaim.webCount}`}
                  />
                </Checkbox>
                <Checkbox
                  checked={showStatusApproving}
                  onChange={() =>
                    setShowStatusApproving(
                      (showStatusApproving) => !showStatusApproving
                    )
                  }
                  style={{ marginBottom: 0, marginLeft: 16, color: "green" }}
                  disabled={showControlsDisabled}
                >
                  {monitoringPlusResultApprovedCount}
                </Checkbox>
                <Checkbox
                  checked={showStatusNone}
                  onChange={() =>
                    setShowStatusNone((showStatusNone) => !showStatusNone)
                  }
                  style={{ marginBottom: 0, marginLeft: 16, color: "#fbb360" }}
                  disabled={showControlsDisabled}
                >
                  {claimedMatchItemHits
                    ? claimedMatchItemHits.length -
                      monitoringPlusResultApprovedCount -
                      monitoringPlusResultRejectedCount
                    : 0}
                </Checkbox>
                <Checkbox
                  checked={showStatusRejecting}
                  onChange={() =>
                    setShowStatusRejecting(
                      (showStatusRejecting) => !showStatusRejecting
                    )
                  }
                  style={{ marginBottom: 0, marginLeft: 16, color: "red" }}
                  disabled={showControlsDisabled}
                >
                  {monitoringPlusResultRejectedCount}
                </Checkbox>
              </NavbarGroup>
            </Navbar>
            <Navbar>
              <NavbarGroup>
                <Button
                  intent="success"
                  onClick={processAllHits}
                  data-action="approving"
                  disabled={showControlsDisabled}
                >
                  Alles goed
                </Button>
              </NavbarGroup>
              <NavbarGroup align="right">
                <Button
                  intent="danger"
                  onClick={processAllHits}
                  data-action="rejecting"
                  disabled={showControlsDisabled}
                >
                  Alles fout
                </Button>
              </NavbarGroup>
            </Navbar>
          </div>
          <div
            style={{
              flex: 1,
              overflowX: "hidden",
              overflowY: "auto",
            }}
            ref={fixedSizeListContainerRef}
          >
            {claimedMatchItemHits !== null ? (
              <FixedSizeList
                ref={monitoringPlusListRef}
                itemCount={filteredClaimedMatchItemHits.length}
                itemSize={50}
                width={fixedSizeListContainerRef.current?.offsetWidth || 300}
                height={fixedSizeListContainerRef.current?.offsetHeight || 500}
              >
                {FilteredMatchItemRow}
              </FixedSizeList>
            ) : (
              <div style={{ marginTop: 20 }}>
                <Spinner />
              </div>
            )}
          </div>
          <div style={{ height: 64 }}>
            <Navbar>
              <NavbarGroup></NavbarGroup>
              <NavbarGroup className="bp5-align-right">
                <Button
                  intent="primary"
                  data-final="1"
                  onClick={submitClaimedHits}
                  disabled={showControlsDisabled}
                >
                  Verzenden
                </Button>
              </NavbarGroup>
            </Navbar>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default MonitoringPlus;
