import React from "react";
import { components } from "../types/openapi";
import { KunciContext } from "../inc/kunci";

const usePublication = (requiredPublicationId?: number) => {
  const { axios } = React.useContext(KunciContext);
  const [publication, setPublication] = React.useState<
    components["schemas"]["Publication"] | null
  >();
  React.useEffect(() => {
    if (!requiredPublicationId || publication !== undefined) {
      return;
    }
    setPublication(null);
    axios
      .request<components["schemas"]["Publication"][]>({
        method: "get",
        url: `/publication/source`,
        params: {
          publicationIds: requiredPublicationId,
        },
      })
      .then((res) => {
        if (res.data.length) {
          setPublication(res.data[0]);
        }
      });
  }, [axios, publication, requiredPublicationId, setPublication]);

  return publication;
};

export default usePublication;
