import React from "react";
import LRU from "lru-cache";
import { components } from "../types/openapi";
import { KunciContext } from "../inc/kunci";

const cache = new LRU<string, Promise<components["schemas"]["MatchItemHit"]>>({
  max: 100,
  ttl: 1000,
});

const useMatchItemHit = (requiredMatchItemHitId?: string) => {
  const { axios } = React.useContext(KunciContext);
  const [matchItemHit, setMatchItemHit] =
    React.useState<components["schemas"]["MatchItemHit"]>();
  React.useEffect(() => {
    if (!requiredMatchItemHitId || matchItemHit) {
      return;
    }
    let promise = cache.get(requiredMatchItemHitId);
    if (!promise) {
      promise = axios
        .request<components["schemas"]["MatchItemHit"]>({
          method: "get",
          url: `/matchItemHit/crud/${requiredMatchItemHitId}`,
        })
        .then((res) => res.data);
      cache.set(requiredMatchItemHitId, promise);
    }
    promise.then(setMatchItemHit);
  }, [axios, matchItemHit, requiredMatchItemHitId, setMatchItemHit]);

  return matchItemHit;
};

export default useMatchItemHit;
