import React from "react";
import { Button, Callout, Intent } from "@blueprintjs/core";
import Ajv from "ajv";
import { ValidationError } from "ajv";
import { KunciContext } from "../../inc/kunci";
import { SchemaObject } from "openapi3-ts";
import { useNavigate, useParams } from "react-router-dom";

import { ApiDataContext } from "../../provider/apiData";
import CenteredSpinner from "../../components/CenteredSpinner";
import openapi from "../../openapi.json";
import toaster from "../../inc/toaster";
import { components } from "../../types/openapi";
import useUsers from "../../hooks/useUsers";
import SchemaFormBody from "../../components/schemaForm/SchemaFormBody";
import ExcludedPublicationTypeIdsControl from "./ExcludedPublicationTypeIdsControl";
import ExcludedDomainTypeIdsControl from "./ExcludeDomainTypeIdsControl";
import { dereferencedOpenApi } from "../../inc/schema";

import "./index.scss";

const ajv = new Ajv({
  formats: {
    password: "",
  },
});
ajv.addSchema(openapi, "openapi.json");
const userSchema = dereferencedOpenApi.components.schemas.User as SchemaObject;

const User = () => {
  const { profile, setUsers } = React.useContext(ApiDataContext);
  const { auth, axios } = React.useContext(KunciContext);
  const { userRoles } = auth?.jwt as components["schemas"]["Jwt"];
  const { userId } = useParams();
  const navigate = useNavigate();
  const users = useUsers();

  const [formUser, setFormUser] = React.useState<components["schemas"]["User"]>(
    {
      displayName: "",
      userName: "",
      mustBeChecked: false,
      excludedPublicationTypeIds: [],
      excludedDomainTypeIds: [],
    }
  );
  const [errors, setErrors] = React.useState<ValidationError["errors"]>();
  const [isInProgress, setIsInProgress] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (users && userId && formUser.userId !== userId) {
      const newFormUser = users[userId];
      if (newFormUser) {
        setFormUser(newFormUser);
      }
    }
  }, [formUser, users, userId]);

  if (!users || !profile || isInProgress || !userSchema.properties) {
    return <CenteredSpinner />;
  }

  if (userRoles.indexOf("admin") === -1) {
    return (
      <Callout
        title="Pagina niet beschikbaar"
        intent={Intent.WARNING}
        style={{ margin: 8 }}
      >
        <p>U heeft niet voldoende rechten voor het openen van deze pagina.</p>
      </Callout>
    );
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (
          !ajv.validate(
            { $ref: "openapi.json#/components/schemas/User" },
            formUser
          ) &&
          ajv.errors
        ) {
          console.log(formUser, ajv.errors);
          setErrors(ajv.errors);
          return;
        }
        setErrors(undefined);
        setIsInProgress(true);
        axios
          .request<components["schemas"]["User"]>({
            method: formUser.userId ? "put" : "post",
            url: `/user/crud${formUser.userId ? `/${formUser.userId}` : ""}`,
            data: formUser,
          })
          .then((res) => {
            setIsInProgress(false);
            toaster.show({
              message: `Gebruiker opgeslagen`,
              intent: Intent.SUCCESS,
            });
            setUsers({
              ...users,
              [res.data.userId as string]: res.data,
            });
            navigate(`/users`);
          })
          .catch((err) => {
            setIsInProgress(false);
            toaster.show({
              message: `Opslaan gebruiker mislukt: ${err.message}`,
              intent: Intent.DANGER,
            });
          });
      }}
      style={{ maxWidth: 500, padding: 16 }}
      className="routes__user"
    >
      <SchemaFormBody<components["schemas"]["User"]>
        errors={errors}
        schema={userSchema}
        value={formUser}
        onChange={setFormUser}
        fieldConfigs={{
          appData: {
            isHidden: true,
          },
          userName: {
            isDisabled: !!formUser.userId,
          },
          excludedPublicationTypeIds: {
            Control: ExcludedPublicationTypeIdsControl,
          },
          excludedDomainTypeIds: {
            Control: ExcludedDomainTypeIdsControl,
          },
        }}
      />
      <Button
        type="submit"
        text="Opslaan"
        intent={Intent.PRIMARY}
        large={true}
      />
    </form>
  );
};

export default User;
