import ReactCrop, { Crop } from "react-image-crop";
import * as React from "react";
import "react-image-crop/lib/ReactCrop.scss";
import { components } from "../../../../types/openapi";

interface IReclipPageProps {
  page: components["schemas"]["Page"];
  onChange: (
    pageCorrection: components["schemas"]["PageCorrection"] | null
  ) => void;
}

const ReclipPage = ({ page, onChange }: IReclipPageProps) => {
  const imgRef = React.useRef<HTMLImageElement>();
  const [crop, setCrop] = React.useState<Crop>();

  return (
    <div style={{ marginRight: 16 }}>
      <ReactCrop
        src={`${page.url}?size=8`}
        imageStyle={{
          maxWidth: "80vw",
          maxHeight: "80vh",
        }}
        crop={crop}
        onChange={setCrop}
        onComplete={(_crop, percentCrop) => {
          const { x = 0, y = 0, width, height } = percentCrop;
          if (!imgRef.current || !width || !height) {
            onChange(null);
            return;
          }

          onChange({
            pageId: page.pageId,
            top: Math.round((y * imgRef.current.naturalHeight) / 100),
            left: Math.round((x * imgRef.current.naturalWidth) / 100),
            width: Math.round((width * imgRef.current.naturalWidth) / 100),
            height: Math.round((height * imgRef.current.naturalHeight) / 100),
          });
        }}
        onImageLoaded={(img) => {
          imgRef.current = img;
        }}
      />
    </div>
  );
};

export default ReclipPage;
