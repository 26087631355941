import React from "react";

interface IHighlightTextProps {
  style?: React.CSSProperties;
  text: string;
}

const HighlightedText = ({ style, text }: IHighlightTextProps) => (
  <div
    style={{
      ...style,
      position: "absolute",
      top: 0,
      left: 0,
      color: "transparent",
    }}
    className="highlighted-clip__title"
    dangerouslySetInnerHTML={{ __html: text }}
  />
);

export default HighlightedText;
