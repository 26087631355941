import React from "react";
import "./index.scss";

interface IKeyboardSvgProps {
  activeKeys?: number[];
  characterKeys?: string;
  modifierKeys?: string;
  enterAndEditingKeys?: string;
  systemAndGuiKeys?: string;
  navigationKeys?: string;
  functionKeys?: string;
  numericKeypad?: string;
  lockKeys?: string;
  characterKeyColor?: string;
  modifierKeyColor?: string;
  enterAndEditingKeyColor?: string;
  systemAndGuiKeyColor?: string;
  functionKeyColor?: string;
  navigationKeyColor?: string;
  numericKeyColor?: string;
  lockKeyColor?: string;
}

const Keyboard = (props: IKeyboardSvgProps) => {
  const {
    activeKeys = [],
    characterKeys = "Character keys",
    modifierKeys = "Modifier keys",
    enterAndEditingKeys = "Enter and editing keys",
    systemAndGuiKeys = "System and GUI keys",
    navigationKeys = "Navigation keys",
    functionKeys = "Function keys",
    numericKeypad = "Numeric keypad",
    lockKeys = "Lock keys",
    characterKeyColor = "#9fcfe0",
    modifierKeyColor = "#e89fa5",
    enterAndEditingKeyColor = "#f1ef9e",
    systemAndGuiKeyColor = "#f2c480",
    functionKeyColor = "#ffc1ea",
    navigationKeyColor = "#a8d1b1",
    numericKeyColor = "#b0bde0",
    lockKeyColor = "#cf98cf",
  } = props;

  interface IKeyProps {
    alt: string;
    code: number;
    children: any;
    location?: number;
  }

  const Key = (keyProps: IKeyProps) => {
    const { code, location, children } = keyProps;
    const isActive = activeKeys && activeKeys.indexOf(code) !== -1;
    return (
      <g
        id={`KEY_${code}${location ? `_${location}` : ""}`}
        className={isActive ? "KeyMap_Key_Active" : ""}
      >
        {children}
      </g>
    );
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 750.93869 339.11687"
      id="svg2"
      fillRule="evenodd"
      className="KeyMap"
    >
      <defs id="defs4">
        <marker orient="auto" id="TriangleOutM" overflow="visible">
          <path
            d="M2.308 0l-3.46 2v-4l3.46 2z"
            id="path4359"
            stroke="#000"
            strokeWidth=".4pt"
          />
        </marker>
        <marker orient="auto" id="TriangleOutS" overflow="visible">
          <path
            d="M1.154 0l-1.73 1v-2l1.73 1z"
            id="path4362"
            stroke="#000"
            strokeWidth=".2pt"
          />
        </marker>
        <marker orient="auto" id="TriangleOutL" overflow="visible">
          <path
            d="M4.616 0l-6.92 4v-8l6.92 4z"
            id="path4356"
            stroke="#000"
            strokeWidth=".8pt"
          />
        </marker>
        <marker orient="auto" id="Arrow1Lend" overflow="visible">
          <path
            d="M-10 0l-4 4L0 0l-14-4 4 4z"
            id="path4216"
            stroke="#000"
            strokeWidth=".8pt"
          />
        </marker>
        <marker orient="auto" id="Arrow2Lend" overflow="visible">
          <path
            d="M-10.69-4.437L1.328-.017l-12.018 4.42c1.92-2.61 1.91-6.18 0-8.84z"
            id="path4234"
            fontSize="12"
            strokeWidth=".625"
            strokeLinejoin="round"
          />
        </marker>
        <marker orient="auto" id="Arrow2Lstart" overflow="visible">
          <path
            d="M10.69 4.437L-1.328.017l12.018-4.42c-1.92 2.61-1.91 6.18 0 8.84z"
            id="path4231"
            fontSize="12"
            strokeWidth=".625"
            strokeLinejoin="round"
          />
        </marker>
        <style id="style6" />
        <marker orient="auto" id="TriangleOutSU" overflow="visible">
          <path
            d="M1.154 0l-1.73 1v-2l1.73 1z"
            id="path5625"
            fill="#2b2828"
            stroke="#2b2828"
            strokeWidth=".2pt"
          />
        </marker>
        <marker orient="auto" id="TriangleOutSU-2" overflow="visible">
          <path
            d="M1.154 0l-1.73 1v-2l1.73 1z"
            id="path5625-2"
            fill="#2b2828"
            stroke="#2b2828"
            strokeWidth=".2pt"
          />
        </marker>
        <marker orient="auto" id="TriangleOutSI" overflow="visible">
          <path
            d="M1.154 0l-1.73 1v-2l1.73 1z"
            id="path6762"
            fill="#2b2828"
            stroke="#2b2828"
            strokeWidth=".2pt"
          />
        </marker>
        <marker orient="auto" id="TriangleOutMu" overflow="visible">
          <path
            d="M2.308 0l-3.46 2v-4l3.46 2z"
            id="path10716"
            fill="#2b2828"
            stroke="#2b2828"
            strokeWidth=".4pt"
          />
        </marker>
        <marker orient="auto" id="TriangleOutMY" overflow="visible">
          <path
            d="M2.308 0l-3.46 2v-4l3.46 2z"
            id="path10719"
            fill="#2b2828"
            stroke="#2b2828"
            strokeWidth=".4pt"
          />
        </marker>
        <marker orient="auto" id="TriangleOutM1" overflow="visible">
          <path
            d="M2.308 0l-3.46 2v-4l3.46 2z"
            id="path11856"
            fill="#2b2828"
            stroke="#2b2828"
            strokeWidth=".4pt"
          />
        </marker>
        <marker orient="auto" id="TriangleOutM1-2" overflow="visible">
          <path
            d="M2.308 0l-3.46 2v-4l3.46 2z"
            id="path11856-5"
            fill="#2b2828"
            stroke="#2b2828"
            strokeWidth=".4pt"
          />
        </marker>
        <marker orient="auto" id="TriangleOutM1-6" overflow="visible">
          <path
            d="M2.308 0l-3.46 2v-4l3.46 2z"
            id="path11856-54"
            fill="#2b2828"
            stroke="#2b2828"
            strokeWidth=".4pt"
          />
        </marker>
        <marker orient="auto" id="TriangleOutM1-4" overflow="visible">
          <path
            d="M2.308 0l-3.46 2v-4l3.46 2z"
            id="path11856-9"
            fill="#2b2828"
            stroke="#2b2828"
            strokeWidth=".4pt"
          />
        </marker>
        <marker orient="auto" id="TriangleOutM1-1" overflow="visible">
          <path
            d="M2.308 0l-3.46 2v-4l3.46 2z"
            id="path11856-97"
            fill="#2b2828"
            stroke="#2b2828"
            strokeWidth=".4pt"
          />
        </marker>
        <marker orient="auto" id="TriangleOutM1-64" overflow="visible">
          <path
            d="M2.308 0l-3.46 2v-4l3.46 2z"
            id="path11856-3"
            fill="#2b2828"
            stroke="#2b2828"
            strokeWidth=".4pt"
          />
        </marker>
        <marker orient="auto" id="TriangleOutM1-5" overflow="visible">
          <path
            d="M2.308 0l-3.46 2v-4l3.46 2z"
            id="path11856-55"
            fill="#2b2828"
            stroke="#2b2828"
            strokeWidth=".4pt"
          />
        </marker>
        <marker orient="auto" id="TriangleOutM1-9" overflow="visible">
          <path
            d="M2.308 0l-3.46 2v-4l3.46 2z"
            id="path11856-0"
            fill="#2b2828"
            stroke="#2b2828"
            strokeWidth=".4pt"
          />
        </marker>
        <marker orient="auto" id="TriangleOutM1-17" overflow="visible">
          <path
            d="M2.308 0l-3.46 2v-4l3.46 2z"
            id="path11856-4"
            fill="#2b2828"
            stroke="#2b2828"
            strokeWidth=".4pt"
          />
        </marker>
        <marker orient="auto" id="TriangleOutMF" overflow="visible">
          <path
            d="M2.308 0l-3.46 2v-4l3.46 2z"
            id="path3528"
            fill="#2b2828"
            stroke="#2b2828"
            strokeWidth=".4pt"
          />
        </marker>
      </defs>
      <path
        id="background"
        fill="#f5f6f7"
        d="M37.522 12.62h678.853c12.106 0 21.418 8.73 21.418 21.335v186.19c0 11.636-9.312 22.303-21.418 22.303H37.522c-13.037 0-23.28-10.667-23.28-22.304V33.954c0-12.605 10.243-21.333 23.28-21.333z"
      />

      <g id="legend">
        <g id="characterKeysLegend">
          <path
            fill={characterKeyColor}
            d="M27.22 262.76h20c1 0 3 1 3 3v18c0 1-2 3-3 3h-20c-2 0-3-2-3-3v-18c0-2 1-3 3-3z"
          />
          <text
            y="192"
            x="400"
            fontSize="16"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -254.295 131.413)"
          >
            {characterKeys}
          </text>
        </g>
        <g id="modifierKeysLegend">
          <path
            fill={modifierKeyColor}
            d="M27.42 295.396h20c1 0 3 1 3 3v18c0 1-2 3-3 3h-20c-2 0-3-2-3-3v-18c0-2 1-3 3-3z"
          />
          <text
            y="192"
            x="400"
            fontSize="16"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -254.295 164.054)"
          >
            {modifierKeys}
          </text>
        </g>
        <g id="enterAndEditingKeysLegend">
          <path
            fill={enterAndEditingKeyColor}
            d="M212.042 262.77h20c1 0 3 1 3 3v18c0 1-2 3-3 3h-20c-2 0-3-2-3-3v-18c0-2 1-3 3-3z"
          />
          <text
            y="192"
            x="400"
            fontSize="16"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -68.58 130.685)"
          >
            {enterAndEditingKeys}
          </text>
        </g>
        <g id="systemAndGuiKeysLegend">
          <path
            fill={systemAndGuiKeyColor}
            d="M212.02 295.396h20c1 0 3 1 3 3v18c0 1-2 3-3 3h-20c-2 0-3-2-3-3v-18c0-2 1-3 3-3z"
          />
          <text
            y="192"
            x="400"
            fontSize="16"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -67.7 164.065)"
          >
            {systemAndGuiKeys}
          </text>
        </g>
        <g id="navigationKeysLegend">
          <path
            fill={navigationKeyColor}
            d="M409.278 262.76h20c1 0 3 1 3 3v18c0 1-2 3-3 3h-20c-2 0-3-2-3-3v-18c0-2 1-3 3-3z"
          />
          <text
            y="192"
            x="400"
            fontSize="16"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 127.533 130.556)"
          >
            {navigationKeys}
          </text>
        </g>
        <g id="functionKeysLegend">
          <path
            id=""
            fill={functionKeyColor}
            d="M409.454 295.276h20c1 0 3 1 3 3v18c0 1-2 3-3 3h-20c-2 0-3-2-3-3v-18c0-2 1-3 3-3z"
          />
          <text
            id="text823"
            y="192"
            x="400"
            fontSize="16"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 127.81 164.31)"
          >
            {functionKeys}
          </text>
        </g>
        <g id="numericKeypadLegend">
          <path
            id=""
            fill={numericKeyColor}
            d="M597.975 262.76h20c1 0 3 1 3 3v18c0 1-2 3-3 3h-20c-2 0-3-2-3-3v-18c0-2 1-3 3-3z"
          />
          <text
            id="text811"
            y="192"
            x="400"
            fontSize="16"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 316.563 131.277)"
          >
            {numericKeypad}
          </text>
        </g>
        <g id="lockKeysLegend">
          <path
            id=""
            fill={lockKeyColor}
            d="M598.253 295.396h20c1 0 3 1 3 3v18c0 1-2 3-3 3h-20c-2 0-3-2-3-3v-18c0-2 1-3 3-3z"
          />
          <text
            id="text797"
            y="192"
            x="400"
            fontSize="16"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 316.845 164.728)"
          >
            {lockKeys}
          </text>
        </g>
      </g>

      <g id="characterKeys">
        <Key code={192} alt="~">
          <path
            fill={characterKeyColor}
            d="M39.284 83.632h20c1 0 3 1 3 3v17c0 2-2 3-3 3h-20c-2 0-3-1-3-3v-17c0-2 1-3 3-3z"
          />
          <text
            id="text639"
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -269.532 -56.99)"
          >
            ~
          </text>
          <text
            id="text635"
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -268.075 -44.004)"
          >
            `
          </text>
        </Key>
        <Key code={49} alt="1">
          <path
            fill={characterKeyColor}
            d="M69.284 83.632h20c2 0 3 1 3 3v17c0 2-1 3-3 3h-20c-2 0-3-1-3-3v-17c0-2 1-3 3-3z"
          />
          <text
            id="text619"
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -239.92 -56.36)"
          >
            !
          </text>
          <text
            id="text509"
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -240.853 -44.013)"
          >
            1
          </text>
        </Key>
        <Key code={50} alt="2">
          <path
            fill={characterKeyColor}
            d="M98.284 83.632h20c2 0 3 1 3 3v17c0 2-1 3-3 3h-20c-1 0-2-1-2-3v-17c0-2 1-3 2-3z"
          />
          <text
            id="text615"
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -209.317 -55.665)"
          >
            @
          </text>
          <text
            id="text513"
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -210.148 -44.013)"
          >
            2
          </text>
        </Key>
        <Key code={51} alt="3">
          <path
            fill={characterKeyColor}
            d="M128.284 83.632h20c2 0 3 1 3 3v17c0 2-1 3-3 3h-20c-2 0-3-1-3-3v-17c0-2 1-3 3-3z"
          />
          <text
            id="text611"
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -181.03 -56.246)"
          >
            #
          </text>
          <text
            id="text517"
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -181.03 -44.013)"
          >
            3
          </text>
        </Key>
        <Key code={52} alt="4">
          <path
            fill={characterKeyColor}
            d="M158.284 83.632h20c2 0 3 1 3 3v17c0 2-1 3-3 3h-20c-1 0-3-1-3-3v-17c0-2 2-3 3-3z"
          />
          <text
            id="text607"
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -151.337 -55.748)"
          >
            $
          </text>
          <text
            id="text521"
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -151.337 -44.04)"
          >
            4
          </text>
        </Key>
        <Key code={53} alt="5">
          <path
            fill={characterKeyColor}
            d="M188.284 83.632h19c2 0 3 1 3 3v17c0 2-1 3-3 3h-19c-1 0-3-1-3-3v-17c0-2 2-3 3-3z"
          />
          <text
            id="text603"
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -122.218 -56.246)"
          >
            %
          </text>
          <text
            id="text525"
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -120.693 -44.13)"
          >
            5
          </text>
        </Key>
        <Key code={54} alt="6">
          <path
            fill={characterKeyColor}
            d="M217.284 83.632h20c2 0 3 1 3 3v17c0 2-1 3-3 3h-20c-1 0-3-1-3-3v-17c0-2 2-3 3-3z"
          />
          <text
            id="text599"
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -92.028 -56.044)"
          >
            ^
          </text>
          <text
            id="text529"
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -92.393 -44.013)"
          >
            6
          </text>
        </Key>
        <Key code={55} alt="7">
          <path
            fill={characterKeyColor}
            d="M247.284 83.632h20c2 0 3 1 3 3v17c0 2-1 3-3 3h-20c-1 0-3-1-3-3v-17c0-2 2-3 3-3z"
          />
          <text
            id="text595"
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -62.262 -56.246)"
          >
            &amp;
          </text>
          <text
            id="text533"
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -61.662 -44.126)"
          >
            7
          </text>
        </Key>
        <Key code={56} alt="8">
          <path
            fill={characterKeyColor}
            d="M277.284 83.632h19c2 0 3 1 3 3v17c0 2-1 3-3 3h-19c-1 0-3-1-3-3v-17c0-2 2-3 3-3z"
          />
          <text
            id="text591"
            y="194"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -31.22 -57.388)"
          >
            *
          </text>
          <text
            id="text537"
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -31.997 -44.013)"
          >
            8
          </text>
        </Key>
        <Key code={57} alt="9">
          <path
            fill={characterKeyColor}
            d="M306.284 83.632h20c2 0 3 1 3 3v17c0 2-1 3-3 3h-20c-1 0-3-1-3-3v-17c0-2 2-3 3-3z"
          />
          <text
            id="text583"
            y="194"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -1.393 -57.793)"
          >
            (
          </text>
          <text
            id="text541"
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -2.305 -44.013)"
          >
            9
          </text>
        </Key>
        <Key code={48} alt="0">
          <path
            fill={characterKeyColor}
            d="M336.284 83.632h20c2 0 3 1 3 3v17c0 2-1 3-3 3h-20c-1 0-3-1-3-3v-17c0-2 2-3 3-3z"
          />
          <text
            id="text587"
            y="194"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 28.125 -57.793)"
          >
            )
          </text>
          <text
            id="text545"
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 27.23 -44.013)"
          >
            0
          </text>
        </Key>
        <Key code={189} alt="-">
          <path
            fill={characterKeyColor}
            d="M366.284 83.632h20c1 0 2 1 2 3v17c0 2-1 3-2 3h-20c-1 0-3-1-3-3v-17c0-2 2-3 3-3z"
          />
          <text
            id="text659"
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 57.94 -56.262)"
          >
            _
          </text>
          <text
            id="text655"
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 58.956 -45.156)"
          >
            -
          </text>
        </Key>
        <Key code={187} alt="=">
          <path
            fill={characterKeyColor}
            d="M395.284 83.632h20c2 0 3 1 3 3v17c0 2-1 3-3 3h-20c-1 0-2-1-2-3v-17c0-2 1-3 2-3z"
          />
          <text
            id="text667"
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 87.058 -55.688)"
          >
            +
          </text>
          <text
            id="text663"
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 87.058 -44.586)"
          >
            =
          </text>
        </Key>

        <Key code={81} alt="Q">
          <path
            fill={characterKeyColor}
            d="M87.284 110.634h19c2 0 3 1 3 3v18c0 1-1 3-3 3h-19c-1 0-3-2-3-3v-18c0-2 2-3 3-3z"
          />
          <text
            id="text203"
            y="192"
            x="400"
            fontSize="18"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -218.09 -20.683)"
          >
            Q
          </text>
        </Key>
        <Key code={87} alt="W">
          <path
            fill={characterKeyColor}
            d="M116.284 110.634h20c2 0 3 1 3 3v18c0 1-1 3-3 3h-20c-1 0-3-2-3-3v-18c0-2 2-3 3-3z"
          />
          <text
            id="text207"
            y="192"
            x="400"
            fontSize="18"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -190.377 -20.683)"
          >
            W
          </text>
        </Key>
        <Key code={69} alt="E">
          <path
            fill={characterKeyColor}
            d="M146.284 110.634h20c2 0 3 1 3 3v18c0 1-1 3-3 3h-20c-1 0-3-2-3-3v-18c0-2 2-3 3-3z"
          />
          <text
            id="text211"
            y="192"
            x="400"
            fontSize="18"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -157.716 -20.683)"
          >
            E
          </text>
        </Key>
        <Key code={82} alt="R">
          <path
            fill={characterKeyColor}
            d="M176.284 110.634h19c2 0 3 1 3 3v18c0 1-1 3-3 3h-19c-1 0-3-2-3-3v-18c0-2 2-3 3-3z"
          />
          <text
            id="text215"
            y="192"
            x="400"
            fontSize="18"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -129.014 -20.683)"
          >
            R
          </text>
        </Key>
        <Key code={84} alt="T">
          <path
            fill={characterKeyColor}
            d="M205.284 110.634h20c2 0 3 1 3 3v18c0 1-1 3-3 3h-20c-1 0-2-2-2-3v-18c0-2 1-3 2-3z"
          />
          <text
            id="text219"
            y="192"
            x="400"
            fontSize="18"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -98.332 -20.683)"
          >
            T
          </text>
        </Key>
        <Key code={89} alt="Y">
          <path
            fill={characterKeyColor}
            d="M235.284 110.634h20c2 0 3 1 3 3v18c0 1-1 3-3 3h-20c-1 0-2-2-2-3v-18c0-2 1-3 2-3z"
          />
          <text
            id="text223"
            y="192"
            x="400"
            fontSize="18"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -68.64 -20.683)"
          >
            Y
          </text>
        </Key>
        <Key code={85} alt="U">
          <path
            fill={characterKeyColor}
            d="M265.284 110.634h20c2 0 3 1 3 3v18c0 1-1 3-3 3h-20c-1 0-2-2-2-3v-18c0-2 1-3 2-3z"
          />
          <text
            id="text227"
            y="192"
            x="400"
            fontSize="18"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -38.948 -20.683)"
          >
            U
          </text>
        </Key>
        <Key code={73} alt="I">
          <path
            fill={characterKeyColor}
            d="M294.284 110.634h20c2 0 3 1 3 3v18c0 1-1 3-3 3h-20c-1 0-2-2-2-3v-18c0-2 1-3 2-3z"
          />
          <text
            id="text231"
            y="192"
            x="400"
            fontSize="18"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -6.287 -20.683)"
          >
            I
          </text>
        </Key>
        <Key code={79} alt="O">
          <path
            fill={characterKeyColor}
            d="M324.284 110.634h20c2 0 3 1 3 3v18c0 1-1 3-3 3h-20c-1 0-2-2-2-3v-18c0-2 1-3 2-3z"
          />
          <text
            id="text235"
            y="192"
            x="400"
            fontSize="18"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 19.446 -20.683)"
          >
            O
          </text>
        </Key>
        <Key code={80} alt="P">
          <path
            fill={characterKeyColor}
            d="M354.284 110.634h20c2 0 3 1 3 3v18c0 1-1 3-3 3h-20c-1 0-2-2-2-3v-18c0-2 1-3 2-3z"
          />
          <text
            y="192"
            x="400"
            fontSize="18"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 50.127 -20.683)"
          >
            P
          </text>
        </Key>
        <Key code={219} alt="{">
          <path
            fill={characterKeyColor}
            d="M384.284 110.634h19c2 0 3 1 3 3v18c0 1-1 3-3 3h-19c-1 0-2-2-2-3v-18c0-2 1-3 2-3z"
          />
          <text
            id="text587-7"
            y="194"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 76.777 -30.7)"
          >
            &#123;
          </text>
          <text
            id="text545-3"
            y="192"
            x="401.207"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 75.883 -17.49)"
          >
            [
          </text>
        </Key>
        <Key code={221} alt="}">
          <path
            fill={characterKeyColor}
            d="M413.284 110.634h20c2 0 3 1 3 3v18c0 1-1 3-3 3h-20c-1 0-2-2-2-3v-18c0-2 1-3 2-3z"
          />
          <text
            y="194"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 106.065 -30.706)"
          >
            &#125;
          </text>
          <text
            y="192"
            x="401.207"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 105.17 -17.498)"
          >
            ]
          </text>
        </Key>
        <Key code={220} alt="\">
          <path
            fill={characterKeyColor}
            d="M442.200 110.636 h33 c1 0 2 2 2 3 v17 c0 2-1 3-2 3 h-33 c-1 0-2-1-2-3 v-17 c0-1 1-3 2-3 z"
          />
          <text
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 134.065 -28.706)"
          >
            |
          </text>
          <text
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 134.156 -17.498)"
          >
            \
          </text>
        </Key>

        <Key code={65} alt="A">
          <path
            fill={characterKeyColor}
            d="M95.284 138.637h19c1 0 3 1 3 2v19c0 1-2 2-3 2h-19c-2 0-3-1-3-2v-19c0-1 1-2 3-2z"
          />
          <text
            id="text243"
            y="192"
            x="400"
            fontSize="18"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -210.172 7.016)"
          >
            A
          </text>
        </Key>
        <Key code={83} alt="S">
          <path
            fill={characterKeyColor}
            d="M124.284 138.637h20c1 0 3 1 3 2v19c0 1-2 2-3 2h-20c-2 0-3-1-3-2v-19c0-1 1-2 3-2z"
          />
          <text
            id="text247"
            y="192"
            x="400"
            fontSize="18"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -179.49 7.016)"
          >
            S
          </text>
        </Key>
        <Key code={68} alt="D">
          <path
            fill={characterKeyColor}
            d="M154.284 138.637h20c2 0 3 1 3 2v19c0 1-1 2-3 2h-20c-2 0-3-1-3-2v-19c0-1 1-2 3-2z"
          />
          <text
            id="text251"
            y="192"
            x="400"
            fontSize="18"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -150.788 7.016)"
          >
            D
          </text>
        </Key>
        <Key code={70} alt="F">
          <path
            fill={characterKeyColor}
            d="M184.284 138.637h19c2 0 3 1 3 2v19c0 1-1 2-3 2h-19c-2 0-3-1-3-2v-19c0-1 1-2 3-2z"
          />
          <text
            id="text255"
            y="192"
            x="400"
            fontSize="18"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -120.106 7.016)"
          >
            F
          </text>
        </Key>
        <Key code={71} alt="G">
          <path
            fill={characterKeyColor}
            d="M213.284 138.637h20c2 0 3 1 3 2v19c0 1-1 2-3 2h-20c-2 0-3-1-3-2v-19c0-1 1-2 3-2z"
          />
          <text
            id="text259"
            y="192"
            x="400"
            fontSize="18"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -91.404 7.016)"
          >
            G
          </text>
        </Key>
        <Key code={72} alt="H">
          <path
            fill={characterKeyColor}
            d="M243.284 138.637h20c2 0 3 1 3 2v19c0 1-1 2-3 2h-20c-1 0-3-1-3-2v-19c0-1 2-2 3-2z"
          />
          <text
            id="text263"
            y="192"
            x="400"
            fontSize="18"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -61.712 7.016)"
          >
            H
          </text>
        </Key>
        <Key code={74} alt="J">
          <path
            fill={characterKeyColor}
            d="M273.284 138.637h19c2 0 3 1 3 2v19c0 1-1 2-3 2h-19c-1 0-3-1-3-2v-19c0-1 2-2 3-2z"
          />
          <text
            id="text267"
            y="192"
            x="400"
            fontSize="18"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -30.04 7.016)"
          >
            J
          </text>
        </Key>
        <Key code={75} alt="K">
          <path
            fill={characterKeyColor}
            d="M302.284 138.637h20c2 0 3 1 3 2v19c0 1-1 2-3 2h-20c-1 0-3-1-3-2v-19c0-1 2-2 3-2z"
          />
          <text
            id="text271"
            y="192"
            x="400"
            fontSize="18"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -2.328 7.016)"
          >
            K
          </text>
        </Key>
        <Key code={76} alt="L">
          <path
            fill={characterKeyColor}
            d="M332.284 138.637h20c2 0 3 1 3 2v19c0 1-1 2-3 2h-20c-1 0-3-1-3-2v-19c0-1 2-2 3-2z"
          />
          <text
            id="text275"
            y="192"
            x="400"
            fontSize="18"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 28.353 7.016)"
          >
            L
          </text>
        </Key>
        <Key code={186} alt=";">
          <path
            fill={characterKeyColor}
            d="M362.284 138.637h20c2 0 3 1 3 2v19c0 1-1 2-3 2h-20c-1 0-3-1-3-2v-19c0-1 2-2 3-2z"
          />
          <text
            id="text587-9-4"
            y="194"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 54.008 -2.867)"
          >
            :
          </text>
          <text
            id="text545-5-4"
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 53.865 10.056)"
          >
            ;
          </text>
        </Key>
        <Key code={188} alt="'">
          <path
            fill={characterKeyColor}
            d="M391.284 138.637h20c2 0 3 1 3 2v19c0 1-1 2-3 2h-20c-1 0-3-1-3-2v-19c0-1 2-2 3-2z"
          />
          <text
            id="text587-9-5"
            y="194"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 81.163 -2.866)"
          >
            &quot;
          </text>
          <text
            id="text545-5-7"
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 85.09 10.628)"
          >
            &apos;
          </text>
        </Key>

        <Key code={90} alt="Z">
          <path
            fill={characterKeyColor}
            d="M105.284 165.64h21c1 0 2 2 2 3v17c0 2-1 3-2 3h-21c-1 0-2-1-2-3v-17c0-1 1-3 2-3z"
          />
          <text
            id="text279"
            y="192"
            x="400"
            fontSize="18"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -198.295 33.726)"
          >
            Z
          </text>
        </Key>
        <Key code={88} alt="X">
          <path
            fill={characterKeyColor}
            d="M135.284 165.64h21c1 0 2 2 2 3v17c0 2-1 3-2 3h-21c-1 0-2-1-2-3v-17c0-1 1-3 2-3z"
          />
          <text
            id="text283"
            y="192"
            x="400"
            fontSize="18"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -168.603 33.726)"
          >
            X
          </text>
        </Key>
        <Key code={67} alt="C">
          <path
            fill={characterKeyColor}
            d="M165.284 165.64h21c1 0 2 2 2 3v17c0 2-1 3-2 3h-21c-1 0-2-1-2-3v-17c0-1 1-3 2-3z"
          />
          <text
            id="text287"
            y="192"
            x="400"
            fontSize="18"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -138.91 33.726)"
          >
            C
          </text>
        </Key>
        <Key code={86} alt="V">
          <path
            fill={characterKeyColor}
            d="M194.284 165.64h21c1 0 2 2 2 3v17c0 2-1 3-2 3h-21s-1-1-1-3v-17c0-1 1-3 1-3z"
          />
          <text
            id="text291"
            y="192"
            x="400"
            fontSize="18"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -109.22 33.726)"
          >
            V
          </text>
        </Key>
        <Key code={66} alt="B">
          <path
            fill={characterKeyColor}
            d="M225.284 165.64h20c1 0 2 2 2 3v17c0 2-1 3-2 3h-20c-2 0-3-1-3-3v-17c0-1 1-3 3-3z"
          />
          <text
            id="text295"
            y="192"
            x="400"
            fontSize="18"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -79.527 33.726)"
          >
            B
          </text>
        </Key>
        <Key code={78} alt="N">
          <path
            fill={characterKeyColor}
            d="M255.284 165.64h20c1 0 2 2 2 3v17c0 2-1 3-2 3h-20c-2 0-3-1-3-3v-17c0-1 1-3 3-3z"
          />
          <text
            id="text299"
            y="192"
            x="400"
            fontSize="18"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -49.835 33.726)"
          >
            N
          </text>
        </Key>
        <Key code={77} alt="M">
          <path
            fill={characterKeyColor}
            d="M285.284 165.64h19c1 0 2 2 2 3v17c0 2-1 3-2 3h-19c-2 0-3-1-3-3v-17c0-1 1-3 3-3z"
          />
          <text
            id="text303"
            y="192"
            x="400"
            fontSize="18"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -21.133 33.726)"
          >
            M
          </text>
        </Key>
        <Key code={188} alt=",">
          <path
            fill={characterKeyColor}
            d="M314.284 165.64h20c1 0 2 2 2 3v17c0 2-1 3-2 3h-20c-2 0-3-1-3-3v-17c0-1 1-3 3-3z"
          />
          <text
            id="text731"
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 4.623 27.25)"
          >
            &lt;
          </text>
          <text
            id="text727"
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 6.07 36.665)"
          >
            ,
          </text>
        </Key>
        <Key code={190} alt=".">
          <path
            fill={characterKeyColor}
            d="M344.284 165.64h20c1 0 2 2 2 3v17c0 2-1 3-2 3h-20c-2 0-3-1-3-3v-17c0-1 1-3 3-3z"
          />
          <text
            id="text723"
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 35.304 27.25)"
          >
            &gt;
          </text>
          <text
            id="text719"
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 36.707 36.665)"
          >
            .
          </text>
        </Key>
        <Key code={191} alt="/">
          <path
            fill={characterKeyColor}
            d="M374.284 165.64h19c1 0 2 2 2 3v17c0 2-1 3-2 3h-19c-2 0-3-1-3-3v-17c0-1 1-3 3-3z"
          />
          <text
            id="text715"
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 64.996 26.106)"
          >
            ?
          </text>
          <text
            id="text711"
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 66.616 36.665)"
          >
            /
          </text>
        </Key>

        <path
          id="KEY_SPACE"
          fill={characterKeyColor}
          d="M157.224 192.642h-10.16c-1.847 0-2.77 1-2.77 3v18c0 2 .923 3 2.77 3h181.96s.923-1 .923-3v-18c0-2-.924-3-.924-3h-171.8z"
        />
      </g>

      <g id="modifierKeys">
        <Key code={16} location={1} alt="ShiftLeft">
          <path
            fill={modifierKeyColor}
            d="M68.283 165.136h29.51c.983 0 1.966 1 1.966 3v18c0 2-.99 3-1.97 3H39.28c-1.965 0-2.95-1-2.95-3v-18c0-2 .985-3 2.95-3z"
          />
          <path d="M55.628 176.315l-2.082-2.08-2.083 2.08h1.026v4.05h2.11v-4.05h1.02m-6.06.782l3.98-3.97 3.973 3.97H55.38v4.05H51.7v-4.05h-2.13" />
        </Key>
        <Key code={17} location={1} alt="ControlLeft">
          <path
            fill={modifierKeyColor}
            d="M39.235 192.642h29.51c.983 0 1.967 1 1.967 3v18c0 2-.984 3-1.967 3h-29.51c-1.967 0-2.95-1-2.95-3v-18c0-2 .983-3 2.95-3z"
          />
          <text
            id="text575"
            y="192"
            x="400"
            fontSize="9"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -262.065 59.448)"
          >
            Ctrl
          </text>
        </Key>
        <Key code={18} location={1} alt="AltLeft">
          <path
            fill={modifierKeyColor}
            d="M111.223 192.642h25.987c1.04 0 2.08 1 2.08 3v18c0 1-1.04 3-2.08 3h-25.987c-1.04 0-2.08-2-2.08-3v-18c0-2 1.04-3 2.08-3z"
          />
          <text
            y="192"
            x="400"
            fontSize="9"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -190.486 58.46)"
          >
            Alt
          </text>
        </Key>
        <Key code={18} location={2} alt="AltRight">
          <path
            fill={modifierKeyColor}
            d="M336.955 192.642h27.064c1.12 0 2.25 1 2.25 3v18c0 1-1.13 3-2.26 3h-27.06c-1.128 0-2.255-2-2.255-3v-18c0-2 1.127-3 2.255-3z"
          />
          <text
            id="text869"
            y="192"
            x="400"
            fontSize="9"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 31.97 58.46)"
          >
            Alt Gr
          </text>
        </Key>
        <Key code={17} location={2} alt="ControlRight">
          <path
            fill={modifierKeyColor}
            d="M442.284 192.642h33c2 0 3 1 3 3v18c0 2-1 3-3 3h-33c-2 0-3-1-3-3v-18c0-2 1-3 3-3z"
          />
          <text
            y="192"
            x="400"
            fontSize="9"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 143.16 59.448)"
          >
            Ctrl
          </text>
        </Key>
        <Key code={16} location={2} alt="ShiftRight">
          <path
            fill={modifierKeyColor}
            d="M402.284 188.64c-1 0-2-1-2-3v-17c0-1 1-3 2-3h73c2 0 3 2 3 3v17c0 2-1 3-3 3h-73z"
          />
          <path
            d="M441.367 176.318l-2.083-2.08-2.082 2.08h1.026v4.05h2.113v-4.05h1.03m-6.06.782l3.98-3.97 3.98 3.97h-2.136v4.05h-3.68v-4.05h-2.136"
            fill="#2b2828"
          />
        </Key>
      </g>

      <g id="enterAndEditingKey">
        <Key code={45} alt="Insert">
          <path
            fill={enterAndEditingKeyColor}
            d="M500.327 83.632h20c1 0 2 1 2 2v18c0 1-1 2-2 2h-20c-2 0-3-1-3-2v-18c0-1 1-2 3-2z"
          />
          <text
            id="text497"
            y="192"
            x="400"
            fontSize="9"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 191.567 -51.347)"
          >
            Insert
          </text>
        </Key>
        <Key code={46} alt="Delete">
          <path
            fill={enterAndEditingKeyColor}
            d="M500.327 110.39h20c1 0 2 2 2 3v18c0 2-1 3-2 3h-20c-2 0-3-1-3-3v-18c0-1 1-3 3-3z"
          />
          <text
            id="text501"
            y="192"
            x="400"
            fontSize="9"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 190.577 -22.892)"
          >
            Delete
          </text>
        </Key>
        <Key code={13} location={3} alt="NumpadEnter">
          <path
            fill={enterAndEditingKeyColor}
            d="M714.75 168.744c0-2.085-1-3.127-3-3.127h-20c-1 0-3 1.042-3 3.127v44.813c0 1.042 2 2.085 3 2.085h20c2 0 3-1.043 3-2.085v-44.813z"
          />
          <text
            id="text505"
            y="192"
            x="400"
            fontSize="9"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 383.843 43.61)"
          >
            Enter
          </text>
        </Key>
        <Key code={8} alt="Backspace">
          <path
            fill={enterAndEditingKeyColor}
            d="M425.075 83.762c-2.084 0-3.126 1-3.126 3l.28 16.85c0 1 1.04 3 3.123 3l50.54-.286c1.042 0 2.084-2 2.084-3l-.286-16.85c0-2-1.044-3-2.086-3l-50.54.286z"
          />
          <path
            d="M446.16 94.48h-15.018"
            fill="none"
            stroke="#2b2828"
            strokeWidth="1.001"
            markerEnd="url(#TriangleOutM1)"
          />
        </Key>
        <Key code={13} alt="Enter">
          <path
            fill={enterAndEditingKeyColor}
            d="M420.61 138.545 h55 c1 0 2 1 2 2 v19 c0 1-1 2-2 2 h-55 c-2 0-3-1-3-2 v-19 c0-1 1-2 3-2z"
          />
          <path
            d="M440.61 145.545v6.773h-14.76"
            fill="none"
            stroke="#2b2828"
            strokeWidth="1.001"
            markerEnd="url(#TriangleOutMF)"
          />
        </Key>
      </g>

      <g id="systemAndGuiKeys">
        <Key code={27} alt="Escape">
          <path
            d="M39.284 42.632h20c1 0 3 1 3 3v18c0 1-2 3-3 3h-20c-2 0-3-2-3-3v-18c0-2 1-3 3-3z"
            fill={systemAndGuiKeyColor}
          />
          <text
            y="192"
            x="400"
            fontSize="9"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -266.586 -91.868)"
          >
            Esc
          </text>
        </Key>
        <Key alt="Print screen" code={44}>
          <path
            d="M498.327 41.632h20c2 0 3 2 3 3v18c0 2-1 3-3 3h-20c-1 0-3-1-3-3v-18c0-1 2-3 3-3z"
            fill={systemAndGuiKeyColor}
          />
          <text
            y="192"
            x="400"
            fontSize="9"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 188.598 -99.774)"
          >
            PrtScn
          </text>
          <text
            y="192"
            x="400"
            fontSize="9"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 188.598 -85.938)"
          >
            SysRq
          </text>
        </Key>
        <Key code={19} alt="Pause">
          <path
            d="M558.327 41.632h19c2 0 3 2 3 3v18c0 2-1 3-3 3h-19c-1 0-3-1-3-3v-18c0-1 2-3 3-3z"
            fill={systemAndGuiKeyColor}
          />
          <text
            y="192"
            x="400"
            fontSize="9"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 247.98 -99.774)"
          >
            Pause
          </text>
          <text
            y="192"
            x="400"
            fontSize="9"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 248.97 -85.938)"
          >
            Break
          </text>
        </Key>
        <Key code={91} alt="SuperSL">
          <path
            d="M374.527 192.642h23.3c1.226 0 2.452 1 2.452 3v18c0 2-1.23 3-2.46 3h-23.3c-2.455 0-3.68-1-3.68-3v-18c0-2 1.225-3 3.68-3z"
            fill={systemAndGuiKeyColor}
          />
        </Key>
        <Key code={92} alt="SuperSR">
          <path
            d="M408.21 192.642h24.613c1.23 0 2.46 1 2.46 3v18c0 2-1.23 3-2.46 3H408.21c-2.462 0-3.693-1-3.693-3v-18c0-2 1.23-3 3.692-3z"
            fill={systemAndGuiKeyColor}
          />
        </Key>
        <Key code={93} alt="ContextMenu">
          <path
            d="M77.93 192.642h23.383c2.46 0 3.692 1 3.692 3v18c0 1-1.23 3-3.692 3H77.93c-1.23 0-3.692-2-3.692-3v-18c0-2 2.46-3 3.692-3z"
            fill={systemAndGuiKeyColor}
          />
        </Key>
      </g>

      <g id="navigationKey">
        <Key code={9} alt="Tab">
          <path
            d="M39.284 134.634c-2 0-3-2-3-3v-18c0-2 1-3 3-3h38c2 0 3 1 3 3v18c0 1-1 3-3 3h-38z"
            fill={navigationKeyColor}
          />
          <text
            y="192"
            x="400"
            fontSize="9"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -270.545 -28.59)"
          >
            Tab
          </text>
          <g stroke="#2b2828">
            <path
              d="M82.103 127.42H72.09"
              markerEnd="url(#TriangleOutMu)"
              transform="translate(-7.716 -10.366)"
            />
            <path d="M61.37 115.05v4.006" />
          </g>
          <g stroke="#2b2828">
            <path
              d="M69.086 135.43H79.1"
              markerEnd="url(#TriangleOutMY)"
              transform="translate(-7.716 -10.366)"
            />
            <path d="M74.387 123.06v4.006" />
          </g>
        </Key>
        <Key code={36} alt="Home">
          <path
            d="M530.327 83.632h20c1 0 2 1 2 2v18c0 1-1 2-2 2h-20c-2 0-3-1-3-2v-18c0-1 1-2 3-2z"
            fill={navigationKeyColor}
          />
          <text
            y="192"
            x="400"
            fontSize="9"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 220.27 -51.347)"
          >
            Home
          </text>
        </Key>
        <Key code={33} alt="PageUp">
          <path
            d="M560.327 83.632h19c1 0 2 1 2 2v18c0 1-1 2-2 2h-19c-2 0-3-1-3-2v-18c0-1 1-2 3-2z"
            fill={navigationKeyColor}
          />
          <text
            y="192"
            x="400"
            fontSize="9"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 251.94 -56.29)"
          >
            Page
          </text>
          <text
            y="192"
            x="400"
            fontSize="9"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 251.94 -48.382)"
          >
            Up
          </text>
        </Key>
        <Key code={35} alt="End">
          <path
            d="M530.327 110.39h20c1 0 2 2 2 3v18c0 2-1 3-2 3h-20c-2 0-3-1-3-3v-18c0-1 1-3 3-3z"
            fill={navigationKeyColor}
          />
          <text
            y="192"
            x="400"
            fontSize="9"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 224.228 -22.892)"
          >
            End
          </text>
        </Key>
        <Key code={34} alt="PageDown">
          <path
            d="M560.327 110.39h19c1 0 2 2 2 3v18c0 2-1 3-2 3h-19c-2 0-3-1-3-3v-18c0-1 1-3 3-3z"
            fill={navigationKeyColor}
          />
          <text
            y="192"
            x="400"
            fontSize="9"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 250.95 -26.845)"
          >
            Page
          </text>
          <text
            y="192"
            x="400"
            fontSize="9"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 250.95 -18.938)"
          >
            Down
          </text>
        </Key>
        <Key code={37} alt="ArrowLeft">
          <path
            fill={navigationKeyColor}
            d="M500.327 192.642h20c1 0 2 1 2 2v19c0 1-1 2-2 2h-20c-2 0-3-1-3-2v-19c0-1 1-2 3-2z"
          />
          <path
            d="M514.997 205.163h-10.013"
            stroke="#2b2828"
            strokeWidth="1.001"
            markerEnd="url(#TriangleOutM1)"
          />
        </Key>
        <Key code={38} alt="ArrowUp">
          <path
            fill={navigationKeyColor}
            d="M530.327 165.64h20c1 0 2 1 2 3v17c0 1-1 3-2 3h-20c-2 0-3-2-3-3v-17c0-2 1-3 3-3z"
          />
          <path
            d="M540.028 183.136v-10.013"
            stroke="#2b2828"
            strokeWidth="1.001"
            markerEnd="url(#TriangleOutM1)"
          />
        </Key>
        <Key code={39} alt="ArrowRight">
          <path
            fill={navigationKeyColor}
            d="M560.327 192.642h19c1 0 2 1 2 2v19c0 1-1 2-2 2h-19c-2 0-3-1-3-2v-19c0-1 1-2 3-2z"
          />
          <path
            d="M565.06 205.163h10.012"
            stroke="#2b2828"
            strokeWidth="1.001"
            markerEnd="url(#TriangleOutM1)"
          />
        </Key>
        <Key code={40} alt="ArrowDown">
          <path
            fill={navigationKeyColor}
            d="M530.327 192.642h20c1 0 2 1 2 2v19c0 1-1 2-2 2h-20c-2 0-3-1-3-2v-19c0-1 1-2 3-2z"
          />
          <path
            d="M540.028 198.216v10.013"
            stroke="#2b2828"
            strokeWidth="1.001"
            markerEnd="url(#TriangleOutM1)"
          />
        </Key>
      </g>

      <g id="functionKeys">
        <Key code={112} alt="F1">
          <path
            d="M98.284 42.632h20c2 0 3 1 3 3v18c0 1-1 3-3 3h-20c-1 0-2-2-2-3v-18c0-2 1-3 2-3z"
            fill={functionKeyColor}
          />
          <text
            y="192"
            x="400"
            fontSize="13"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -206.213 -90.88)"
          >
            F1
          </text>
        </Key>
        <Key code={113} alt="F2">
          <path
            d="M128.284 42.632h20c2 0 3 1 3 3v18c0 1-1 3-3 3h-20c-2 0-3-2-3-3v-18c0-2 1-3 3-3z"
            fill={functionKeyColor}
          />
          <text
            y="192"
            x="400"
            fontSize="13"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -177.51 -90.88)"
          >
            F2
          </text>
        </Key>
        <Key code={114} alt="F3">
          <path
            d="M158.284 42.632h20c2 0 3 1 3 3v18c0 1-1 3-3 3h-20c-1 0-3-2-3-3v-18c0-2 2-3 3-3z"
            fill={functionKeyColor}
          />
          <text
            y="192"
            x="400"
            fontSize="13"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -147.82 -90.88)"
          >
            F3
          </text>
        </Key>
        <Key code={115} alt="F4">
          <path
            d="M188.284 42.632h19c2 0 3 1 3 3v18c0 1-1 3-3 3h-19c-1 0-3-2-3-3v-18c0-2 2-3 3-3z"
            fill={functionKeyColor}
          />
          <text
            y="192"
            x="400"
            fontSize="13"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -118.127 -90.88)"
          >
            F4
          </text>
        </Key>
        <Key code={116} alt="F5">
          <path
            d="M232.284 41.632h20c1 0 2 2 2 3v18c0 2-1 3-2 3h-20c-2 0-3-1-3-3v-18c0-1 1-3 3-3z"
            fill={functionKeyColor}
          />
          <text
            y="192"
            x="400"
            fontSize="13"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -73.59 -90.88)"
          >
            F5
          </text>
        </Key>
        <Key code={117} alt="F6">
          <path
            d="M262.284 41.632h20c1 0 2 2 2 3v18c0 2-1 3-2 3h-20c-2 0-3-1-3-3v-18c0-1 1-3 3-3z"
            fill={functionKeyColor}
          />
          <text
            y="192"
            x="400"
            fontSize="13"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -43.897 -90.88)"
          >
            F6
          </text>
        </Key>
        <Key code={118} alt="F7">
          <path
            d="M291.284 41.632h20c1 0 2 2 2 3v18c0 2-1 3-2 3h-20c-1 0-2-1-2-3v-18c0-1 1-3 2-3z"
            fill={functionKeyColor}
          />
          <text
            y="192"
            x="400"
            fontSize="13"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -14.205 -90.88)"
          >
            F7
          </text>
        </Key>
        <Key code={119} alt="F8">
          <path
            d="M321.284 41.632h20c1 0 2 2 2 3v18c0 2-1 3-2 3h-20c-2 0-3-1-3-3v-18c0-1 1-3 3-3z"
            fill={functionKeyColor}
          />
          <text
            y="192"
            x="400"
            fontSize="13"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 15.487 -90.88)"
          >
            F8
          </text>
        </Key>
        <Key code={120} alt="F9">
          <path
            d="M365.284 41.632h20c1 0 2 2 2 3v18c0 2-1 3-2 3h-20c-2 0-3-1-3-3v-18c0-1 1-3 3-3z"
            fill={functionKeyColor}
          />
          <text
            y="192"
            x="400"
            fontSize="13"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 59.035 -90.88)"
          >
            F9
          </text>
        </Key>
        <Key code={121} alt="F10">
          <path
            d="M394.284 41.632h20c1 0 2 2 2 3v18c0 2-1 3-2 3h-20c-2 0-3-1-3-3v-18c0-1 1-3 3-3z"
            fill={functionKeyColor}
          />
          <text
            y="192"
            x="400"
            fontSize="13"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 85.757 -90.88)"
          >
            F10
          </text>
        </Key>
        <Key code={122} alt="F11">
          <path
            d="M424.284 41.632h20c1 0 2 2 2 3v18c0 2-1 3-2 3h-20c-2 0-3-1-3-3v-18c0-1 1-3 3-3z"
            fill={functionKeyColor}
          />
          <text
            y="192"
            x="400"
            fontSize="13"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 116.438 -90.88)"
          >
            F11
          </text>
        </Key>
        <Key code={123} alt="F12">
          <path
            d="M454.284 41.632h20c1 0 2 2 2 3v18c0 2-1 3-2 3h-20c-2 0-3-1-3-3v-18c0-1 1-3 3-3z"
            fill={functionKeyColor}
          />
          <text
            y="192"
            x="400"
            fontSize="13"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 145.14 -90.88)"
          >
            F12
          </text>
        </Key>
      </g>

      <g id="numericKeypad">
        <Key code={103} alt="Numpad7">
          <path
            d="M602.75 110.39h20c2 0 3 2 3 3v18c0 2-1 3-3 3h-20c-1 0-3-1-3-3v-18c0-1 2-3 3-3z"
            fill={numericKeyColor}
          />
          <text
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 293.627 -28.034)"
          >
            7
          </text>
          <text
            y="192"
            x="400"
            fontSize="9"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 293.802 -17.976)"
          >
            Home
          </text>
        </Key>
        <Key code={100} alt="Numpad4">
          <path
            d="M602.75 138.393h20c2 0 3 1 3 3v18c0 2-1 3-3 3h-20c-1 0-3-1-3-3v-18c0-2 2-3 3-3z"
            fill={numericKeyColor}
          />
          <text
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 293.802 -.007)"
          >
            4
          </text>
          <path
            d="M620.57 153.855h-10.013"
            fill="none"
            stroke="#2b2828"
            strokeWidth="1.001"
            markerEnd="url(#TriangleOutM1)"
          />
        </Key>
        <Key code={97} alt="Numpad1">
          <path
            d="M602.75 165.64h20c2 0 3 1 3 3v17c0 1-1 3-3 3h-20c-1 0-3-2-3-3v-17c0-2 2-3 3-3z"
            fill={numericKeyColor}
          />
          <text
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 293.983 26.892)"
          >
            1
          </text>
          <text
            y="192"
            x="400"
            fontSize="9"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 293.802 36.69)"
          >
            End
          </text>
        </Key>
        <Key code={111} alt="NumpadDivide">
          <path
            d="M632.75 83.632h19c2 0 3 1 3 2v18c0 1-1 2-3 2h-19c-1 0-3-1-3-2v-18c0-1 2-2 3-2z"
            fill={numericKeyColor}
          />
          <text
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 325.783 -55.274)"
          >
            /
          </text>
        </Key>
        <Key code={104} alt="Numpad8">
          <path
            d="M632.75 110.39h19c2 0 3 2 3 3v18c0 2-1 3-3 3h-19c-1 0-3-1-3-3v-18c0-1 2-3 3-3z"
            fill={numericKeyColor}
          />
          <text
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 324.505 -28.034)"
          >
            8
          </text>
          <path
            d="M645.6 129.825v-10.013"
            fill="none"
            stroke="#2b2828"
            strokeWidth="1.001"
            markerEnd="url(#TriangleOutM1)"
          />
        </Key>
        <Key code={101} alt="Numpad5">
          <path
            d="M632.75 138.393h19c2 0 3 1 3 3v18c0 2-1 3-3 3h-19c-1 0-3-1-3-3v-18c0-2 2-3 3-3z"
            fill={numericKeyColor}
          />
          <text
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 324.483 -.11)"
          >
            5
          </text>
        </Key>
        <Key code={98} alt="Numpad2">
          <path
            d="M632.75 165.64h19c2 0 3 1 3 3v17c0 1-1 3-3 3h-19c-1 0-3-2-3-3v-17c0-2 2-3 3-3z"
            fill={numericKeyColor}
          />
          <text
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 324.598 26.892)"
          >
            2
          </text>
          <path
            id="NumArrowUDown"
            d="M645.6 173.123v10.013"
            fill="none"
            stroke="#2b2828"
            strokeWidth="1.001"
            markerEnd="url(#TriangleOutM1)"
          />
        </Key>
        <Key code={106} alt="NumpadMultiply">
          <path
            d="M661.75 83.632h20c2 0 3 1 3 2v18c0 1-1 2-3 2h-20c-1 0-3-1-3-2v-18c0-1 2-2 3-2z"
            fill={numericKeyColor}
          />
          <text
            y="194"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 353.94 -55.274)"
          >
            *
          </text>
        </Key>
        <Key code={105} alt="Numpad9">
          <path
            d="M661.75 110.39h20c2 0 3 2 3 3v18c0 2-1 3-3 3h-20c-1 0-3-1-3-3v-18c0-1 2-3 3-3z"
            fill={numericKeyColor}
          />
          <text
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 353.157 -28.034)"
          >
            9
          </text>
          <text
            y="192"
            x="400"
            fontSize="9"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 353.185 -17.976)"
          >
            PgUp
          </text>
        </Key>
        <Key code={102} alt="Numpad6">
          <path
            d="M661.75 138.393h20c2 0 3 1 3 3v18c0 2-1 3-3 3h-20c-1 0-3-1-3-3v-18c0-2 2-3 3-3z"
            fill={numericKeyColor}
          />
          <text
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 353.185 -.11)"
          >
            6
          </text>
          <path
            d="M665.626 153.855h10.013"
            fill="none"
            stroke="#2b2828"
            strokeWidth="1.001"
            markerEnd="url(#TriangleOutM1)"
          />
        </Key>
        <Key code={99} alt="Numpad3">
          <path
            d="M661.75 165.64h20c2 0 3 1 3 3v17c0 1-1 3-3 3h-20c-1 0-3-2-3-3v-17c0-2 2-3 3-3z"
            fill={numericKeyColor}
          />
          <text
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 353.162 26.834)"
          >
            3
          </text>
          <text
            y="192"
            x="400"
            fontSize="9"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 353.185 36.69)"
          >
            PgDn
          </text>
        </Key>

        <Key code={109} alt="NumpadSubtract">
          <path
            d="M691.75 83.632h20c2 0 3 1 3 2v18c0 1-1 2-3 2h-20c-1 0-3-1-3-2v-18c0-1 2-2 3-2z"
            fill={numericKeyColor}
          />
          <text
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 383.028 -56.262)"
          >
            -
          </text>
        </Key>
        <Key code={107} alt="NumpadAdd">
          <path
            d="M714.75 113.87c0-1.032-1-3.097-3-3.097h-20c-1 0-3 2.065-3 3.098v45.43c0 2.07 2 3.1 3 3.1h20c2 0 3-1.03 3-3.094v-45.43z"
            fill={numericKeyColor}
          />
          <text
            y="194"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 381.864 -10.043)"
          >
            +
          </text>
        </Key>

        <Key code={96} alt="Numpad0">
          <path
            d="M602.75 215.642c-1 0-3-1-3-2v-19c0-1 2-2 3-2h49c2 0 3 1 3 2v19c0 1-1 2-3 2h-49z"
            fill={numericKeyColor}
          />
          <text
            y="192"
            x="400"
            fontSize="12"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 293.665 54.532)"
          >
            0
          </text>
          <text
            y="192"
            x="400"
            fontSize="9"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 294.79 64.39)"
          >
            Ins
          </text>
        </Key>

        <Key code={110} alt="NumpadDecimal">
          <path
            d="M661.75 192.642h20c2 0 3 1 3 2v19c0 1-1 2-3 2h-20c-1 0-3-1-3-2v-19c0-1 2-2 3-2z"
            fill={numericKeyColor}
          />
          <text
            y="192"
            x="400"
            fontSize="9"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 354.75 51.54)"
          >
            .
          </text>
          <text
            y="192"
            x="400"
            fontSize="9"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 353.185 64.39)"
          >
            Del
          </text>
        </Key>
      </g>

      <g id="lockKeys">
        <Key code={20} alt="Caps">
          <path
            d="M39.284 161.637c-1 0-3-1-3-2v-19c0-1 2-2 3-2h45c2 0 4 1 4 2v19c0 1-2 2-4 2h-45z"
            fill={lockKeyColor}
          />
          <text
            y="192"
            x="400"
            fontSize="9"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -255.7 .097)"
          >
            Caps
          </text>
          <text
            y="192"
            x="400"
            fontSize="9"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 -255.7 8.004)"
          >
            Lock
          </text>
        </Key>
        <Key code={145} alt="Lock">
          <path
            d="M528.327 41.632h20c2 0 3 2 3 3v18c0 2-1 3-3 3h-20c-1 0-3-1-3-3v-18c0-1 2-3 3-3z"
            fill={lockKeyColor}
          />
          <text
            y="192"
            x="400"
            fontSize="9"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 219.28 -95.82)"
          >
            Scroll
          </text>
          <text
            y="192"
            x="400"
            fontSize="9"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 219.28 -87.914)"
          >
            Lock
          </text>
        </Key>
        <Key code={144} alt="Num">
          <path
            d="M602.75 83.632h20c2 0 3 1 3 2v18c0 1-1 2-3 2h-20c-1 0-3-1-3-2v-18c0-1 2-2 3-2z"
            fill={lockKeyColor}
          />
          <text
            y="192"
            x="400"
            fontSize="9"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 295.757 -55.3)"
          >
            Num
          </text>
          <text
            y="192"
            x="400"
            fontSize="9"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 295.757 -47.394)"
          >
            Lock
          </text>
        </Key>
      </g>

      <g id="LockLeds">
        <g id="NumLockLed">
          <path d="M606.75 58.632h7v-3h-7v3z" fill="#2b2828" />
          <text
            y="192"
            x="400"
            fontSize="8"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 305.655 -92.856)"
          >
            Num
          </text>
          <text
            y="192"
            x="400"
            fontSize="8"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 305.655 -85.938)"
          >
            Lock
          </text>
        </g>

        <g id="CapsLockLed">
          <path d="M647.75 58.632h7v-3h-7v3z" fill="#2b2828" />
          <text
            y="192"
            x="400"
            fontSize="8"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 347.223 -92.856)"
          >
            Caps
          </text>
          <text
            y="192"
            x="400"
            fontSize="8"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 347.223 -85.938)"
          >
            Lock
          </text>
        </g>

        <g id="ScrollLockLed">
          <path d="M688.75 58.632h7v-3h-7v3z" fill="#2b2828" />
          <text
            y="192"
            x="400"
            fontSize="8"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 387.802 -92.856)"
          >
            Scroll
          </text>
          <text
            y="192"
            x="400"
            fontSize="8"
            fill="#2b2828"
            fontFamily="Arial"
            transform="matrix(.77456 0 0 .77345 387.802 -85.938)"
          >
            Lock
          </text>
        </g>
      </g>
    </svg>
  );
};

export default Keyboard;
