import { components } from "../../../types/openapi";
import { Spinner } from "@blueprintjs/core";
import * as React from "react";
import useMatchItemHit from "../../../hooks/useMatchItemHit";
import useSearchTopicMap from "../../../hooks/useSearchTopicMap";

const CustomerNameCell = (
  deleteRequest?: components["schemas"]["DeleteRequest"]
) => {
  const matchItemHit = useMatchItemHit(deleteRequest?.matchItemHitId);
  const { hydrateById, searchTopicMap } = useSearchTopicMap();

  React.useEffect(() => {
    if (matchItemHit?.searchTopicId) {
      hydrateById(matchItemHit?.searchTopicId);
    }
  }, [hydrateById, matchItemHit?.searchTopicId]);

  const searchTopic =
    searchTopicMap && matchItemHit?.searchTopicId
      ? searchTopicMap[matchItemHit?.searchTopicId]
      : undefined;
  if (!deleteRequest?.matchItemHitId) {
    return <span>-</span>;
  }
  return searchTopic ? (
    <strong>{searchTopic.customerName}</strong>
  ) : (
    <div style={{ paddingTop: 8 }}>
      <Spinner size={16} />!
    </div>
  );
};

export default CustomerNameCell;
