import React from "react";
import { ErrorObject } from "ajv";
import {
  Checkbox,
  FormGroup,
  InputGroup,
  MenuItem,
  Slider,
} from "@blueprintjs/core";
import SchemaFormBody, { IFieldConfig } from "../index";
import { getDomIdFromDataPath } from "../../index";
import { t } from "../../../../inc/i18n";
import StringFormControl from "./StringFormControl";
import { SchemaObject } from "openapi3-ts";
import { MultiSelect } from "@blueprintjs/select";

interface IFormGroupProps<T> {
  dataPath: string;
  fieldConfig?: IFieldConfig<T>;
  isRequired?: boolean;
  onChange: (newValue: any) => void;
  propErrors: ErrorObject[];
  schema: SchemaObject;
  value?: any; // T;
}

export default function SchemaFormGroup<T>({
  fieldConfig,
  isRequired,
  dataPath,
  onChange,
  propErrors,
  schema,
  value,
}: IFormGroupProps<T>) {
  const labelBody =
    fieldConfig?.label ||
    `${t(dataPath.split(".").pop() as string)}${isRequired ? " *" : ""}`;

  const formGroupBody = React.useMemo(() => {
    const propName = dataPath.split(".").pop() as string;
    const disabled = fieldConfig?.isDisabled;

    if (fieldConfig && fieldConfig.Control) {
      return (
        <fieldConfig.Control
          schema={schema}
          isDisabled={disabled}
          dataPath={dataPath}
          errors={propErrors}
          value={value}
          onChange={onChange}
        />
      );
    }

    switch (schema.type) {
      case "array":
        const options = (schema.items as SchemaObject).enum;
        if (options) {
          return (
            <MultiSelect<string>
              placeholder="Kies opties..."
              itemRenderer={(option, { modifiers, handleClick }) => {
                if (!modifiers.matchesPredicate) {
                  return null;
                }
                const isSelected =
                  value && (value as string[]).indexOf(option) >= 0;
                return (
                  <MenuItem
                    aria-selected={isSelected}
                    selected={modifiers.active}
                    icon={isSelected ? "tick" : "blank"}
                    key={option}
                    label={t(option)}
                    text={t(option)}
                    onClick={handleClick}
                    shouldDismissPopover={false}
                  />
                );
              }}
              tagRenderer={(option) => t(option)}
              tagInputProps={{
                onRemove: (_tag: React.ReactNode, index: number) => {
                  onChange(
                    value.filter(
                      (obj: string, valueIndex: number) => valueIndex !== index
                    )
                  );
                },
              }}
              onItemSelect={(option) => {
                const isSelected = value && value.indexOf(option) >= 0;
                if (isSelected) {
                  onChange(value.filter((obj: string) => obj !== option));
                  return;
                }
                onChange([...(value || []), option]);
              }}
              items={options}
              selectedItems={value || []}
            />
          );
        }
        console.log(schema);
        throw new Error("Not yet implemented!");

      case "boolean":
        return (
          <Checkbox
            checked={value}
            type="checkbox"
            label={`${t(propName)}`}
            onChange={() => {
              onChange(!value);
            }}
          />
        );

      case "string":
        // isRequired =
        //   isRequired && !!(schema.enum || schema.minLength || schema.format);
        return (
          <>
            <StringFormControl
              schema={schema}
              isDisabled={disabled}
              dataPath={dataPath}
              errors={propErrors}
              value={value}
              onChange={onChange}
              fieldConfig={fieldConfig}
            />
            {fieldConfig?.addition ? fieldConfig?.addition(value) : undefined}
          </>
        );

      case "integer":
        if (schema.maximum) {
          return (
            <Slider
              min={schema.minimum}
              max={schema.maximum}
              stepSize={1}
              labelStepSize={20}
              onChange={onChange}
              labelRenderer={(value) => `${value}%`}
              value={value}
            />
          );
        }
        return (
          <InputGroup
            type="number"
            required={isRequired}
            value={value || ""}
            onChange={() => onChange(parseInt(value, 10))}
          />
        );

      case "object":
        return (
          <SchemaFormBody
            schema={schema}
            value={value}
            disabled={disabled || fieldConfig?.isDisabled}
            onChange={(newValue: any) => {
              onChange({
                ...value,
                [propName]: newValue,
              });
            }}
          />
        );

      default:
        console.log(schema);
        throw new Error(`Unsupported prop type: ${schema.type}`);
    }
  }, [dataPath, fieldConfig, isRequired, onChange, propErrors, schema, value]);

  return (
    <FormGroup
      helperText={
        propErrors.length ? t(propErrors[0].message as string) : undefined
      }
      label={labelBody}
      intent={propErrors.length ? "danger" : "success"}
      labelFor={getDomIdFromDataPath(dataPath)}
      labelInfo={isRequired ? "(verplicht)" : null}
    >
      {formGroupBody}
    </FormGroup>
  );
}
