const translations: { [key: string]: string } = {
  excludedMediaTypePrint: "Print",
  excludedMediaTypeWeb: "Web",
  admin: "Administrator",
  headliner: "Headliner",
  powerUser: "Power user",
  print: "print",
  printReader: "Print lezer",
  printChecker: "Print checker",
  printOther: "print",
  webReader: "Web lezer",
  webChecker: "Web checker",
  "should NOT be shorter than 2 characters":
    "Dit veld dient minimaal 2 karakters lang te zijn",
  "should NOT be shorter than 5 characters":
    "Dit veld dient minimaal 5 karakters lang te zijn",
  "should NOT have fewer than 1 items":
    "Er dient tenminste 1 veld aangevinkt te zijn",
  KEY_100: "Num 4",
  KEY_101: "Num 5",
  KEY_102: "Num 6",
  KEY_103: "Num 7",
  KEY_104: "Num 8",
  KEY_105: "Num 9",
  KEY_106: "*",
  KEY_107: "+",
  KEY_109: "Num -",
  KEY_110: "Num .",
  KEY_111: "Num /",
  KEY_112: "F1",
  KEY_113: "F2",
  KEY_114: "F3",
  KEY_115: "F4",
  KEY_116: "F5",
  KEY_117: "F6",
  KEY_118: "F7",
  KEY_119: "F8",
  KEY_120: "F9",
  KEY_121: "F10",
  KEY_122: "F11",
  KEY_123: "F12",
  KEY_13: "Enter",
  KEY_144: "NumLock",
  KEY_145: "ScrollLock",
  KEY_16: "Shift",
  KEY_17: "Ctrl",
  KEY_18: "Alt",
  KEY_186: ";",
  KEY_187: "=",
  KEY_188: ",",
  KEY_189: "-",
  KEY_19: "Break",
  KEY_190: ". (keyboard)",
  KEY_191: "/ (keyboard)",
  KEY_192: "~",
  KEY_20: "CapsLock",
  KEY_219: "{",
  KEY_220: "\\",
  KEY_221: "}",
  KEY_222: "'",
  KEY_27: "Escape",
  KEY_32: "Space",
  KEY_33: "PgUp",
  KEY_34: "PgDn",
  KEY_35: "End",
  KEY_36: "Home",
  KEY_37: "←",
  KEY_38: "↑",
  KEY_39: "→",
  KEY_40: "↓",
  KEY_45: "Insert",
  KEY_46: "Delete",
  KEY_48: "0",
  KEY_49: "1",
  KEY_50: "2",
  KEY_51: "3",
  KEY_52: "4",
  KEY_53: "5",
  KEY_54: "6",
  KEY_55: "7",
  KEY_56: "8",
  KEY_57: "9",
  KEY_65: "A",
  KEY_66: "B",
  KEY_67: "C",
  KEY_68: "D",
  KEY_69: "E",
  KEY_70: "F",
  KEY_71: "G",
  KEY_72: "H",
  KEY_73: "I",
  KEY_74: "J",
  KEY_75: "K",
  KEY_76: "L",
  KEY_77: "M",
  KEY_78: "N",
  KEY_79: "O",
  KEY_8: "Backspace",
  KEY_80: "P",
  KEY_81: "Q",
  KEY_82: "R",
  KEY_83: "S",
  KEY_84: "T",
  KEY_85: "U",
  KEY_86: "V",
  KEY_87: "W",
  KEY_88: "X",
  KEY_89: "Y",
  KEY_9: "Tab",
  KEY_90: "Z",
  KEY_91: "Left Super S",
  KEY_92: "Right Super S",
  KEY_93: "Select",
  KEY_96: "Num 0",
  KEY_97: "Num 1",
  KEY_98: "Num 2",
  KEY_99: "Num 3",
  checkPreferenceTypePrint: "Print",
  checkPreferenceTypeWeb: "Web",
  "should be string": "Zou gevuld moeten zijn",
  "should be integer": "Zou gevuld moeten zijn",
  ready: "Leesbaar",
  processing: "Bezig met lezen",
  hold: "Geparkeerd",
  finish: "Afgerond",
  "User/Password combination not found":
    "De combinatie gebruikersnaam/wachtwoord is onjuist",
  "newpassword,Same as currentpassword":
    "Het oude wachtwoord kan niet opnieuw worden gebruikt",
  "Value for PublicationId is incorect": "Publicatienummer is niet juist",
  web: "web",
  "Url,Not found": "URL niet gevonden",
  deleteRequestStatus: "Status",
  waitForApproval: "Wacht op goedkeuring",
  creditApproved: "Toegewezen",
  creditRejected: "Afgewezen",
  reason: "Reden creditaanvraag",
  deleteRequestor: "Aanvraag door",
  customerName: "Klant",
  accountManager: "Accountmanager",
  backOfficer: "Backoffice",
  deleteRequestCreateDateTime: "Aangemaakt op",
  deleteRequestStatusChangedBy: "Behandeld door",
  deleteRequestStatusChangedTime: "Behandeld op",
  checkPreferenceTypePublicationTypePrint: "Print-categorie",
  checkPreferenceTypePublicationTypeWeb: "Web-categorie",
  publicationTypeId: "Publicatie type",
  publicationId: "Publicatie",
  domainTypeId: "Domein type",
  domainId: "Domein",
  checkPercentage: "Percentage",
  readAllArticles: "Alle print-artikelen zonder hit controleren",
  displayName: "Naam",
  userName: "Gebruikersnaam",
  mustBeChecked: "Controle vereist",
  excludedPublicationTypeIds: "Uitgesloten publicatietypes",
  excludedDomainTypeIds: "Uitgesloten websites",
  deleteRequestUser: "Verwerker credits",
  monitoringPlusUser: "Monitoring Plus",
  backSearchReader: "Backsearch lezer",
  backSearchChecker: "Backsearch checker",
  roles: "Rollen",
  "Missing Checkpreference-contents": "Kies ten minste 1 voorkeur",
  searchTopicId: "Zoekopdracht",
  "Conflict with other CheckPreference":
    "Waarde niet mogelijk: er bestaat hier reeds een leesvoorkeur voor",
  processError: "Probleem",
  approved: "Goedgekeurd",
  none: "Geen beoordeling",
  skipped: "Overgeslagen",
  rejected: "Afgewezen",
  reclipRequest: "Herknip verzoek",
  isError: "Fout",
  isNoAction: "Geen actie",
  approving: "Goedkeuren (niet definitief)",
  rejecting: "Afkeuren (niet definitief)",
};

export function t(key: string) {
  if (key.startsWith("should match pattern")) {
    return "Dit is te eenvoudig. Een wachtwoord is tenminste 8 karakters en dient kleine letters, hoofdletters en cijfers of bijzondere tekens te bevatten";
  }
  return translations[key] || key;
}
