import * as React from "react";
import SchemaTable, { IColumnConfig } from "../../components/SchemaTable";
import useDeleteRequests from "../../hooks/useDeleteRequests";
import openapi from "../../openapi.json";
import { SchemaObject } from "openapi3-ts";
import { t } from "../../inc/i18n";
import MatchItemHitIdCell from "./MatchItemHitIdCell";
import { components } from "../../types/openapi";
import { Button } from "@blueprintjs/core";
import { Intent } from "@blueprintjs/core/lib/esm/common/intent";
import { useNavigate } from "react-router-dom";
import CustomerNameCell from "./CustomerNameCell";
import CenteredSpinner from "../../components/CenteredSpinner";
import { LayoutContext } from "../../provider/layout";

const deleteRequestSchema = openapi.components.schemas
  .DeleteRequest as SchemaObject;

const DeleteRequests = () => {
  const { deleteRequestTableScrollTop, setDeleteRequestTableScrollTop } =
    React.useContext(LayoutContext);

  const deleteRequests = useDeleteRequests();
  const navigate = useNavigate();
  const data = React.useMemo(() => {
    return deleteRequests ? Object.values(deleteRequests) : null;
  }, [deleteRequests]);
  const config = React.useMemo<{
    [propName: string]: IColumnConfig<components["schemas"]["DeleteRequest"]>;
  }>(
    () => ({
      matchItemHitId: {
        title: "Artikel",
        renderCell: MatchItemHitIdCell,
      },
      rejectReason: {
        hidden: true,
      },
      deleteRequestor: {
        width: 150,
        renderCell: (deleteRequest: components["schemas"]["DeleteRequest"]) => {
          return (
            <a href={`mailto:${deleteRequest.deleteRequestorEmail}`}>
              {deleteRequest.deleteRequestor}
            </a>
          );
        },
      },
      deleteRequestorEmail: {
        hidden: true,
      },
      accountManager: {
        width: 150,
        hidden: true,
      },
      backOfficer: {
        width: 150,
        hidden: true,
      },
      deleteRequestStatusChangedBy: {
        width: 150,
      },
      customerName: { width: 150, order: -100, renderCell: CustomerNameCell },
      deleteRequestCreateDateTime: { width: 150 },
      deleteRequestStatusChangedTime: { width: 150 },
      deleteRequestStatus: {
        width: 180,
        order: 100,
        renderCell: (deleteRequest: components["schemas"]["DeleteRequest"]) => {
          const colors = {
            waitForApproval: "orange",
            creditApproved: "green",
            creditRejected: "red",
          };
          return (
            <div
              style={{ color: colors[deleteRequest.deleteRequestStatus] }}
              title={deleteRequest.rejectReason}
            >
              {t(deleteRequest.deleteRequestStatus)}
            </div>
          );
        },
      },
      customerId: { hidden: true },
      deleteRequestId: {
        order: 999,
        width: 150,
        title: "-",
        renderCell: (deleteRequest) => (
          <Button
            onClick={() => {
              navigate(`/deleteRequest/${deleteRequest.deleteRequestId}`);
            }}
            intent={Intent.SUCCESS}
            text="Bekijken"
            small={true}
          />
        ),
      },
    }),
    [navigate]
  );

  const onScroll = React.useCallback(
    ({ scrollTop }: { scrollTop: number }) => {
      setDeleteRequestTableScrollTop(scrollTop);
    },
    [setDeleteRequestTableScrollTop]
  );

  return data ? (
    <div style={{ marginTop: 16 }}>
      <SchemaTable<components["schemas"]["DeleteRequest"]>
        schema={deleteRequestSchema}
        config={config}
        data={data}
        defaultSortColumn="deleteRequestStatus"
        defaultSortAsc={true}
        onScroll={onScroll}
        scrollTop={deleteRequestTableScrollTop}
        isFullScreen
        isSearchable
        isSortable
      />
    </div>
  ) : (
    <div style={{ marginTop: 40 }}>
      <CenteredSpinner />
    </div>
  );
};

export default DeleteRequests;
