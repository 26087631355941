import { components } from "../types/openapi";

export function addUserActionIfNeeded(
  actions: components["schemas"]["MatchItemHitAction"][],
  userName: string,
  requestedMatchItemHitId: string
) {
  // make sure there is an action for the current user so we can write our comment
  if (userName && !actions.find((action) => action.userName === userName)) {
    actions.push({
      userName,
      version: -1,
      startDateTime: new Date().toISOString(),
      matchItemHitActionResult: "none",
      comment: "",
      requestedMatchItemHitId,
    });
  }
  return actions;
}

export function getStoredMatchItemHitActionResult(
  matchItemHit: components["schemas"]["MatchItemHit"],
  actions: components["schemas"]["MatchItemHitAction"][]
): components["schemas"]["MatchItemHitActionResult"] {
  if (
    matchItemHit.matchItemHitStatus === "monitoringPlus" &&
    matchItemHit.monitoringPlusResult
  ) {
    return matchItemHit.monitoringPlusResult;
  }
  const lastStoredAction = actions
    ? actions.filter((action) => action.version !== -1).pop()
    : undefined;
  return lastStoredAction?.matchItemHitActionResult || "none";
}
