import * as React from "react";
import { Button, Intent, TextArea } from "@blueprintjs/core";
import { useParams } from "react-router-dom";

import useDeleteRequests from "../../../hooks/useDeleteRequests";
import CenteredSpinner from "../../CenteredSpinner";
import { IReadHitWindowProps } from "../index";
import { ApiDataContext } from "../../../provider/apiData";

import "./index.scss";
import { t } from "../../../inc/i18n";
import { localeFormat } from "../../../inc/date";

const DeleteRequest = ({ processDeleteRequest }: IReadHitWindowProps) => {
  const { currentClaimedMatchItemHit, searchTopicMap } =
    React.useContext(ApiDataContext);
  const deleteRequests = useDeleteRequests();
  const params = useParams<{ deleteRequestId: string }>();
  const deleteRequest =
    deleteRequests && params.deleteRequestId
      ? deleteRequests[params.deleteRequestId]
      : undefined;
  const [rejectReason, setRejectReason] = React.useState(
    deleteRequest?.rejectReason
  );
  const searchTopic =
    searchTopicMap && currentClaimedMatchItemHit?.hit.searchTopicId
      ? searchTopicMap[currentClaimedMatchItemHit.hit.searchTopicId]
      : undefined;

  const actions = React.useMemo(
    () =>
      currentClaimedMatchItemHit?.actions
        .filter((action) => action.version !== -1)
        .sort((a, b) => a.version - b.version) || [],
    [currentClaimedMatchItemHit?.actions]
  );

  return deleteRequest && processDeleteRequest ? (
    <div
      style={{
        margin: 16,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "hidden",
        alignItems: "stretch",
      }}
    >
      <dl>
        {deleteRequest.deleteRequestCreateDateTime ? (
          <>
            <dt>Aangemaakt op</dt>
            <dd>
              {localeFormat(
                new Date(deleteRequest.deleteRequestCreateDateTime),
                "dd-MM-yyyy HH:mm"
              )}
            </dd>
          </>
        ) : null}
        <dt>Aangemaakt door</dt>
        <dd>
          <a href={`mailto:${deleteRequest.deleteRequestorEmail}`}>
            {deleteRequest.deleteRequestor}
          </a>
        </dd>
        <dt>Klantnaam</dt>
        <dd>{searchTopic?.customerName}</dd>
        {searchTopic ? (
          <>
            <dt>Accountmanager</dt>
            <dd>
              <span>{searchTopic?.accountManager}</span>
            </dd>
            <dt>Backofficer</dt>
            <dd>
              <span>{searchTopic?.backOfficer}</span>
            </dd>
          </>
        ) : null}
        <dt>Reden aanvraag</dt>
        <dd>{deleteRequest.reason}</dd>
        {actions.length ? (
          <>
            <dt>Beoordelingen</dt>
            <dd>
              <ul>
                {actions.map((action) => (
                  <li key={action.version}>
                    {action.userName}: {t(action.matchItemHitActionResult)}
                  </li>
                ))}
              </ul>
            </dd>
          </>
        ) : null}
      </dl>
      <Button
        disabled={deleteRequest.deleteRequestStatus !== "waitForApproval"}
        intent={Intent.SUCCESS}
        onClick={() => processDeleteRequest("creditApproved")}
      >
        Goedkeuren
      </Button>
      <hr style={{ marginTop: 16, marginBottom: 16, width: "100%" }} />
      <TextArea
        disabled={deleteRequest.deleteRequestStatus !== "waitForApproval"}
        placeholder="Reden afkeuring"
        name="rejectReason"
        value={rejectReason}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          setRejectReason(e.target.value);
        }}
      ></TextArea>
      <Button
        disabled={deleteRequest.deleteRequestStatus !== "waitForApproval"}
        intent={Intent.DANGER}
        style={{ marginTop: 16 }}
        onClick={() => {
          if (!rejectReason) {
            window.alert("Een reden is verplicht");
            return;
          }
          processDeleteRequest("creditRejected", rejectReason);
        }}
      >
        Afkeuren
      </Button>
    </div>
  ) : (
    <CenteredSpinner />
  );
};

export default DeleteRequest;
