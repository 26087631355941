import * as React from "react";

interface IWebSocketContext {
  webSocket: WebSocket;
}

const webSocket = new WebSocket(
  process.env.REACT_APP_SOCKET_SERVICE_URL!
);
export const WebSocketContext = React.createContext<IWebSocketContext>({
  webSocket,
});

export const WebSocketProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <WebSocketContext.Provider
      value={{
        webSocket,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};
