import { components } from "../types/openapi";

/**
 *        oMatchItem.Name:=Format('%.5d',[oSQL.FieldByName('PublicationId').AsInteger])
 *               +' - '+FormatDateTime('d mmm yyyy',oSQL.FieldByName('PublicationDate').AsDateTime)
 *               +' - '+ Format('%.5d',[oSQL.FieldByName('PageSequence').AsInteger])
 *               +' - '+ Format('%.5d',[oSQL.FieldByName('ClippingSequence').AsInteger])

 * @param matchItem
 */
export function getName(matchItem: components["schemas"]["MatchItem"]): string {
  return [
    matchItem.publicationId,
    matchItem.publicationName,
    matchItem.publicationDate,
    matchItem.pageSequence,
    matchItem.clippingSequence,
  ]
    .filter((piece) => !!piece)
    .join("-");
}
