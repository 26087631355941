import { MultiSelect } from "@blueprintjs/select";
import { components } from "../../../types/openapi";
import useDomainTypeMap from "../../../hooks/useDomainTypeMap";
import { MenuItem, Spinner } from "@blueprintjs/core";
import { ISchemaFormControlProps } from "../../../components/schemaForm";

const ExcludedDomainTypeIdsControl = ({
  onChange,
  value = [],
}: ISchemaFormControlProps<string[]>) => {
  const domainTypeMap = useDomainTypeMap();
  if (!domainTypeMap) {
    return <Spinner />;
  }
  return (
    <MultiSelect<components["schemas"]["DomainType"]>
      items={Object.values(domainTypeMap)}
      itemRenderer={({ domainTypeId, domainType }, { handleClick }) => {
        const isSelected = value?.indexOf(domainTypeId) >= 0;
        return (
          <MenuItem
            icon={isSelected ? "tick" : "blank"}
            key={domainTypeId}
            label={domainType}
            text={domainType}
            onClick={handleClick}
          />
        );
      }}
      onItemSelect={({ domainTypeId }) => {
        const isSelected = value?.indexOf(domainTypeId) >= 0;
        onChange(
          isSelected
            ? value?.filter((existingId) => existingId !== domainTypeId)
            : [...value, domainTypeId]
        );
      }}
      tagRenderer={(domainType: components["schemas"]["DomainType"]) =>
        domainType.domainType
      }
      tagInputProps={{
        onRemove: (domainTypeTitle) => {
          const domainType = Object.values(domainTypeMap).find(
            (domainType) => domainType.domainType === domainTypeTitle
          );
          if (!domainType) {
            return;
          }
          onChange(
            value.filter(
              (excludedDomainTypeId) =>
                domainType.domainTypeId !== excludedDomainTypeId
            )
          );
        },
      }}
      fill={true}
      placeholder=""
      selectedItems={value.map((typeId) => domainTypeMap[typeId])}
    ></MultiSelect>
  );
};

export default ExcludedDomainTypeIdsControl;
