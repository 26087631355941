import React from "react";
import { differenceInMinutes } from "date-fns";
import { KunciContext } from "../inc/kunci";
import { ApiDataContext } from "../provider/apiData";
import { useLocation } from "react-router-dom";

const useIsCurrentClaimReadOnly = (): boolean => {
  const { auth } = React.useContext(KunciContext);
  const location = useLocation();

  const isManage = location.pathname.indexOf("/manage/") >= 0;
  const { currentClaimedMatchItemHit } = React.useContext(ApiDataContext);

  return React.useMemo((): boolean => {
    if (currentClaimedMatchItemHit?.hit.matchItemHitStatus === "hold") {
      return false;
    }
    if (isManage) {
      return true;
    }
    const myLastAction = (
      currentClaimedMatchItemHit?.actions
        ? [...currentClaimedMatchItemHit.actions]
        : []
    )
      .sort((a, b) =>
        new Date(b.startDateTime) > new Date(a.startDateTime) ? 1 : -1
      )
      .find((action) => action.userName === auth?.jwt.userName);

    return !!(
      myLastAction &&
      differenceInMinutes(
        new Date(myLastAction.endDateTime || myLastAction.startDateTime),
        new Date()
      ) > 5
    );
  }, [
    auth?.jwt.userName,
    currentClaimedMatchItemHit?.actions,
    currentClaimedMatchItemHit?.hit.matchItemHitStatus,
    isManage,
  ]);
};

export default useIsCurrentClaimReadOnly;
