import * as React from "react";
import { Icon, Spinner } from "@blueprintjs/core";
import useMatchItemMap from "../../../../hooks/useMatchItemMap";
import { ApiDataContext } from "../../../../provider/apiData";
import { format } from "date-fns";
import { components } from "../../../../types/openapi";

import "./index.scss";
import { LayoutContext } from "../../../../provider/layout";

interface IMatchItemHitRowProps {
  disabled: boolean;
  index: number;
  matchItemHits: components["schemas"]["MatchItemHit"][];
  style: React.CSSProperties;
}

const MatchItemHitRow = ({
  disabled,
  index,
  matchItemHits,
  style,
}: IMatchItemHitRowProps) => {
  const { currentClaimedMatchItemHit } = React.useContext(ApiDataContext);
  const { openMonitoringPlusHit } = React.useContext(LayoutContext);
  const matchItemHit = matchItemHits[index];
  const { matchItemMap } = useMatchItemMap(matchItemHit.matchItemId);

  const onMatchItemClick = React.useCallback(() => {
    if (!matchItemHit || disabled) {
      return;
    }
    openMonitoringPlusHit(matchItemHit.matchItemHitId);
  }, [disabled, matchItemHit, openMonitoringPlusHit]);

  const classNames = [
    "monitoring_plus_mosaic_window__row",
    `monitoring_plus_mosaic_window__row--${matchItemHit.monitoringPlusResult}`,
  ];
  if (disabled) {
    classNames.push("monitoring_plus_mosaic_window__row--disabled");
  }
  if (
    currentClaimedMatchItemHit &&
    matchItemHit.matchItemHitId ===
      currentClaimedMatchItemHit.hit.matchItemHitId
  ) {
    classNames.push("monitoring_plus_mosaic_window__row--selected");
  }

  const matchItem = matchItemMap[matchItemHit.matchItemId];
  return (
    <div
      key={matchItemHit.matchItemHitId}
      className={classNames.join(" ")}
      onClick={onMatchItemClick}
      data-match-item-hit-id={matchItemHit.matchItemHitId}
      style={{
        ...style,
      }}
    >
      {matchItem ? (
        <div style={{ height: 36, overflow: "hidden" }}>
          <Icon
            icon={matchItem.mediaType === "web" ? "globe-network" : "manual"}
            size={14}
          />{" "}
          <strong>{matchItem.publicationName}</strong>
          {matchItem.publicationDate
            ? ` - ${format(new Date(matchItem.publicationDate), "dd-MM-yyyy")}`
            : null}
          {matchItem.pageSequence ? " - " : null}
          {matchItem.pageSequence}
          {" - "}
          {matchItem.clipTitle}
        </div>
      ) : (
        <Spinner size={14} />
      )}
    </div>
  );
};

export default MatchItemHitRow;
