import { components } from "../../../types/openapi";
import { Spinner } from "@blueprintjs/core";
import * as React from "react";
import useDomain from "../../../hooks/useDomain";

const DomainCell = (
  checkPreference?: components["schemas"]["CheckPreference"]
) => {
  const domain = useDomain(checkPreference?.domainId);
  if (!domain) {
    return null;
  }
  return domain ? (
    <>{domain.name}</>
  ) : (
    <div style={{ paddingTop: 10 }}>
      <Spinner size={16} />
    </div>
  );
};

export default DomainCell;
