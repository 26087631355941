import { components } from "../../../../types/openapi";
import * as React from "react";
import { ApiDataContext } from "../../../../provider/apiData";
import useSearchTopicClaims from "../../../../hooks/useSearchTopicClaims";
import { Button } from "@blueprintjs/core";
import { Intent } from "@blueprintjs/core/lib/esm/common/intent";
import { KunciContext } from "../../../../inc/kunci";

import "./index.scss";

interface SearchTopicLiProps {
  searchTopic: components["schemas"]["SearchTopic"];
}

const SearchTopicLi = ({ searchTopic }: SearchTopicLiProps) => {
  const {
    currentClaimedMatchItemHit,
    currentSearchTopicClaim,
    previousSearchTopicClaim,
    searchTopicResults,
    setNextMatchItemId,
    setNextSearchTopicClaim,
    submitClaimedHits,
  } = React.useContext(ApiDataContext);
  const searchTopicClaims = useSearchTopicClaims();
  const { auth } = React.useContext(KunciContext);

  const availableSearchTopicClaim = React.useMemo<
    components["schemas"]["SearchTopicClaim"] | undefined
  >(() => {
    if (
      !currentSearchTopicClaim ||
      !searchTopicClaims ||
      !searchTopicClaims.length
    ) {
      return undefined;
    }

    return searchTopicClaims.find(
      (claim) =>
        claim.searchTopicId === searchTopic.searchTopicId &&
        !claim[
          `${currentClaimedMatchItemHit?.hit.mediaType}ClaimedByUserName` as "webClaimedByUserName"
        ]
    );
  }, [
    currentClaimedMatchItemHit?.hit.mediaType,
    currentSearchTopicClaim,
    searchTopic.searchTopicId,
    searchTopicClaims,
  ]);

  let intent: Intent;
  const searchTopicResult = searchTopicResults
    ? searchTopicResults[searchTopic.searchTopicId]
    : undefined;
  switch (searchTopicResult?.matchItemHitActionResult) {
    case "approving":
    case "approved":
      intent = "success";
      break;

    case "skipped":
      intent = "warning";
      break;

    case "rejected":
    case "reclipRequest":
    case "rejecting":
    case "isError":
      intent = "danger";
      break;

    case "isNoAction":
    case "none":
    default:
      intent = "none";
  }

  const claimProp =
    `${currentClaimedMatchItemHit?.hit.mediaType}ClaimedByUserName` as "webClaimedByUserName";

  if (!auth) {
    return null;
  }

  return (
    <li
      className={`search-topic-li--${searchTopicResult?.matchItemHitActionResult}`}
    >
      {currentSearchTopicClaim ? (
        <Button
          intent={intent}
          disabled={!availableSearchTopicClaim}
          onClick={async () => {
            if (!availableSearchTopicClaim) {
              return;
            }
            await submitClaimedHits();
            await setNextSearchTopicClaim(
              {
                ...availableSearchTopicClaim,
                [claimProp]: auth.jwt.displayName,
              } as components["schemas"]["SearchTopicClaim"],
              !(
                previousSearchTopicClaim &&
                previousSearchTopicClaim.searchTopicId ===
                  availableSearchTopicClaim.searchTopicId
              )
            );
            setNextMatchItemId(
              currentClaimedMatchItemHit?.hit.matchItemId as string
            );
          }}
        >
          {searchTopic.description}
        </Button>
      ) : (
        searchTopic.description
      )}
    </li>
  );
};

export default SearchTopicLi;
