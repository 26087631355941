import * as React from "react";
import { KunciContext } from "../../../../inc/kunci";
import { ApiDataContext } from "../../../../provider/apiData";
import useMatchItemMap from "../../../../hooks/useMatchItemMap";
import Lightbox from "react-18-image-lightbox";

interface IClippingPanelProps {
  imageWidth: number;
}

const ClippingPanel = ({ imageWidth }: IClippingPanelProps) => {
  const { currentClaimedMatchItemHit } = React.useContext(ApiDataContext);
  const { axios } = React.useContext(KunciContext);
  const { matchItemMap } = useMatchItemMap(
    currentClaimedMatchItemHit?.hit.matchItemId
  );
  const matchItem =
    matchItemMap && currentClaimedMatchItemHit
      ? matchItemMap[currentClaimedMatchItemHit.hit.matchItemId]
      : undefined;

  const [selectedClippingUrl, setSelectedClippingUrl] =
    React.useState<string>();
  const { baseURL } = axios.defaults;

  if (!baseURL || !matchItem) {
    return null;
  }
  const { clippingUrls = [] } = matchItem;

  return (
    <React.Fragment>
      {clippingUrls.map((selectedClippingUrl, selectedClippingUrlIndex) => (
        <img
          key={selectedClippingUrl}
          src={selectedClippingUrl}
          alt={`Knipsel pagina ${selectedClippingUrlIndex + 1}`}
          style={{
            width: imageWidth,
            marginTop: 8,
          }}
          onClick={() => {
            setSelectedClippingUrl(selectedClippingUrl);
          }}
        />
      ))}
      {selectedClippingUrl ? (
        <Lightbox
          mainSrc={selectedClippingUrl}
          onCloseRequest={() => setSelectedClippingUrl(undefined)}
        />
      ) : null}
    </React.Fragment>
  );
};

export default ClippingPanel;
