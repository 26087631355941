import * as React from "react";
import { MosaicWindow } from "react-mosaic-component";
import { differenceInMinutes } from "date-fns";
import { Intent } from "@blueprintjs/core/lib/esnext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { KunciContext } from "../../inc/kunci";
import { AxiosError } from "axios";

import { ApiDataContext } from "../../provider/apiData";
import CenteredSpinner from "../../components/CenteredSpinner";
import { KeyboardActions } from "../../inc/keyboard";
import { LayoutContext } from "../../provider/layout";
import { getStoredMatchItemHitActionResult } from "../../inc/matchItemHit";
import toaster from "../../inc/toaster";
import ReclipView from "./ReclipView";
import { components } from "../../types/openapi";
import { matchItemHitWindowComponents } from "../../components/mosaicWindows";
import { getName } from "../../inc/matchItem";
import SpeedReaderMosaic from "../../components/SpeedReaderMosaic";
import useMatchItemMap from "../../hooks/useMatchItemMap";
import useIsCurrentClaimReadOnly from "../../hooks/useIsCurrentClaimReadOnly";

import "./index.scss";

const ReadMatchItemHit = () => {
  const location = useLocation();
  // @ts-ignore
  const { returnUrl } = location.state || {};
  const {
    claimHitById,
    claimNextHit,
    counters,
    currentClaimedMatchItemHit,
    setCurrentClaimedMatchItemHit,
    updateCounters,
    updateMatchItemTitle,
  } = React.useContext(ApiDataContext);
  const { hydrateMatchItem, matchItemMap } = useMatchItemMap();
  const {
    isLast,
    isSearchTopicDialogOpen,
    readingMinutes,
    setIsLast,
    setIsCommentOpen,
    setIsSearchTopicDialogOpen,
    setReadingMinutes,
  } = React.useContext(LayoutContext);
  const [isInProgress, setIsInProgress] = React.useState(false);
  const navigate = useNavigate();
  const { auth, axios } = React.useContext(KunciContext);
  const isReadOnly = useIsCurrentClaimReadOnly();
  const isAdministrator =
    (auth?.jwt as components["schemas"]["Jwt"]).userRoles.indexOf("admin") >= 0;
  const matchItem =
    matchItemMap && currentClaimedMatchItemHit
      ? matchItemMap[currentClaimedMatchItemHit.hit.matchItemId]
      : undefined;

  const myLastAction = React.useMemo(
    () =>
      (currentClaimedMatchItemHit?.actions
        ? [...currentClaimedMatchItemHit.actions]
        : []
      )
        .sort((a, b) =>
          new Date(b.startDateTime) > new Date(a.startDateTime) ? 1 : -1
        )
        .find((action) => action.userName === auth?.jwt.userName),
    [auth?.jwt.userName, currentClaimedMatchItemHit?.actions]
  );
  const processHit = React.useCallback(
    (
      result: "approved" | "skipped" | "rejected" | "isError" | "isNoAction",
      comment?: string
    ) => {
      if (
        !currentClaimedMatchItemHit ||
        (isReadOnly && !isAdministrator) ||
        (!currentClaimedMatchItemHit.hit.searchTopicId &&
          ["isNoAction", "error"].indexOf(result) === -1)
      ) {
        return;
      }

      setIsInProgress(true);
      setIsCommentOpen(false);
      setIsSearchTopicDialogOpen(false);

      const params: any = {
        result,
        comment:
          myLastAction && myLastAction.comment ? myLastAction.comment : comment,
      };
      if (isReadOnly) {
        params.overrule = "true";
      }
      return axios
        .request<components["schemas"]["ProcessResult"]>({
          method: "post",
          url: `/matchItemHit/${currentClaimedMatchItemHit.hit.matchItemHitId}/process`,
          params,
        })
        .then((res) => {
          if (!res.data.success) {
            throw new Error("Process matchItemHit failed");
          }
          if (matchItem) {
            const targetCounter =
              getStoredMatchItemHitActionResult(
                currentClaimedMatchItemHit.hit,
                currentClaimedMatchItemHit.actions
              ) === "none"
                ? "Read"
                : ("Checked" as keyof components["schemas"]["CountersResult"]);
            updateCounters({
              ...counters,
              // @ts-ignore
              [targetCounter]: counters[targetCounter] - 1,
            });
          }
          if (returnUrl) {
            navigate(returnUrl);
            return;
          }
          if (isLast) {
            setIsLast(false);
            navigate(`/`);
            return;
          }
          return claimNextHit().then(() => {
            setIsInProgress(false);
          });
          // claim next;
        })
        .catch((err: AxiosError) => {
          setIsInProgress(false);
          if (err.response?.status === 404) {
            toaster.show({
              message: `😊 Er zijn geen artikelen meer 😊`,
              intent: Intent.SUCCESS,
            });
            navigate("/");
            return;
          }

          toaster.show({ message: err.message, intent: Intent.DANGER });
        });
    },
    [
      axios,
      claimNextHit,
      counters,
      currentClaimedMatchItemHit,
      isAdministrator,
      isLast,
      isReadOnly,
      matchItem,
      myLastAction,
      navigate,
      returnUrl,
      setIsCommentOpen,
      setIsLast,
      setIsSearchTopicDialogOpen,
      updateCounters,
    ]
  );
  const [isForcedTitleCheck, setIsForcedTitleCheck] = React.useState(false);

  React.useEffect(() => {
    setIsForcedTitleCheck(!isReadOnly && matchItem?.mediaType === "print");
  }, [isReadOnly, matchItem?.mediaType, matchItem?.matchItemId]);

  const doKeyboardAction = React.useCallback(
    (actionId: keyof typeof KeyboardActions) => {
      switch (actionId) {
        case KeyboardActions.HIT_REJECT:
          processHit("rejected");
          break;
        case KeyboardActions.HIT_START_COMMENT:
          setIsCommentOpen(true);
          break;
        case KeyboardActions.HIT_ADD_SEARCHTOPIC:
          if (!isSearchTopicDialogOpen) {
            setIsSearchTopicDialogOpen(true);
          }
          break;
        case KeyboardActions.HIT_SKIP:
          if (myLastAction?.comment) {
            processHit("skipped");
          }
          break;
        case KeyboardActions.HIT_APPROVE:
          processHit("approved");
          break;
        case KeyboardActions.HIT_MARK_LAST:
          setIsLast(!isLast);
      }
    },
    [
      isLast,
      isSearchTopicDialogOpen,
      myLastAction?.comment,
      processHit,
      setIsCommentOpen,
      setIsLast,
      setIsSearchTopicDialogOpen,
    ]
  );

  const { matchItemHitId } = useParams();

  // Ensure correct matchItemHit!
  React.useEffect(() => {
    const userName = auth?.jwt.userName;
    if (
      matchItemHitId &&
      userName &&
      (currentClaimedMatchItemHit === undefined ||
        (currentClaimedMatchItemHit &&
          currentClaimedMatchItemHit.hit.matchItemHitId !== matchItemHitId &&
          !isInProgress))
    ) {
      claimHitById(matchItemHitId);
      return;
    }
  }, [
    auth?.jwt.userName,
    claimHitById,
    currentClaimedMatchItemHit,
    isInProgress,
    matchItemHitId,
    navigate,
    setCurrentClaimedMatchItemHit,
  ]);

  // Ensure matchItem!
  React.useEffect(() => {
    if (!currentClaimedMatchItemHit) {
      return;
    }
    if (
      matchItem === undefined ||
      (matchItem &&
        currentClaimedMatchItemHit.hit.matchItemId !== matchItem.matchItemId)
    ) {
      hydrateMatchItem(currentClaimedMatchItemHit.hit.matchItemId).catch(
        (err: AxiosError) => {
          const { response } = err;
          if (!response) {
            console.log(err);
            return;
          }
          processHit(
            "skipped",
            `MatchItem API-response: ${JSON.stringify(response.data)}`
          );
        }
      );
    }
  }, [
    auth?.jwt.userName,
    currentClaimedMatchItemHit,
    hydrateMatchItem,
    matchItem,
    processHit,
    setCurrentClaimedMatchItemHit,
  ]);

  React.useEffect(() => {
    if (!currentClaimedMatchItemHit) {
      return;
    }
    const clockCheckInterval = window.setInterval(() => {
      const newReadingMinutes = differenceInMinutes(
        new Date(),
        currentClaimedMatchItemHit.date
      );
      if (!newReadingMinutes) {
        return;
      }
      if (
        newReadingMinutes !== readingMinutes &&
        currentClaimedMatchItemHit.hit &&
        (!myLastAction ||
          myLastAction.matchItemHitActionResult !== "reclipRequest")
      ) {
        setReadingMinutes(newReadingMinutes);
        toaster.show({
          message: `Het lezen van dit item duurt nu ${
            newReadingMinutes === 1
              ? "1 minuut"
              : `${newReadingMinutes} minuten`
          }`,
          intent: Intent.WARNING,
        });
      }
      if (newReadingMinutes > 4 && !isReadOnly) {
        navigate("/?timeout=1", { replace: true });
      }
    }, 1000);
    return () => {
      clearInterval(clockCheckInterval);
    };
  }, [
    currentClaimedMatchItemHit,
    isReadOnly,
    myLastAction,
    navigate,
    readingMinutes,
    setReadingMinutes,
  ]);

  if (!matchItem || isInProgress) {
    return <CenteredSpinner />;
  }

  const isReclip = myLastAction?.matchItemHitActionResult === "reclipRequest";
  return isReclip ? (
    <ReclipView />
  ) : (
    <SpeedReaderMosaic
      appDataNodeName={
        isForcedTitleCheck ? "forcedTitleCheckNode" : "readHitNode"
      }
      doKeyboardAction={doKeyboardAction}
    >
      {(matchItemHitWindowType, path) => {
        const HitWindowComponent =
          matchItemHitWindowComponents[matchItemHitWindowType];
        return (
          <MosaicWindow<number>
            title={getName(matchItem)}
            path={path}
            toolbarControls={[]}
            draggable={false}
            className={`mosaic-window--type${matchItemHitWindowType}`}
          >
            <HitWindowComponent
              processHit={processHit}
              onForcedTitleCheckSubmit={
                isForcedTitleCheck
                  ? (title: string) => {
                      if (
                        title &&
                        title !== matchItem?.clipTitle &&
                        window.confirm(
                          "Weet u zeker dat u de titel wilt wijzigen?"
                        )
                      ) {
                        updateMatchItemTitle(title);
                      }
                      if (
                        currentClaimedMatchItemHit?.hit.matchItemHitStatus ===
                        "titleOnly"
                      ) {
                        processHit("isNoAction");
                        return;
                      }

                      setIsForcedTitleCheck(false);
                    }
                  : undefined
              }
            />
          </MosaicWindow>
        );
      }}
    </SpeedReaderMosaic>
  );
};

export default ReadMatchItemHit;
