import React, { ChangeEventHandler } from "react";
import { InputGroup } from "@blueprintjs/core";
import { ISchemaFormControlProps } from "../../../index";
import { IFieldConfig } from "../../index";
import { t } from "../../../../../inc/i18n";

type TStringFormControlProps = ISchemaFormControlProps<string> & {
  isRequired?: boolean;
  fieldConfig?: IFieldConfig<any>;
};

const StringFormControl = ({
  fieldConfig,
  isDisabled,
  isRequired,
  onChange,
  schema,
  dataPath,
  value,
}: TStringFormControlProps) => {
  const onControlChange = React.useCallback<ChangeEventHandler<any>>(
    (e) => {
      let { value } = e.currentTarget;
      // e.g. allow empty e-mail address
      if (!isRequired && !value) {
        value = undefined;
      }
      onChange(value);
    },
    [isRequired, onChange]
  );

  const options = React.useMemo(() => {
    if (fieldConfig?.options) {
      return fieldConfig?.options;
    }
    if (schema && schema.enum) {
      return schema.enum.map((optionValue: string) => (
        <option key={optionValue} value={optionValue}>
          {t(optionValue)}
        </option>
      ));
    }
    return undefined;
  }, [fieldConfig?.options, schema]);

  if (schema.format === "date") {
    throw new Error("Not yet implemented");
  }

  if (options) {
    return (
      <div className="bp5-html-select">
        <select
          // isInvalid={!!errors.length}
          disabled={isDisabled}
          required={isRequired}
          name={dataPath}
          value={value || ""}
          onChange={onControlChange}
        >
          <option />
          {options}
        </select>
        <span className="bp5-icon bp5-icon-double-caret-vertical"></span>
      </div>
    );
  }
  return (
    <InputGroup
      disabled={isDisabled}
      type={schema.format || "text"}
      // isInvalid={!!errors.length}
      required={isRequired}
      value={value || ""}
      onChange={onControlChange}
    />
  );
};

export default StringFormControl;
