import React from "react";
import { Button, Icon, Spinner, Popover } from "@blueprintjs/core";
import useSearchTopicClaims from "../../../../hooks/useSearchTopicClaims";
import useSearchTopicMap from "../../../../hooks/useSearchTopicMap";
import { ApiDataContext } from "../../../../provider/apiData";
import { components } from "../../../../types/openapi";
import { KunciContext } from "../../../../inc/kunci";
import "./index.scss";

interface ISearchTopicButtonProps {
  disabled?: boolean;
}

const SearchTopicButton = ({ disabled }: ISearchTopicButtonProps) => {
  const {
    currentSearchTopicClaim,
    setNextSearchTopicClaim,
    setSearchTopicClaims,
    submitClaimedHits,
  } = React.useContext(ApiDataContext);
  const { auth } = React.useContext(KunciContext);
  const searchTopicClaims = useSearchTopicClaims();
  const { hydrateById, searchTopicMap } = useSearchTopicMap();
  const [query, setQuery] = React.useState("");

  const renderSearchTopicClaimLabel = React.useCallback(
    (searchTopicClaim: components["schemas"]["SearchTopicClaim"]) => {
      const searchTopic = searchTopicMap
        ? searchTopicMap[searchTopicClaim.searchTopicId]
        : undefined;
      if (!searchTopic) {
        hydrateById(searchTopicClaim.searchTopicId);
        return "⌛";
      }
      return `${searchTopic?.customerId} - ${
        searchTopic?.description || searchTopic?.searchTopicName
      }`;
    },
    [hydrateById, searchTopicMap]
  );

  const filteredSearchTopicClaims = React.useMemo(() => {
    const lcQuery = query.trim().toLowerCase();
    return searchTopicClaims
      ? searchTopicClaims.filter((searchTopicClaim) => {
          const searchTopic = searchTopicMap
            ? searchTopicMap[searchTopicClaim.searchTopicId]
            : undefined;
          if (!searchTopic || !lcQuery) {
            return true;
          }
          const { searchTopicName, description, comment, customerId } =
            searchTopic;
          return (
            `${searchTopicName}${description}${comment}${customerId}`
              .toLowerCase()
              .indexOf(lcQuery) >= 0
          );
        })
      : searchTopicClaims;
  }, [query, searchTopicClaims, searchTopicMap]);

  if (!auth) {
    return null;
  }

  return (
    <Popover
      className="search-topic-button"
      interactionKind="click"
      position={"right-top"}
      onOpening={() => {
        setSearchTopicClaims(undefined);
      }}
      content={
        <div style={{ maxWidth: 850, maxHeight: "80vh", overflow: "auto" }}>
          <div
            className="bp5-input-group"
            style={{ marginLeft: 10, marginTop: 10, marginRight: 10 }}
          >
            <span className="bp5-icon bp5-icon-search"></span>
            <input
              className="bp5-input"
              type="search"
              placeholder="Zoeken..."
              autoFocus={true}
              onChange={(e) => {
                setQuery(e.currentTarget.value);
              }}
              value={query}
            />
          </div>
          {filteredSearchTopicClaims ? (
            <table
              className="bp5-html-table bp5-html-table-bordered bp5-html-table-striped"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th style={{ width: 600 }}>Zoekopdracht</th>
                  <th style={{ width: 100 }}>
                    <div style={{ display: "flex" }}>
                      <Icon
                        icon="manual"
                        size={12}
                        style={{ marginRight: 4, marginTop: 3 }}
                      />
                      <span>Print</span>
                    </div>
                  </th>
                  <th style={{ width: 100 }}>
                    <div style={{ display: "flex" }}>
                      <Icon
                        icon="globe-network"
                        size={12}
                        style={{ marginRight: 4, marginTop: 3 }}
                      />
                      <span>Web</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredSearchTopicClaims.map((searchTopicClaim) => {
                  const isMine =
                    searchTopicClaim.webClaimedByUserName ===
                      auth?.jwt.displayName ||
                    searchTopicClaim.printClaimedByUserName ===
                      auth?.jwt.displayName;
                  return (
                    <tr key={searchTopicClaim.searchTopicId}>
                      <td width="100%">
                        <Button
                          disabled={isMine}
                          intent={
                            searchTopicClaim.printClaimedByUserName ||
                            searchTopicClaim.webClaimedByUserName
                              ? isMine
                                ? "success"
                                : "danger"
                              : undefined
                          }
                          onClick={async () => {
                            if (
                              (!searchTopicClaim.printClaimedByUserName &&
                                !searchTopicClaim.webClaimedByUserName) ||
                              window.confirm(
                                `Deze is reeds in behandeling!\n\n${
                                  searchTopicClaim.printClaimedByUserName ||
                                  searchTopicClaim.webClaimedByUserName
                                } verliest al het niet opgeslagen werk.\n\nWeet je zeker dat je dit wilt?`
                              )
                            ) {
                              await submitClaimedHits();
                              await setNextSearchTopicClaim(
                                {
                                  ...searchTopicClaim,
                                  printClaimedByUserName: auth.jwt.displayName,
                                  webClaimedByUserName: auth.jwt.displayName,
                                },
                                false,
                                true
                              );
                            }
                          }}
                        >
                          {renderSearchTopicClaimLabel(searchTopicClaim)}
                        </Button>
                      </td>
                      <td>
                        {searchTopicClaim.printClaimedByUserName ? (
                          searchTopicClaim.printClaimedByUserName.split(" ")[0]
                        ) : (
                          <Button
                            disabled={!searchTopicClaim.printCount}
                            onClick={async () => {
                              await submitClaimedHits();
                              await setNextSearchTopicClaim({
                                ...searchTopicClaim,
                                printClaimedByUserName: auth.jwt.displayName,
                              });
                            }}
                          >
                            {searchTopicClaim.printCount}
                          </Button>
                        )}
                      </td>
                      <td>
                        {searchTopicClaim.webClaimedByUserName ? (
                          searchTopicClaim.webClaimedByUserName.split(" ")[0]
                        ) : (
                          <Button
                            disabled={!searchTopicClaim.webCount}
                            onClick={async () => {
                              await submitClaimedHits();
                              await setNextSearchTopicClaim({
                                ...searchTopicClaim,
                                webClaimedByUserName: auth.jwt.displayName,
                              });
                            }}
                          >
                            {searchTopicClaim.webCount}
                          </Button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div style={{ margin: 50 }}>
              <Spinner size={50} />
            </div>
          )}
        </div>
      }
    >
      <Button
        disabled={disabled}
        style={{
          marginLeft: 8,
          marginRight: 8,
          marginBottom: 8,
        }}
        intent="primary"
        text={
          currentSearchTopicClaim
            ? renderSearchTopicClaimLabel(currentSearchTopicClaim)
            : `Kies een zoekopdracht`
        }
      />
    </Popover>
  );
};

export default SearchTopicButton;
