import React from "react";
import {
  Button,
  Checkbox,
  Classes,
  Icon,
  Menu,
  MenuDivider,
  MenuItem,
} from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import { Link, useNavigate } from "react-router-dom";
import { KunciContext } from "../../inc/kunci";
import { capitalize } from "lodash";
import CounterTag from "../CounterTag";
import { ApiDataContext } from "../../provider/apiData";
import { components } from "../../types/openapi";
import { dereferencedOpenApi } from "../../inc/schema";
import {
  ChatContext,
  MigChatWebSocketContext,
} from "@mediainfogroep/mig-chat-app";
import ChatIcon from "../../icons/chatIcon";
import "./index.scss";

const settings = dereferencedOpenApi.paths["/settings/{type}/{enable}"].post
  .parameters[0].schema.enum as string[];

const icons = {
  web: "globe-network",
  print: "manual",
  headLine: "header",
  backSearch: "history",
};

const SpeedreaderNavBar = () => {
  const navigate = useNavigate();
  const { auth, axios } = React.useContext(KunciContext);
  const { counters, updateCounters } = React.useContext(ApiDataContext);
  const { userRoles = [] } = auth?.jwt as components["schemas"]["Jwt"];
  const { webSocket } = React.useContext(MigChatWebSocketContext);
  const { chatDialogConfigHandler } = React.useContext(ChatContext);
  const isAdmin = userRoles.indexOf("admin") >= 0;
  const isPowerUser = isAdmin || userRoles.indexOf("powerUser") >= 0;
  const isMonitoringPlusUser =
    isAdmin || userRoles.indexOf("monitoringPlusUser") >= 0;

  React.useEffect(() => {
    updateCounters();
    const counterInterval = window.setInterval(updateCounters, 300000);
    return () => {
      clearInterval(counterInterval);
    };
  }, [updateCounters]);

  const openChatDialogHandler = React.useCallback(() => {
    chatDialogConfigHandler();
  }, [chatDialogConfigHandler]);

  return (
    <div className={[Classes.NAVBAR, Classes.DARK].join(" ")}>
      <Link to="/">
        <div className={Classes.NAVBAR_GROUP}>
          <img
            src="/whitelogomig.svg"
            alt="Speedreader"
            title="Speedreader"
            style={{ height: "80%", marginRight: 8 }}
          />
          <div
            className={Classes.NAVBAR_HEADING}
            style={{ color: "white", textDecoration: "none" }}
          >
            Speedreader
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {isPowerUser ? (
              <>
                <CounterTag counterKey="printToBeRead">
                  te lezen{" "}
                  <Icon
                    icon="manual"
                    size={12}
                    title="Print"
                    style={{ marginLeft: 4, marginTop: 3 }}
                  />
                </CounterTag>
                <CounterTag counterKey="printToBeChecked">
                  te checken{" "}
                  <Icon
                    icon="manual"
                    size={12}
                    title="Print"
                    style={{ marginLeft: 4, marginTop: 3 }}
                  />
                </CounterTag>
                <CounterTag counterKey="printOnHold">
                  on hold{" "}
                  <Icon
                    icon="manual"
                    size={12}
                    title="Print"
                    style={{ marginLeft: 4, marginTop: 3 }}
                  />
                </CounterTag>
                <CounterTag counterKey="webToBeRead">
                  te lezen{" "}
                  <Icon
                    icon="globe-network"
                    size={12}
                    title="Web"
                    style={{ marginLeft: 4, marginTop: 3 }}
                  />
                </CounterTag>
                <CounterTag counterKey="webToBeChecked">
                  te checken{" "}
                  <Icon
                    icon="globe-network"
                    size={12}
                    title="Web"
                    style={{ marginLeft: 4, marginTop: 3 }}
                  />
                </CounterTag>
                <CounterTag counterKey="webOnHold">
                  on hold{" "}
                  <Icon
                    icon="globe-network"
                    size={12}
                    title="Web"
                    style={{ marginLeft: 4, marginTop: 3 }}
                  />
                </CounterTag>
                <CounterTag counterKey="backsearchToBeRead">
                  te lezen{" "}
                  <Icon
                    icon="history"
                    size={12}
                    title="Backsearch"
                    style={{ marginLeft: 4, marginTop: 3 }}
                  />
                </CounterTag>
                <CounterTag counterKey="backsearchToBeChecked">
                  te checken{" "}
                  <Icon
                    icon="history"
                    size={12}
                    title="Backsearch"
                    style={{ marginLeft: 4, marginTop: 3 }}
                  />
                </CounterTag>
                <CounterTag counterKey="printOther">te controleren</CounterTag>
              </>
            ) : null}
          </div>
        </div>
      </Link>
      {isAdmin ? (
        <div className="bp5-navbar-group bp5-align-center type-toggles">
          {settings.map((setting) => {
            const counterKey = `${setting}Active` as
              | "printActive"
              | "webActive"
              | "headLineActive";
            return (
              <Checkbox
                key={counterKey}
                checked={!!counters[counterKey]}
                onChange={() => {
                  axios
                    .post(`/settings/${setting}/${!counters[counterKey]}`)
                    .then((res) => updateCounters(res.data));
                }}
                style={{ marginLeft: 16 }}
              >
                <Icon
                  icon={icons[setting as "print"] as "manual"}
                  size={16}
                  style={{ marginRight: 4, marginTop: 3 }}
                  htmlTitle={capitalize(setting)}
                />
              </Checkbox>
            );
          })}
        </div>
      ) : null}
      <div className="bp5-navbar-group bp5-align-right">
        <div
          title={
            webSocket.readyState === 1
              ? "Real-time communication is online"
              : "Real-time communication is down"
          }
          style={{
            display: "inline-block",
            cursor: "pointer",
            marginLeft: 8,
            marginRight: 8,
            marginTop: 0,
          }}
          onClick={openChatDialogHandler}
        >
          <ChatIcon
            height={25}
            width={25}
            fill={webSocket.readyState === 1 ? "green" : "red"}
          />
        </div>
        <Popover2
          content={
            <Menu>
              {isPowerUser ? (
                <MenuItem
                  icon="eye-on"
                  onClick={() => {
                    navigate("/matchItemHits");
                  }}
                  label="Leesresultaten"
                  text="Leesresultaten"
                />
              ) : null}
              {isPowerUser ? (
                <MenuItem
                  icon="search"
                  onClick={() => {
                    navigate("/searchTopics");
                  }}
                  label="Overzicht zoekopdrachten"
                  text="Overzicht zoekopdrachten"
                />
              ) : null}
              {isMonitoringPlusUser ? (
                <MenuItem
                  icon="plus"
                  onClick={() => {
                    navigate("/monitoringPlusMatchItemHits");
                  }}
                  label="Monitoringplusresultaten"
                  text="Monitoringplusresultaten"
                />
              ) : null}
              {userRoles.indexOf("admin") >= 0 ? (
                <MenuItem
                  onClick={() => {
                    navigate("/checkPreferences");
                  }}
                  icon="saved"
                  label="Check-voorkeuren"
                  text="Check-voorkeuren"
                />
              ) : null}
              {userRoles.indexOf("admin") >= 0 ? (
                <MenuItem
                  onClick={() => {
                    navigate("/users");
                  }}
                  icon="key"
                  label="Gebruikers"
                  text="Gebruikers"
                />
              ) : null}
              <MenuDivider />
              <MenuItem
                onClick={() => {
                  navigate("/profile");
                }}
                icon="user"
                label="Beheer uw lees-voorkeuren"
                text="Beheer uw lees-voorkeuren"
              />
              <MenuItem
                icon={"log-out"}
                label="Uitloggen"
                text="Uitloggen"
                href="/logout"
              />
            </Menu>
          }
        >
          <Button icon="cog" />
        </Popover2>
      </div>
    </div>
  );
};

export default SpeedreaderNavBar;
