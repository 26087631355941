import React from "react";
import { ChatDialog } from "@mediainfogroep/mig-chat-app";
import { KunciContext } from "../../inc/kunci";
import "@mediainfogroep/mig-chat-app/dist/index.css";

const Chat = () => {
  const { auth } = React.useContext(KunciContext);
  return <ChatDialog displayName={auth?.jwt.displayName || ""} />;
};

export default Chat;
