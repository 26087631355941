import { components } from "../../../types/openapi";
import { Spinner } from "@blueprintjs/core";
import * as React from "react";
import useMatchItemHit from "../../../hooks/useMatchItemHit";
import useMatchItemMap from "../../../hooks/useMatchItemMap";

const MatchItemHitIdCell = (
  deleteRequest?: components["schemas"]["DeleteRequest"]
) => {
  const matchItemHit = useMatchItemHit(deleteRequest?.matchItemHitId);
  const { matchItemMap } = useMatchItemMap(matchItemHit?.matchItemId);
  const matchItem = matchItemHit
    ? matchItemMap[matchItemHit?.matchItemId]
    : undefined;
  if (!deleteRequest?.matchItemHitId) {
    return <em>Geen - (RTV item?)</em>;
  }
  return matchItem ? (
    <strong>{matchItem.clipTitle}</strong>
  ) : (
    <div style={{ paddingTop: 8 }}>
      <Spinner size={16} />!
    </div>
  );
};

export default MatchItemHitIdCell;
