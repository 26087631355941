import { MultiSelect } from "@blueprintjs/select";
import { components } from "../../../types/openapi";
import usePublicationTypeMap from "../../../hooks/usePublicationTypeMap";
import { MenuItem, Spinner } from "@blueprintjs/core";
import { ISchemaFormControlProps } from "../../../components/schemaForm";

const ExcludedPublicationTypeIdsControl = ({
  onChange,
  value = [],
}: ISchemaFormControlProps<string[]>) => {
  const publicationTypeMap = usePublicationTypeMap();
  if (!publicationTypeMap) {
    return <Spinner />;
  }
  return (
    <MultiSelect<components["schemas"]["PublicationType"]>
      items={Object.values(publicationTypeMap)}
      itemRenderer={(
        { publicationTypeId, publicationType },
        { handleClick }
      ) => {
        const isSelected = value?.indexOf(publicationTypeId) >= 0;
        return (
          <MenuItem
            icon={isSelected ? "tick" : "blank"}
            key={publicationTypeId}
            label={publicationType}
            text={publicationType}
            onClick={handleClick}
          />
        );
      }}
      onItemSelect={({ publicationTypeId }) => {
        const isSelected = value?.indexOf(publicationTypeId) >= 0;
        onChange(
          isSelected
            ? value?.filter((existingId) => existingId !== publicationTypeId)
            : [...value, publicationTypeId]
        );
      }}
      tagRenderer={(
        publicationType: components["schemas"]["PublicationType"]
      ) => publicationType.publicationType}
      tagInputProps={{
        onRemove: (publicationTypeTitle) => {
          const publicationType = Object.values(publicationTypeMap).find(
            (publicationType) =>
              publicationType.publicationType === publicationTypeTitle
          );
          if (!publicationType) {
            return;
          }
          onChange(
            value.filter(
              (excludedPublicationTypeId) =>
                publicationType.publicationTypeId !== excludedPublicationTypeId
            )
          );
        },
      }}
      fill={true}
      placeholder=""
      selectedItems={
        value ? value.map((typeId) => publicationTypeMap[typeId]) : []
      }
    ></MultiSelect>
  );
};

export default ExcludedPublicationTypeIdsControl;
