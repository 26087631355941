import * as React from "react";
import escapeStringRegexp from "escape-string-regexp";
import { H1, EditableText } from "@blueprintjs/core";
import * as he from "he";
import CenteredSpinner from "../../../components/CenteredSpinner";
import { ApiDataContext, IClaim } from "../../../provider/apiData";
import HighlightedText from "./HighlightedText";
import "./index.scss";
import { components } from "../../../types/openapi";
import useMatchItemMap from "../../../hooks/useMatchItemMap";

interface IHighlightMatch {
  part: "title" | "text";
  partIndex: number;
  totalIndex: number;
  text: string;
  isWild: boolean;
}

export function highlight(
  claim: IClaim,
  matchItem: components["schemas"]["MatchItem"],
  highlightColor1: string,
  highlightColor2: string
) {
  let result = {
    title: matchItem.clipTitle,
    text: matchItem.clipText,
  };

  // find to be highlightedIndexes
  const toBeHighlighted: IHighlightMatch[] = [];
  claim.searchTerms.forEach((searchTerm) => {
    let bestMatch: IHighlightMatch | null = null;
    Object.entries(result).forEach(([resultProp, resultValue]) => {
      // https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
      const plainValue = resultValue
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      let matches = [
        ...plainValue.matchAll(
          // prevent use of \\b to find Disney+ or Disney +, see http://otrs.knipsel.lan/otrs/index.pl?Action=AgentTicketZoom;TicketID=12117#40519
          new RegExp(
            `([^a-zA-Z0-9_]|^)(${escapeStringRegexp(
              searchTerm.word
            )})([^a-zA-Z0-9_]|$)`,
            "gi"
          )
        ),
      ];
      if (!matches.length) {
        // https://www.notion.so/50819cc26dfc42eda68a202b31d0b291?v=68c3722489b24c8b8abe9fba382cbefb&p=887f1e04628345ce9d019d3db90737cd
        // Word not found? Try and match the wildcard!
        matches = [
          ...plainValue.matchAll(
            new RegExp(
              `(${searchTerm.searchTerm
                .split("")
                .join("-SPEED_READER_QUESTION_MARK")
                .replaceAll("+", "\\+")
                .replaceAll("*", "\\S*")
                .replaceAll("?", "\\S")
                .replaceAll("SPEED_READER_QUESTION_MARK", "?")})`,
              "gi"
            )
          ),
        ];
      }
      matches.forEach((match) => {
        if (match.index === undefined) {
          return;
        }
        const partIndex = match.index + (match[2] ? match[1].length : 0);
        if (
          // cannot highlight twice: find something else!
          toBeHighlighted.find(
            (highlightMatch) => highlightMatch.partIndex === partIndex
          )
        ) {
          return;
        }
        if (
          !bestMatch ||
          Math.abs(match.index - searchTerm.location) <
            Math.abs(bestMatch.totalIndex - searchTerm.location)
        ) {
          const plainText = match[2] || match[1];
          bestMatch = {
            part: resultProp as "title" | "text",
            partIndex,
            totalIndex: partIndex,
            text: resultValue.substring(
              partIndex,
              partIndex + plainText.length
            ),
            isWild: searchTerm.searchTerm.indexOf("*") >= 0,
          };
          return;
        }
      });
    });
    if (bestMatch) {
      toBeHighlighted.push(bestMatch);
    }
  });
  const usedIndexes: number[] = [];

  // add BB-tags before HTML encoding
  result = toBeHighlighted
    .filter((a) => {
      if (usedIndexes.indexOf(a.partIndex) >= 0) {
        return false;
      }
      usedIndexes.push(a.partIndex);
      return true;
    })
    .sort((a, b) => b.totalIndex - a.totalIndex)
    .reduce(
      (prev, toBeHighlighted) => ({
        ...prev,
        [toBeHighlighted.part]: `${prev[toBeHighlighted.part].substring(
          0,
          toBeHighlighted.partIndex
        )}[${toBeHighlighted.isWild ? "wild-highlight" : "highlight"}]${
          toBeHighlighted.text
        }[/${toBeHighlighted.isWild ? "wild-highlight" : "highlight"}]${prev[
          toBeHighlighted.part
        ].substring(toBeHighlighted.partIndex + toBeHighlighted.text.length)}`,
      }),
      result
    );

  // replace HTML-encode the output and replace BB-tags
  result = Object.entries(result).reduce(
    (prev, [bbTaggedKey, bbTaggedValue]) => {
      prev[bbTaggedKey as "title" | "text"] = he
        .encode(bbTaggedValue)
        .replaceAll(
          "[highlight]",
          `<span style="background-color: ${highlightColor1}" class="highlight">`
        )
        .replaceAll(
          "[wild-highlight]",
          `<span style="background-color: ${highlightColor2}" class="wild-highlight">`
        )
        .replaceAll("[/highlight]", "</span>")
        .replaceAll("[/wild-highlight]", `</span>`);
      return prev;
    },
    result
  );

  return result;
}

const Text = () => {
  const { currentClaimedMatchItemHit, updateMatchItemTitle, profile } =
    React.useContext(ApiDataContext);
  const { matchItemMap } = useMatchItemMap(
    currentClaimedMatchItemHit?.hit.matchItemId
  );

  const matchItem =
    matchItemMap && currentClaimedMatchItemHit
      ? matchItemMap[currentClaimedMatchItemHit.hit.matchItemId]
      : undefined;

  const highlightedClip = React.useMemo(() => {
    if (!currentClaimedMatchItemHit || !matchItem) {
      return {
        title: "",
        text: "",
      };
    }

    return highlight(
      currentClaimedMatchItemHit,
      matchItem,
      profile?.appData.theme.highlightColor1 || "#ADD8E6",
      profile?.appData.theme.highlightColor2 || "#FFFF00"
    );
  }, [
    currentClaimedMatchItemHit,
    matchItem,
    profile?.appData.theme.highlightColor1,
    profile?.appData.theme.highlightColor2,
  ]);

  React.useEffect(() => {
    setTimeout(() => {
      const highlightSpan = document.querySelector(".highlight");
      if (highlightSpan) {
        highlightSpan.scrollIntoView();
      }
    }, 1);
  }, [highlightedClip.text]);

  if (matchItem === undefined) {
    return <div className="mosaic-window__disabled-overlay" />;
  }

  if (!matchItem || !profile) {
    return <CenteredSpinner />;
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <H1
        style={{
          position: "relative",
          paddingTop: 20,
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        <HighlightedText
          text={highlightedClip.title}
          style={{ paddingTop: 20, paddingLeft: 20, paddingRight: 20 }}
        />
        <EditableText
          confirmOnEnterKey={true}
          multiline={true}
          key={matchItem.clipTitle}
          defaultValue={matchItem.clipTitle}
          onConfirm={updateMatchItemTitle}
        />
      </H1>
      <div
        style={{ overflow: "auto", flex: 1, paddingLeft: 20, paddingRight: 20 }}
      >
        <p
          className="highlighted-clip__text"
          dangerouslySetInnerHTML={{ __html: highlightedClip.text }}
          style={{
            columnCount: profile.appData.theme.columnCount || 2,
            columnGap: 20,
          }}
        />
      </div>
    </div>
  );
};

export default Text;
