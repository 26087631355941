import { SchemaObject } from "openapi3-ts";
import { ErrorObject } from "ajv";

import './index.scss'

export interface ISchemaFormControlProps<T> {
  errors: ErrorObject[];
  isDisabled?: boolean;
  dataPath: string;
  onChange: (newValue: T) => void;
  schema: SchemaObject;
  value?: T;
}

export const getDomIdFromDataPath = (dataPath: string) =>
  dataPath.replace(/[^a-z0-9]/gi, "_").replace(/^_+|_+$/g, "");
