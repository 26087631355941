import * as React from "react";
import { MosaicWindow } from "react-mosaic-component";
import { Intent } from "@blueprintjs/core/lib/esnext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { KunciContext } from "../../inc/kunci";
import { AxiosError } from "axios";

import { ApiDataContext } from "../../provider/apiData";
import CenteredSpinner from "../../components/CenteredSpinner";
import { KeyboardActions } from "../../inc/keyboard";
import { LayoutContext } from "../../provider/layout";
import { getStoredMatchItemHitActionResult } from "../../inc/matchItemHit";
import toaster from "../../inc/toaster";
import { components } from "../../types/openapi";
import { matchItemHitWindowComponents } from "../../components/mosaicWindows";
import { getName } from "../../inc/matchItem";
import SpeedReaderMosaic from "../../components/SpeedReaderMosaic";
import useMatchItemMap from "../../hooks/useMatchItemMap";
import ReclipView from "../ReadMatchItemHit/ReclipView";

const ManageMatchItemHit = () => {
  const location = useLocation();
  // @ts-ignore
  const { returnUrl } = location.state || {};
  const {
    claimHitById,
    claimNextHit,
    counters,
    currentClaimedMatchItemHit,
    setCurrentClaimedMatchItemHit,
    updateCounters,
  } = React.useContext(ApiDataContext);
  const { hydrateMatchItem, matchItemMap } = useMatchItemMap();
  const {
    isLast,
    isSearchTopicDialogOpen,
    setIsLast,
    setIsCommentOpen,
    setIsSearchTopicDialogOpen,
  } = React.useContext(LayoutContext);
  const [isInProgress, setIsInProgress] = React.useState(false);
  const navigate = useNavigate();
  const { auth, axios } = React.useContext(KunciContext);

  const { userRoles } = auth?.jwt as components["schemas"]["Jwt"];
  const isAdministrator = userRoles.indexOf("admin") >= 0;
  const isPowerUser = userRoles.indexOf("powerUser") >= 0;
  const isMonitoringPlusUser = userRoles.indexOf("monitoringPlusUser") >= 0;
  const matchItem =
    matchItemMap && currentClaimedMatchItemHit
      ? matchItemMap[currentClaimedMatchItemHit.hit.matchItemId]
      : undefined;

  const myAction = currentClaimedMatchItemHit?.actions.find(
    (action) => action.userName === auth?.jwt.userName
  );
  const processHit = React.useCallback(
    (
      result: "approved" | "skipped" | "rejected" | "isError" | "isNoAction",
      comment?: string
    ) => {
      if (
        !currentClaimedMatchItemHit ||
        (!currentClaimedMatchItemHit.hit.searchTopicId &&
          ["isNoAction", "error"].indexOf(result) === -1)
      ) {
        return;
      }

      setIsInProgress(true);
      setIsCommentOpen(false);
      setIsSearchTopicDialogOpen(false);

      return axios
        .request<components["schemas"]["ProcessResult"]>({
          method: "post",
          url: `/matchItemHit/${currentClaimedMatchItemHit.hit.matchItemHitId}/process`,
          params: {
            result,
            comment: myAction && myAction.comment ? myAction.comment : comment,
            overrule:
              currentClaimedMatchItemHit?.hit.matchItemHitStatus === "hold"
                ? undefined
                : "true",
          },
        })
        .then((res) => {
          if (!res.data.success) {
            throw new Error("Process matchItemHit failed");
          }
          if (matchItem) {
            const targetCounter =
              getStoredMatchItemHitActionResult(
                currentClaimedMatchItemHit.hit,
                currentClaimedMatchItemHit.actions
              ) === "none"
                ? "Read"
                : ("Checked" as keyof components["schemas"]["CountersResult"]);
            updateCounters({
              ...counters,
              // @ts-ignore
              [targetCounter]: counters[targetCounter] - 1,
            });
          }
          if (returnUrl) {
            navigate(returnUrl);
            return;
          }
          if (isLast) {
            setIsLast(false);
            navigate(`/`);
            return;
          }
          return claimNextHit().then(() => {
            setIsInProgress(false);
          });
          // claim next;
        })
        .catch((err: AxiosError) => {
          setIsInProgress(false);
          if (err.response?.status === 404) {
            toaster.show({
              message: `😊 Er zijn geen artikelen meer 😊`,
              intent: Intent.SUCCESS,
            });
            navigate("/");
            return;
          }

          toaster.show({ message: err.message, intent: Intent.DANGER });
        });
    },
    [
      axios,
      claimNextHit,
      counters,
      currentClaimedMatchItemHit,
      isLast,
      matchItem,
      myAction,
      navigate,
      returnUrl,
      setIsCommentOpen,
      setIsLast,
      setIsSearchTopicDialogOpen,
      updateCounters,
    ]
  );

  const doKeyboardAction = React.useCallback(
    (actionId: keyof typeof KeyboardActions) => {
      switch (actionId) {
        // case KeyboardActions.HIT_REJECT:
        //   processHit("rejected");
        //   break;
        case KeyboardActions.HIT_START_COMMENT:
          setIsCommentOpen(true);
          break;
        case KeyboardActions.HIT_ADD_SEARCHTOPIC:
          if (!isSearchTopicDialogOpen) {
            setIsSearchTopicDialogOpen(true);
          }
          break;
        // case KeyboardActions.HIT_SKIP:
        //   if (myAction?.comment) {
        //     processHit("skipped");
        //   }
        //   break;
        case KeyboardActions.HIT_APPROVE:
          processHit("approved");
          break;
        case KeyboardActions.HIT_MARK_LAST:
          setIsLast(!isLast);
      }
    },
    [
      isLast,
      isSearchTopicDialogOpen,
      processHit,
      setIsCommentOpen,
      setIsLast,
      setIsSearchTopicDialogOpen,
    ]
  );

  const { matchItemHitId } = useParams();

  // Ensure correct matchItemHit!
  React.useEffect(() => {
    const userName = auth?.jwt.userName;
    if (
      matchItemHitId &&
      userName &&
      (currentClaimedMatchItemHit === undefined ||
        (currentClaimedMatchItemHit &&
          currentClaimedMatchItemHit.hit.matchItemHitId !== matchItemHitId &&
          !isInProgress))
    ) {
      claimHitById(matchItemHitId);
    }
  }, [
    auth?.jwt.userName,
    claimHitById,
    currentClaimedMatchItemHit,
    isInProgress,
    matchItemHitId,
  ]);

  // Ensure matchItem!
  React.useEffect(() => {
    if (!currentClaimedMatchItemHit) {
      return;
    }
    if (
      matchItem === undefined ||
      (matchItem &&
        currentClaimedMatchItemHit.hit.matchItemId !== matchItem.matchItemId)
    ) {
      hydrateMatchItem(currentClaimedMatchItemHit.hit.matchItemId).catch(
        (err: AxiosError) => {
          const { response } = err;
          if (!response) {
            console.log(err);
            return;
          }
          processHit(
            "skipped",
            `MatchItem API-response: ${JSON.stringify(response.data)}`
          );
        }
      );
    }
  }, [
    auth?.jwt.userName,
    currentClaimedMatchItemHit,
    hydrateMatchItem,
    matchItem,
    processHit,
    setCurrentClaimedMatchItemHit,
  ]);

  if (!matchItem || isInProgress) {
    return <CenteredSpinner />;
  }

  if (!isAdministrator && !isPowerUser && !isMonitoringPlusUser) {
    return <div>Geen toegang</div>;
  }

  const isReclip = myAction?.matchItemHitActionResult === "reclipRequest";
  return isReclip ? (
    <ReclipView />
  ) : (
    <SpeedReaderMosaic
      appDataNodeName={"manageHitNode"}
      doKeyboardAction={doKeyboardAction}
    >
      {(matchItemHitWindowType, path) => {
        const HitWindowComponent =
          matchItemHitWindowComponents[matchItemHitWindowType];
        return (
          <MosaicWindow<number>
            title={getName(matchItem)}
            path={path}
            toolbarControls={[]}
            draggable={false}
            className={`mosaic-window--type${matchItemHitWindowType}`}
          >
            <HitWindowComponent processHit={processHit} />
          </MosaicWindow>
        );
      }}
    </SpeedReaderMosaic>
  );
};

export default ManageMatchItemHit;
