import { SchemaObject } from "openapi3-ts";
import { isPlainObject } from "lodash";
import openapi from "../openapi.json";

export type TSpeedreaderOpenApi = typeof openapi;

// traverse refs for proper form generation and stuff
function dereferenceFromOpenApi(schema: SchemaObject): SchemaObject {
  return Object.entries(schema).reduce((prev: any, [key, value]) => {
    if (key === "$ref") {
      let target = openapi;
      value
        .split("/")
        .slice(1)
        .forEach((prop: string) => {
          // @ts-ignore
          target = target[prop];
        });
      return dereferenceFromOpenApi(target);
    }
    prev[key] = isPlainObject(value) ? dereferenceFromOpenApi(value) : value;
    return prev;
  }, {});
}

export const dereferencedOpenApi = dereferenceFromOpenApi(
  openapi
) as TSpeedreaderOpenApi;
