import React from "react";
import { Button, Callout, FormGroup } from "@blueprintjs/core";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { intersection, pick, upperFirst } from "lodash";
import { KunciContext } from "../../inc/kunci";
import { Intent } from "@blueprintjs/core/lib/esm/common/intent";
import { SchemaObject } from "openapi3-ts";
import CenteredSpinner from "../../components/CenteredSpinner";
import { components, paths } from "../../types/openapi";
import useSearchTopicMap from "../../hooks/useSearchTopicMap";
import SchemaTable from "../../components/SchemaTable";
import { dereferencedOpenApi } from "../../inc/schema";
import { getName } from "../../inc/matchItem";
import { t } from "../../inc/i18n";
import useMatchItemMap from "../../hooks/useMatchItemMap";
import SearchTopicIdControl from "./SearchTopicIdControl";
import { parseLocationSearch, serializeLocationSearch } from "../../inc/data";
import toaster from "../../inc/toaster";
import openapi from "../../openapi.json";
import DatePicker from "react-datepicker";
import "./index.scss";

interface IErrors {
  [prop: string]: string;
}

function validate(
  monitoringPlusMatchItemHitCrudParams: Partial<
    paths["/monitoringPlus/matchItemHits"]["get"]["parameters"]["query"]
  >
): IErrors {
  let errors: any = {};
  ["searchTopicId", "startDate", "endDate"].forEach((requiredProp) => {
    if (!monitoringPlusMatchItemHitCrudParams[requiredProp as "startDate"]) {
      errors[requiredProp] = "Dit veld is verplicht";
    }
  });
  return errors;
}

const MonitoringPlusMatchItemHits = () => {
  const location = useLocation();
  const [
    monitoringPlusMatchItemHitCrudParams,
    setMonitoringPlusMatchItemHitCrudParams,
  ] = React.useState<
    Partial<
      paths["/monitoringPlus/matchItemHits"]["get"]["parameters"]["query"]
    >
  >(
    pick(
      parseLocationSearch(location.search),
      openapi.paths["/monitoringPlus/matchItemHits"].get.parameters.map(
        (parameter) => parameter.name
      )
    )
  );
  const [matchItemHits, setMatchItemHits] = React.useState<
    components["schemas"]["MatchItemHit"][] | null
  >();
  const [errors, setErrors] = React.useState<IErrors>({});
  const { hydrateMatchItem, matchItemMap } = useMatchItemMap();
  const { auth, axios } = React.useContext(KunciContext);
  const { searchTopicMap } = useSearchTopicMap();
  const navigate = useNavigate();

  const { userRoles } = auth?.jwt as components["schemas"]["Jwt"];

  const loadResults = React.useCallback(
    (e?: React.FormEvent<HTMLFormElement>) => {
      if (e) {
        e.preventDefault();
      }
      const newErrors = validate(monitoringPlusMatchItemHitCrudParams);
      setErrors(newErrors);
      if (Object.keys(newErrors).length) {
        return;
      }

      navigate({
        pathname: "/monitoringPlusMatchItemHits",
        search: `?${Object.entries(monitoringPlusMatchItemHitCrudParams)
          .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
          .join("&")}`,
      });
    },
    [monitoringPlusMatchItemHitCrudParams, navigate]
  );

  React.useEffect(() => {
    const params = pick(
      parseLocationSearch(location.search),
      openapi.paths["/monitoringPlus/matchItemHits"].get.parameters.map(
        (parameter) => parameter.name
      )
    );
    setMonitoringPlusMatchItemHitCrudParams(params);
    if (Object.keys(validate(params)).length) {
      setMatchItemHits(undefined);
      return;
    }
    setMatchItemHits(null);
    axios
      .request<components["schemas"]["MatchItemHit"][]>({
        method: "get",
        url: `/monitoringPlus/matchItemHits`,
        params,
      })
      .then((res) => {
        setMatchItemHits(res.data);
        return;
      })
      .catch((err) => {
        setMatchItemHits(undefined);
        toaster.show({
          message: `Laden lees-resultaten mislukt: ${err.message}`,
          intent: Intent.DANGER,
        });
      });
  }, [axios, location.search]);

  const config = React.useMemo(
    () => ({
      matchItemId: {
        order: -90,
        title: "Artikelnummer",
        renderData: (matchItemHit: components["schemas"]["MatchItemHit"]) => {
          const matchItem = matchItemMap[matchItemHit.matchItemId];
          return matchItem ? getName(matchItem) : "-";
        },
      },
      mediaType: {
        order: -85,
        width: 100,
        title: "Mediatype",
        renderData: (matchItemHit: components["schemas"]["MatchItemHit"]) => {
          return upperFirst(matchItemHit.mediaType);
        },
      },

      clipTitle: {
        order: -80,
        title: "Artikel titel",
        renderData: (matchItemHit: components["schemas"]["MatchItemHit"]) => {
          const matchItem = matchItemMap[matchItemHit.matchItemId];
          return matchItem?.clipTitle || "-";
        },
      },
      actions: {
        order: -55,
        title: "Klantnummer",
        width: 120,
        renderData: (matchItemHit: components["schemas"]["MatchItemHit"]) => {
          const searchTopic =
            searchTopicMap && matchItemHit.searchTopicId
              ? searchTopicMap[matchItemHit.searchTopicId]
              : undefined;
          return `${searchTopic?.customerId || ""}`;
        },
      },
      matchItemHitId: {
        order: -50,
        title: "Klantnaam",
        renderData: (matchItemHit: components["schemas"]["MatchItemHit"]) => {
          const searchTopic =
            searchTopicMap && matchItemHit.searchTopicId
              ? searchTopicMap[matchItemHit.searchTopicId]
              : undefined;
          return searchTopic?.customerName || "";
        },
      },
      searchTopicId: {
        order: -70,
        width: 250,
        title: "Searchtopic",
        renderData: (matchItemHit: components["schemas"]["MatchItemHit"]) => {
          const searchTopic =
            searchTopicMap && matchItemHit.searchTopicId
              ? searchTopicMap[matchItemHit.searchTopicId]
              : null;
          return searchTopic?.description || "...";
        },
      },
      monitoringPlusResult: {
        hidden: true,
      },
      readOnly: {
        hidden: true,
      },
      searchTerms: {
        hidden: true,
      },
      seenByAdmin: {
        hidden: true,
      },
      matchItemHitStatus: {
        order: 999,
        width: 150,
        title: "-",
        renderCell: (matchItemHit: components["schemas"]["MatchItemHit"]) => {
          hydrateMatchItem(matchItemHit.matchItemId);
          return (
            <Link
              to={{
                pathname: `/manage/${matchItemHit.matchItemHitId}`,
              }}
              state={{
                returnUrl: `/monitoringPlusMatchItemHits${serializeLocationSearch(
                  monitoringPlusMatchItemHitCrudParams
                )}`,
              }}
            >
              <Button intent={Intent.PRIMARY} text="Bekijken" small={true} />
            </Link>
          );
        },
      },
    }),
    [
      hydrateMatchItem,
      matchItemMap,
      monitoringPlusMatchItemHitCrudParams,
      searchTopicMap,
    ]
  );

  const getRowClassName = React.useCallback(
    (matchItemHit: components["schemas"]["MatchItemHit"]) =>
      `monitoring-plus-match-item-hits__row--${matchItemHit.monitoringPlusResult}`,
    []
  );

  if (!searchTopicMap) {
    return <CenteredSpinner />;
  }

  const isMonitoringPlus =
    intersection(userRoles, ["admin", "monitoringPlusUser"]).length > 0;
  if (!isMonitoringPlus) {
    return (
      <Callout title="Pagina niet beschikbaar" intent={Intent.WARNING}>
        <p>U heeft niet voldoende rechten voor het openen van deze pagina.</p>
      </Callout>
    );
  }

  return (
    <div>
      <form
        style={{ display: "flex", alignItems: "flex-end" }}
        onSubmit={loadResults}
      >
        <FormGroup
          label={t("searchTopicId")}
          labelFor="searchTopicId"
          style={{ margin: 8, padding: 8, flex: 1, border: "1px solid white" }}
          intent={errors.searchTopicId ? "danger" : "none"}
          helperText={errors.searchTopicId}
        >
          <SearchTopicIdControl
            value={monitoringPlusMatchItemHitCrudParams.searchTopicId}
            onChange={(searchTopicId) => {
              setErrors({});
              setMonitoringPlusMatchItemHitCrudParams(
                (monitoringPlusMatchItemHitCrudParams) => ({
                  ...monitoringPlusMatchItemHitCrudParams,
                  searchTopicId,
                })
              );
            }}
          />
        </FormGroup>
        <FormGroup
          style={{ margin: 8, padding: 8, border: "1px solid white" }}
          label="Startdatum"
          labelFor="startDate"
          intent={errors.startDate ? "danger" : "none"}
          helperText={errors.startDate}
        >
          <DatePicker
            onChange={(startDate) => {
              setErrors({});
              setMonitoringPlusMatchItemHitCrudParams(
                (monitoringPlusMatchItemHitCrudParams) => ({
                  ...monitoringPlusMatchItemHitCrudParams,
                  startDate: startDate ? startDate.toISOString() : undefined,
                })
              );
            }}
            selected={
              monitoringPlusMatchItemHitCrudParams.startDate
                ? new Date(monitoringPlusMatchItemHitCrudParams.startDate)
                : undefined
            }
          />
        </FormGroup>
        <FormGroup
          style={{ margin: 8, padding: 8, border: "1px solid white" }}
          label="Einddatum"
          labelFor="endDate"
          intent={errors.endDate ? "danger" : "none"}
          helperText={errors.endDate}
        >
          <DatePicker
            onChange={(endDate) => {
              setErrors({});
              setMonitoringPlusMatchItemHitCrudParams(
                (monitoringPlusMatchItemHitCrudParams) => ({
                  ...monitoringPlusMatchItemHitCrudParams,
                  endDate: endDate ? endDate.toISOString() : undefined,
                })
              );
            }}
            selected={
              monitoringPlusMatchItemHitCrudParams.endDate
                ? new Date(monitoringPlusMatchItemHitCrudParams.endDate)
                : undefined
            }
          />
        </FormGroup>
        <div style={{ margin: 8, padding: 8 }}>
          <Button type="submit" intent={Intent.PRIMARY} text="Zoek" />
        </div>
      </form>
      {matchItemHits ? (
        <SchemaTable
          data={matchItemHits}
          isFullScreen
          config={config}
          getRowClassName={getRowClassName}
          schema={
            dereferencedOpenApi.components.schemas.MatchItemHit as SchemaObject
          }
        />
      ) : null}
    </div>
  );
};

export default MonitoringPlusMatchItemHits;
