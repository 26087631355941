import * as React from "react";
import { Button } from "@blueprintjs/core";
import { ApiDataContext, IHashMap } from "../../../provider/apiData";
import CenteredSpinner from "../../../components/CenteredSpinner";
import { LayoutContext } from "../../../provider/layout";
import SearchTopicDialog from "./SearchTopicDialog";
import SearchTopicLi from "./SearchTopicLi";
import { KunciContext } from "../../../inc/kunci";
import { components } from "../../../types/openapi";
import useSearchTopicMap from "../../../hooks/useSearchTopicMap";
import { useLocation } from "react-router-dom";

const SearchTopics = () => {
  const {
    currentClaimedMatchItemHit,
    currentSearchTopicClaim,
    matchItemMap,
    profile,
    setSearchTopicResults,
  } = React.useContext(ApiDataContext);
  const { searchTopicMap } = useSearchTopicMap();
  const location = useLocation();
  const { isSearchTopicDialogOpen, setIsSearchTopicDialogOpen } =
    React.useContext(LayoutContext);
  const { axios } = React.useContext(KunciContext);

  const matchItem =
    matchItemMap && currentClaimedMatchItemHit
      ? matchItemMap[currentClaimedMatchItemHit.hit.matchItemId]
      : undefined;

  const searchTopic =
    searchTopicMap &&
    currentClaimedMatchItemHit &&
    currentClaimedMatchItemHit.hit.searchTopicId
      ? searchTopicMap[currentClaimedMatchItemHit.hit.searchTopicId]
      : null;
  const claimedSearchTopic =
    searchTopicMap && currentSearchTopicClaim
      ? searchTopicMap[currentSearchTopicClaim.searchTopicId]
      : undefined;

  const otherSearchTopics = React.useMemo<
    components["schemas"]["SearchTopic"][]
  >(
    () =>
      matchItem && matchItem.allSearchTopicIds && searchTopicMap
        ? matchItem.allSearchTopicIds
            .filter((searchTopicId) => {
              if (searchTopic && searchTopicId === searchTopic.searchTopicId) {
                return false;
              }
              const otherSearchTopic = searchTopicMap[searchTopicId];
              if (!otherSearchTopic) {
                return false;
              }
              if (claimedSearchTopic) {
                return (
                  claimedSearchTopic.customerId === otherSearchTopic.customerId
                );
              }

              if (
                currentClaimedMatchItemHit?.hit.matchItemHitStatus ===
                  "monitoringPlus" &&
                !otherSearchTopic.isForMonitoringPlus
              ) {
                return false;
              }

              return !!otherSearchTopic;
            })
            .map(
              (searchTopicId) =>
                searchTopicMap[
                  searchTopicId
                ] as components["schemas"]["SearchTopic"]
            )
            .sort((searchTopicA, searchTopicB) =>
              searchTopicA.searchTopicName < searchTopicB.searchTopicName
                ? -1
                : 1
            )
        : [],
    [
      claimedSearchTopic,
      currentClaimedMatchItemHit?.hit.matchItemHitStatus,
      matchItem,
      searchTopic,
      searchTopicMap,
    ]
  );

  React.useEffect(() => {
    if (
      !matchItem?.matchItemId ||
      !matchItem?.allSearchTopicIds ||
      matchItem?.allSearchTopicIds.length < 2
    ) {
      return;
    }
    setSearchTopicResults(null);
    axios
      .get<components["schemas"]["SearchTopicResult"][]>(
        `/searchTopic/result/${matchItem.matchItemId}`
      )
      .then((res) => {
        setSearchTopicResults(
          res.data.reduce<IHashMap<components["schemas"]["SearchTopicResult"]>>(
            (prev, searchTopicResult) => {
              prev[searchTopicResult.searchTopicId] = searchTopicResult;
              return prev;
            },
            {}
          )
        );
      });
  }, [
    axios,
    matchItem?.matchItemId,
    setSearchTopicResults,
    matchItem?.allSearchTopicIds,
  ]);

  if (matchItem === undefined) {
    return <div className="mosaic-window__disabled-overlay" />;
  }

  if (
    !currentClaimedMatchItemHit ||
    !searchTopicMap ||
    !profile ||
    !matchItem ||
    !otherSearchTopics
  ) {
    return <CenteredSpinner />;
  }

  const isMonitoringPlusRegular = !!currentSearchTopicClaim;
  const isDeleteRequest = location.pathname.indexOf("deleteRequest") >= 0;
  return (
    <div style={{ margin: 16 }}>
      {otherSearchTopics.length ? (
        <ul>
          {otherSearchTopics.map((searchTopic) => {
            return (
              <SearchTopicLi
                searchTopic={searchTopic}
                key={searchTopic.searchTopicId}
              />
            );
          })}
        </ul>
      ) : (
        "<< Geen andere zoekvragen >>"
      )}
      {isMonitoringPlusRegular || isDeleteRequest ? null : (
        <Button
          style={{ margin: 8 }}
          icon="plus"
          text="Toevoegen"
          onClick={() => {
            setIsSearchTopicDialogOpen(true);
          }}
        />
      )}

      <SearchTopicDialog
        onClose={() => {
          setIsSearchTopicDialogOpen(false);
        }}
        isOpen={isSearchTopicDialogOpen}
      />
    </div>
  );
};

export default SearchTopics;
