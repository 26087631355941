import React from "react";
import FileSaver from "file-saver";
import { KunciContext } from "../inc/kunci";
import { useParams } from "react-router-dom";

import useSearchTopicMap from "./useSearchTopicMap";
import { ApiDataContext } from "../provider/apiData";
import useMatchItemMap from "./useMatchItemMap";
import useDeleteRequests from "./useDeleteRequests";

function getEmailAddres(recipient: string): string {
  const nameParts = recipient.split(" ");
  if (nameParts.length < 2) {
    return "";
  }
  const initial = (nameParts.shift() as string).substring(0, 1).toLowerCase();
  const lastName = (nameParts.pop() as string).toLowerCase();
  return `${initial}${lastName}@mediainfogroep.nl`;
}

const useEmail = () => {
  const { currentClaimedMatchItemHit } = React.useContext(ApiDataContext);
  const { auth } = React.useContext(KunciContext);
  const { searchTopicMap } = useSearchTopicMap();
  const { matchItemMap } = useMatchItemMap();
  const deleteRequests = useDeleteRequests();
  const params = useParams<{ deleteRequestId: string }>();
  const deleteRequest =
    deleteRequests && params.deleteRequestId
      ? deleteRequests[params.deleteRequestId]
      : undefined;
  const matchItem =
    matchItemMap && currentClaimedMatchItemHit
      ? matchItemMap[currentClaimedMatchItemHit.hit.matchItemId]
      : undefined;

  return React.useCallback(() => {
    const searchTopic =
      searchTopicMap && currentClaimedMatchItemHit?.hit.searchTopicId
        ? searchTopicMap[currentClaimedMatchItemHit.hit.searchTopicId]
        : undefined;
    const subject = `Klantnr ${searchTopic?.customerId} - ${
      searchTopic?.customerName
    } - ${deleteRequest ? "Zoekopdracht moet worden aangepast" : "Vraag"}`;
    const clippingUrl = matchItem?.clippingUrls
      ? matchItem?.clippingUrls[0]
      : undefined;
    const blob = new Blob(
      [
        `To: "${searchTopic?.backOfficer}" <${getEmailAddres(
          searchTopic?.backOfficer || ""
        )}>
Cc: "${searchTopic?.accountManager}" <${getEmailAddres(
          searchTopic?.accountManager || ""
        )}>
Subject: ${subject}
X-Unsent: 1
Content-Type: text/html

<!DOCTYPE html>
<html lang="nl">
    <head><title>${subject}</title></head>
    <body>
        <p>Hoi,</p>
        
        <p>${
          deleteRequest
            ? "Er is een retourmelding voor het volgende artikel"
            : "Ik heb een vraag over het volgende artikel"
        }</p>
        <table>
            <tbody>
                <tr>
                    <td>Klantnummer:</td>
                    <td>${searchTopic?.customerId}</td>
                  </tr>
                  <tr>
                      <td>Accountmanager:</td>
                      <td>${searchTopic?.accountManager}</td>
                </tr>
                <tr>
                  <td>Backoffice:</td>
                  <td>${searchTopic?.backOfficer}</td>
                </tr>
                <tr>
                  <td>Zoekopdracht:</td>
                  <td>${searchTopic ? searchTopic.searchTopicName : "RTV"}</td>
              </tr>
              ${
                deleteRequest
                  ? `<tr>
                    <td>Credit vermelding (opmerking) door klant:</td>
                    <td>${deleteRequest.reason}</td>
                  </tr>`
                  : ""
              }
              ${
                clippingUrl
                  ? `
                <tr>
                    <td>Link naar artikel</td>
                    <td><a href="${clippingUrl}">${clippingUrl}</a></td>
                </tr>
`
                  : ""
              }
          </tbody>
        </table>
        ${
          currentClaimedMatchItemHit
            ? `
        <p>Het artikel is gevonden op de volgende matches:</p>
        <div style="max-width: 800px; border: 1px solid gray; padding: 20px;">
        <h2>${
          document.querySelector(".highlighted-clip__title")?.innerHTML
        }</h2><div style="column-count: 2; column-gap: 20px">${
                document.querySelector(".highlighted-clip__text")?.innerHTML
              }</div>
        </div>`
            : ""
        }
        <p>Groeten,</p>
        <p>${auth?.jwt.displayName}</p>
  </body>
</html>`,
      ],
      { type: "text/plain;charset=utf-8" }
    );
    FileSaver.saveAs(blob, "email.eml");
  }, [
    auth?.jwt.displayName,
    currentClaimedMatchItemHit,
    deleteRequest,
    matchItem?.clippingUrls,
    searchTopicMap,
  ]);
};

export default useEmail;
