import { components } from "../../../types/openapi";
import { Spinner } from "@blueprintjs/core";
import * as React from "react";
import usePublication from "../../../hooks/usePublication";

const PublicationCell = (
  checkPreference?: components["schemas"]["CheckPreference"]
) => {
  const publication = usePublication(checkPreference?.publicationId);
  if (!checkPreference?.publicationId) {
    return null;
  }
  return publication ? (
    <>{publication.name}</>
  ) : (
    <div style={{ paddingTop: 10 }}>
      <Spinner size={16} />
    </div>
  );
};

export default PublicationCell;
