import { ApiDataContext } from "../provider/apiData";
import React from "react";
import { components } from "../types/openapi";
import toaster from "../inc/toaster";
import { Intent } from "@blueprintjs/core/lib/esnext";
import { KunciContext } from "../inc/kunci";

export const usePublicationIssue = (publicationIssueId?: string) => {
  const { axios } = React.useContext(KunciContext);
  const { publicationIssueMap, setPublicationIssueMap } =
    React.useContext(ApiDataContext);

  const publicationIssue = publicationIssueId
    ? publicationIssueMap[publicationIssueId]
    : undefined;
  if (publicationIssue === undefined && publicationIssueId) {
    setPublicationIssueMap((publicationIssueMap) => ({
      ...publicationIssueMap,
      [publicationIssueId]: null,
    }));
    axios
      .request<components["schemas"]["PublicationIssue"]>({
        method: "get",
        url: `/publicationIssue/crud/${publicationIssueId}`,
      })
      .then((res) => {
        setPublicationIssueMap((publicationIssueMap) => ({
          ...publicationIssueMap,
          [publicationIssueId]: res.data,
        }));
      })
      .catch((err) => {
        setPublicationIssueMap((publicationIssueMap) => {
          const newPublicationIssueMap = { ...publicationIssueMap };
          delete newPublicationIssueMap[publicationIssueId];
          return newPublicationIssueMap;
        });
        toaster.show({
          message: `Laden publicatie mislukt: ${err.message}`,
          intent: Intent.DANGER,
        });
      });
  }
  return publicationIssue;
};
