import {
  Alignment,
  Button,
  Checkbox,
  EditableText,
  H1,
  Navbar,
  NavbarGroup,
} from "@blueprintjs/core";
import { Intent } from "@blueprintjs/core/lib/esnext";
import * as React from "react";
import { ApiDataContext } from "../../../provider/apiData";
import { LayoutContext } from "../../../provider/layout";
import { IReadHitWindowProps } from "../index";

const CheckTitle = ({ onForcedTitleCheckSubmit }: IReadHitWindowProps) => {
  const { matchItemMap, profile, currentClaimedMatchItemHit } =
    React.useContext(ApiDataContext);
  const { isLast, setIsLast } = React.useContext(LayoutContext);

  const matchItem =
    matchItemMap && currentClaimedMatchItemHit
      ? matchItemMap[currentClaimedMatchItemHit.hit.matchItemId]
      : undefined;

  const [clipTitle, setClipTitle] = React.useState<string>(
    matchItem?.clipTitle || ""
  );

  React.useEffect(() => {
    setClipTitle(matchItem?.clipTitle || "");
  }, [matchItem?.clipTitle]);

  if (!matchItem || !onForcedTitleCheckSubmit || !profile) {
    return null;
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <H1
          style={{
            position: "relative",
            paddingTop: 20,
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <EditableText
            key="read-match-item-hit__window__check-title"
            isEditing={true}
            multiline={true}
            value={clipTitle}
            onChange={(newClipTitle) => {
              if (newClipTitle.indexOf("\n") >= 0) {
                onForcedTitleCheckSubmit(clipTitle);
                return;
              }
              setClipTitle(newClipTitle);
            }}
            onConfirm={(newClipTitle) => {
              setClipTitle(newClipTitle);
            }}
          />
        </H1>
      </div>
      <Navbar style={{ height: 40, padding: 8 }}>
        <Navbar.Group align={Alignment.LEFT}>
          <Checkbox
            label={`Laatste actie`}
            style={{ marginBottom: 0 }}
            checked={isLast}
            onChange={() => {
              setIsLast(!isLast);
            }}
          />
        </Navbar.Group>
        <NavbarGroup align={Alignment.RIGHT} style={{ height: 36 }}>
          <Button
            intent={Intent.SUCCESS}
            onClick={() =>
              onForcedTitleCheckSubmit(clipTitle.replace(/\r?\n/g, ""))
            }
          >
            Titel gecontroleerd
          </Button>
        </NavbarGroup>
      </Navbar>
    </div>
  );
};

export default CheckTitle;
