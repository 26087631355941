import { Intent } from "@blueprintjs/core";
import { KunciContext } from "../inc/kunci";
import React from "react";
import { ApiDataContext, IHashMap } from "../provider/apiData";
import toaster from "../inc/toaster";
import { components } from "../types/openapi";

const useDeleteRequests = () => {
  const { deleteRequests, setDeleteRequests } =
    React.useContext(ApiDataContext);
  const { auth, axios } = React.useContext(KunciContext);
  const { userRoles = [] } = auth?.jwt as components["schemas"]["Jwt"];

  React.useEffect(() => {
    if (
      userRoles.indexOf("admin") + userRoles.indexOf("powerUser") === -2 ||
      deleteRequests !== undefined
    ) {
      return;
    }

    setDeleteRequests(null);
    axios
      .get<components["schemas"]["DeleteRequest"][]>("/deleteRequest/crud")
      .then((res) => {
        if (!res.data.length) {
          throw new Error("Failed to retrieve deleteRequests");
        }
        setDeleteRequests(
          res.data.reduce<IHashMap<components["schemas"]["DeleteRequest"]>>(
            (prev, deleteRequest) => {
              prev[deleteRequest.deleteRequestId as string] = deleteRequest;
              return prev;
            },
            {}
          )
        );
      })
      .catch((err) => {
        toaster.show({
          message: `Laden verwijderverzoeken mislukt: ${err.message}`,
          intent: Intent.DANGER,
        });
      });
  }, [axios, setDeleteRequests, deleteRequests, userRoles]);

  return deleteRequests;
};

export default useDeleteRequests;
