import { Intent } from "@blueprintjs/core/lib/esnext";
import { Tag } from "@blueprintjs/core";
import React from "react";
import { ApiDataContext } from "../../provider/apiData";
import { t } from "../../inc/i18n";
import { components } from "../../types/openapi";

interface ICounterTagProps {
  children: any;
  counterKey: keyof components["schemas"]["CountersResult"];
}

const CounterTag = ({ counterKey, children }: ICounterTagProps) => {
  const { counters } = React.useContext(ApiDataContext);
  const count = counters[counterKey] || 0;
  const intent = count ? Intent.PRIMARY : Intent.SUCCESS;

  return (
    <Tag
      minimal={true}
      round={true}
      intent={intent}
      style={{ marginBottom: 4, color: "white" }}
      title={`${counters[counterKey]} ${t(
        counterKey.split("To")[0].split("On")[0]
      )}`}
    >
      {count} {children}
    </Tag>
  );
};

export default CounterTag;
