import * as React from "react";
import { IReadHitWindowProps } from "../index";
import { ApiDataContext } from "../../../provider/apiData";
import { t } from "../../../inc/i18n";
import "./index.scss";

const ReadResult = (props: IReadHitWindowProps) => {
  const { currentClaimedMatchItemHit, searchTopicMap } =
    React.useContext(ApiDataContext);

  const searchTopic =
    searchTopicMap && currentClaimedMatchItemHit?.hit.searchTopicId
      ? searchTopicMap[currentClaimedMatchItemHit.hit.searchTopicId]
      : undefined;

  const actions = React.useMemo(
    () =>
      currentClaimedMatchItemHit?.actions
        .filter((action) => action.version !== -1)
        .sort((a, b) => a.version - b.version) || [],
    [currentClaimedMatchItemHit?.actions]
  );

  return (
    <div
      style={{
        margin: 16,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "hidden",
        alignItems: "stretch",
      }}
    >
      <dl>
        {searchTopic ? (
          <>
            <dt>Klantnaam</dt>
            <dd>{searchTopic?.customerName}</dd>
            <dt>Accountmanager</dt>
            <dd>
              <span>{searchTopic?.accountManager}</span>
            </dd>
            <dt>Backofficer</dt>
            <dd>
              <span>{searchTopic?.backOfficer}</span>
            </dd>
          </>
        ) : null}
        {actions.length ? (
          <>
            <dt>Beoordelingen</dt>
            <dd>
              <ul>
                {actions.map((action) => (
                  <li key={action.version}>
                    {action.userName}: {t(action.matchItemHitActionResult)}
                  </li>
                ))}
              </ul>
            </dd>
          </>
        ) : null}
      </dl>
    </div>
  );
};

export default ReadResult;
