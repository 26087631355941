import React from "react";
import { components } from "../types/openapi";
import { ApiDataContext, IHashMap } from "../provider/apiData";
import toaster from "../inc/toaster";
import { Intent } from "@blueprintjs/core";
import { KunciContext } from "../inc/kunci";

const usePublicationTypeMap = () => {
  const { publicationTypeMap, setPrintPublicationTypeMap } =
    React.useContext(ApiDataContext);
  const { axios } = React.useContext(KunciContext);

  React.useEffect(() => {
    if (publicationTypeMap !== undefined) {
      return;
    }

    setPrintPublicationTypeMap(null);
    axios
      .get<components["schemas"]["PublicationType"][]>("/publication/type")
      .then((printRes) => {
        if (!printRes.data.length) {
          throw new Error("Failed to retrieve print publicationTypeMap");
        }
        setPrintPublicationTypeMap(
          printRes.data.reduce<
            IHashMap<components["schemas"]["PublicationType"]>
          >((prev, printPublicationType) => {
            prev[printPublicationType.publicationTypeId as string] = {
              ...printPublicationType,
              // publicationType: `Print: ${printPublicationType.publicationType}`,
            };
            return prev;
          }, {})
        );
      })
      .catch((err) => {
        toaster.show({
          message: `Laden publicatietypes mislukt: ${err.message}`,
          intent: Intent.DANGER,
        });
      });
  }, [axios, publicationTypeMap, setPrintPublicationTypeMap]);

  return publicationTypeMap;
};

export default usePublicationTypeMap;
