import React from "react";
import { Button, Callout } from "@blueprintjs/core";
import { ApiDataContext } from "../../provider/apiData";
import CenteredSpinner from "../../components/CenteredSpinner";
import axios, { AxiosError } from "axios";
import Ajv from "ajv";
import { ValidationError } from "ajv";
import { t } from "../../inc/i18n";
import { Intent } from "@blueprintjs/core/lib/esm/common/intent";
import toaster from "../../inc/toaster";
import openapi from "../../openapi.json";
import { SchemaObject } from "openapi3-ts";
import { components } from "../../types/openapi";
import { useNavigate, useParams } from "react-router-dom";
import { KunciContext } from "../../inc/kunci";
import SchemaFormBody from "../../components/schemaForm/SchemaFormBody";
import DomainIdControl from "./DomainIdControl";
import usePublicationTypeMap from "../../hooks/usePublicationTypeMap";
import PublicationIdControl from "./PublicationIdControl";
import useDomainTypeMap from "../../hooks/useDomainTypeMap";
import useCheckPreferences from "../../hooks/useCheckPreferences";

const ajv = new Ajv({
  formats: {
    password: "",
  },
});
ajv.addSchema(openapi, "openapi.json");

const CheckPreference = () => {
  const { setCheckPreferences, profile } = React.useContext(ApiDataContext);
  const navigate = useNavigate();
  const { checkPreferenceId } = useParams();
  const { auth } = React.useContext(KunciContext);
  const domainTypeMap = useDomainTypeMap();
  const publicationTypeMap = usePublicationTypeMap();
  const checkPreferencesMap = useCheckPreferences();

  const { userRoles } = auth?.jwt as components["schemas"]["Jwt"];
  const [formCheckPreference, setFormCheckPreference] = React.useState<
    components["schemas"]["CheckPreference"]
  >({
    checkPercentage: 100,
    readAllArticles: false,
  });
  const [errors, setErrors] = React.useState<ValidationError["errors"]>();
  const [isInProgress, setIsInProgress] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (
      checkPreferencesMap &&
      formCheckPreference.checkPreferenceId !== checkPreferenceId
    ) {
      if (!checkPreferenceId) {
        return;
      }
      const newFormCheckPreference = checkPreferencesMap[checkPreferenceId];
      if (newFormCheckPreference) {
        setFormCheckPreference(newFormCheckPreference);
      }
    }
  }, [formCheckPreference, checkPreferencesMap, checkPreferenceId]);

  const onSubmit = React.useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      const data = formCheckPreference;
      if (
        !ajv.validate(openapi.components.schemas.CheckPreference, data) &&
        ajv.errors
      ) {
        console.log(data, ajv.errors);
        setErrors(ajv.errors);
        return;
      }

      if (
        !data.domainId &&
        !data.domainTypeId &&
        !data.publicationId &&
        !data.publicationTypeId
      ) {
        console.log(data, "Missing Checkpreference-contents");
        setErrors([
          {
            keyword: "custom",
            dataPath: ".",
            schemaPath: ".",
            message: "Missing Checkpreference-contents",
            params: {},
          },
        ]);
        return;
      }

      const checkPreferences = Object.values(checkPreferencesMap || {});
      const conflictCcheckResult = [
        "domainId",
        "domainTypeId",
        "publicationId",
        "publicationTypeId",
      ].some((conflictProp) => {
        if (data[conflictProp as "domainId"]) {
          const conflict = checkPreferences.find(
            (obj) =>
              obj[conflictProp as "domainId"] ===
                data[conflictProp as "domainId"] &&
              obj.checkPreferenceId !== data.checkPreferenceId
          );
          if (conflict) {
            setErrors([
              {
                keyword: "custom",
                dataPath: `.${conflictProp}`,
                schemaPath: `.${conflictProp}`,
                message: "Conflict with other CheckPreference",
                params: {},
              },
            ]);
            return true;
          }
        }
        return false;
      });
      if (conflictCcheckResult) {
        return;
      }

      setErrors(undefined);
      setIsInProgress(true);
      axios
        .request<components["schemas"]["CheckPreference"]>({
          method: data.checkPreferenceId ? "put" : "post",
          url: `/checkPreference/crud${
            data.checkPreferenceId ? `/${data.checkPreferenceId}` : ""
          }`,
          data,
        })
        .then((updatedCheckPreferenceResponse) => {
          const updatedCheckPreference = updatedCheckPreferenceResponse.data;
          setIsInProgress(false);
          toaster.show({
            message: `Voorkeur opgeslagen`,
            intent: Intent.SUCCESS,
          });
          setCheckPreferences({
            ...checkPreferencesMap,
            [updatedCheckPreference.checkPreferenceId as string]:
              updatedCheckPreference,
          });
          navigate(`/checkPreferences`);
        })
        .catch((err: AxiosError) => {
          setIsInProgress(false);
          toaster.show({
            message: `Opslaan check-voorkeur mislukt: ${t(
              (err.response?.data as components["schemas"]["ValidationError"])
                .message || err.message
            )}`,
            intent: Intent.DANGER,
          });
        });
    },
    [checkPreferencesMap, formCheckPreference, navigate, setCheckPreferences]
  );

  if (!checkPreferencesMap || !profile || isInProgress) {
    return <CenteredSpinner />;
  }

  if (userRoles.indexOf("admin") === -1) {
    return (
      <Callout
        title="Pagina niet beschikbaar"
        intent={Intent.WARNING}
        style={{ margin: 8 }}
      >
        <p>U heeft niet voldoende rechten voor het openen van deze pagina.</p>
      </Callout>
    );
  }

  return (
    <form
      onSubmit={onSubmit}
      style={{ maxWidth: 500, padding: 16 }}
      className="routes__checkPreference"
    >
      <SchemaFormBody
        errors={errors}
        schema={openapi.components.schemas.CheckPreference as SchemaObject}
        value={formCheckPreference}
        onChange={setFormCheckPreference}
        fieldConfigs={{
          publicationTypeId: {
            isDisabled: !!(
              formCheckPreference.publicationId ||
              formCheckPreference.domainTypeId ||
              formCheckPreference.domainId
            ),
            options: publicationTypeMap
              ? Object.values(publicationTypeMap)
                  .sort((a, b) =>
                    a.publicationType > b.publicationType ? 1 : -1
                  )
                  .map((publicationType) => (
                    <option
                      key={publicationType.publicationTypeId}
                      value={publicationType.publicationTypeId}
                    >
                      {publicationType.publicationType}
                    </option>
                  ))
              : [],
          },
          publicationId: {
            isDisabled: !!(
              formCheckPreference.publicationTypeId ||
              formCheckPreference.domainTypeId ||
              formCheckPreference.domainId
            ),
            Control: PublicationIdControl,
          },
          domainTypeId: {
            isDisabled: !!(
              formCheckPreference.publicationTypeId ||
              formCheckPreference.publicationId ||
              formCheckPreference.domainId
            ),
            options: domainTypeMap
              ? Object.values(domainTypeMap)
                  .sort((a, b) => (a.domainType > b.domainType ? 1 : -1))
                  .map((domainType) => (
                    <option
                      key={domainType.domainTypeId}
                      value={domainType.domainTypeId}
                    >
                      {domainType.domainType}
                    </option>
                  ))
              : [],
          },
          domainId: {
            isDisabled: !!(
              formCheckPreference.publicationTypeId ||
              formCheckPreference.publicationId ||
              formCheckPreference.domainTypeId
            ),
            Control: DomainIdControl,
          },
        }}
      />
      <Button
        type="submit"
        text="Opslaan"
        intent={Intent.PRIMARY}
        large={true}
        style={{ marginTop: 16 }}
      />
    </form>
  );
};

export default CheckPreference;
