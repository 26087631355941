import * as React from "react";
import { debounce } from "lodash";
import { Dispatch, RefObject, SetStateAction } from "react";
import { FixedSizeList } from "react-window";
import { ApiDataContext } from "./apiData";
import useClaimedMatchItemHits from "../hooks/useClaimedMatchItemHits";
import { components } from "../types/openapi";
import { emptyFn } from "../inc/data";
import { KunciContext } from "../inc/kunci";

interface ILayoutContext {
  deleteRequestTableScrollTop: number;
  isCommentOpen: boolean;
  isLast: boolean;
  isMatchItemHitVisible: (
    matchItem: components["schemas"]["MatchItemHit"]
  ) => boolean;
  isSearchTopicDialogOpen: boolean;
  monitoringPlusListRef?: RefObject<FixedSizeList>;
  openMonitoringPlusHit: (matchItemHitId: string) => void;
  readingMinutes: number;
  setDeleteRequestTableScrollTop: (scrollTop: number) => void;
  setIsCommentOpen: (isCommentOpen: boolean) => void;
  setIsLast: (isLast: boolean) => void;
  setIsSearchTopicDialogOpen: (isSearchTopicDialogOpen: boolean) => void;
  setReadingMinutes: (readingMinutes: number) => void;
  setShowPrint: Dispatch<SetStateAction<boolean>>;
  setShowStatusApproving: Dispatch<SetStateAction<boolean>>;
  setShowStatusNone: Dispatch<SetStateAction<boolean>>;
  setShowStatusRejecting: Dispatch<SetStateAction<boolean>>;
  setShowWeb: Dispatch<SetStateAction<boolean>>;
  showPrint: boolean;
  showStatusApproving: boolean;
  showStatusNone: boolean;
  showStatusRejecting: boolean;
  showWeb: boolean;
  windowInnerHeight: number;
  windowOuterWidth: number;
}

export const LayoutContext = React.createContext<ILayoutContext>({
  deleteRequestTableScrollTop: 0,
  isCommentOpen: false,
  isLast: false,
  isMatchItemHitVisible: () => true,
  isSearchTopicDialogOpen: false,
  openMonitoringPlusHit: emptyFn,
  readingMinutes: 0,
  setIsLast: emptyFn,
  setDeleteRequestTableScrollTop: emptyFn,
  setIsCommentOpen: emptyFn,
  setIsSearchTopicDialogOpen: emptyFn,
  setReadingMinutes: emptyFn,
  setShowPrint: emptyFn,
  setShowStatusApproving: emptyFn,
  setShowStatusNone: emptyFn,
  setShowStatusRejecting: emptyFn,
  setShowWeb: emptyFn,
  showPrint: true,
  showStatusApproving: true,
  showStatusNone: true,
  showStatusRejecting: true,
  showWeb: true,
  windowOuterWidth: window.outerWidth,
  windowInnerHeight: window.innerHeight,
});

const Layout = ({ children }: any) => {
  const { setCurrentClaimedMatchItemHit } = React.useContext(ApiDataContext);
  const { axios } = React.useContext(KunciContext);
  const [deleteRequestTableScrollTop, setDeleteRequestTableScrollTop] =
    React.useState(0);
  const [isLast, setIsLast] = React.useState(false);
  const [isCommentOpen, setIsCommentOpen] = React.useState(false);
  const [isSearchTopicDialogOpen, setIsSearchTopicDialogOpen] =
    React.useState(false);
  const [readingMinutes, setReadingMinutes] = React.useState(0);
  const [showWeb, setShowWeb] = React.useState(true);
  const [showPrint, setShowPrint] = React.useState(true);
  const [showStatusApproving, setShowStatusApproving] = React.useState(true);
  const [showStatusNone, setShowStatusNone] = React.useState(true);
  const [showStatusRejecting, setShowStatusRejecting] = React.useState(true);
  const monitoringPlusListRef = React.useRef<FixedSizeList>(null);
  const claimedMatchItemHits = useClaimedMatchItemHits();

  const isMatchItemHitVisible = React.useCallback(
    (claimedMatchItemHit: components["schemas"]["MatchItemHit"]) => {
      if (claimedMatchItemHit.mediaType === "print" && !showPrint) {
        return false;
      }
      if (claimedMatchItemHit.mediaType === "web" && !showWeb) {
        return false;
      }
      if (
        !showStatusApproving &&
        claimedMatchItemHit.monitoringPlusResult === "approving"
      ) {
        return false;
      }
      if (
        !showStatusNone &&
        claimedMatchItemHit.monitoringPlusResult === "none"
      ) {
        return false;
      }
      return !(
        !showStatusRejecting &&
        claimedMatchItemHit.monitoringPlusResult === "rejecting"
      );
    },
    [
      showPrint,
      showStatusApproving,
      showStatusNone,
      showStatusRejecting,
      showWeb,
    ]
  );

  const filteredClaimedMatchItemHits = React.useMemo(
    () =>
      claimedMatchItemHits
        ? claimedMatchItemHits.filter(isMatchItemHitVisible)
        : [],
    [claimedMatchItemHits, isMatchItemHitVisible]
  );

  const [{ windowOuterWidth, windowInnerHeight }, setWndowDimensions] =
    React.useState<{
      windowOuterWidth: number;
      windowInnerHeight: number;
    }>({
      windowOuterWidth: window.outerWidth,
      windowInnerHeight: window.innerHeight,
    });

  React.useEffect(() => {
    const updateWindowDimensions = debounce(() => {
      setWndowDimensions({
        windowOuterWidth: window.outerWidth,
        windowInnerHeight: window.innerHeight,
      });
    }, 100);
    window.onresize = updateWindowDimensions;
    window.onfocus = updateWindowDimensions;
  }, []);

  const openMonitoringPlusHit = React.useCallback(
    async (matchItemHitId: string) => {
      if (!filteredClaimedMatchItemHits) {
        return;
      }
      const index = filteredClaimedMatchItemHits.findIndex(
        (claimedMatchItemHit) =>
          claimedMatchItemHit.matchItemHitId === matchItemHitId
      );
      if (index < 0) {
        return;
      }
      setCurrentClaimedMatchItemHit({
        date: new Date(),
        hit: filteredClaimedMatchItemHits[index],
        actions: [],
        searchTerms: [],
      });
      axios
        .request<components["schemas"]["SearchTerm"][]>({
          method: "get",
          url: `/matchItemHit/${matchItemHitId}/searchTerms`,
        })
        .then((res) => {
          setCurrentClaimedMatchItemHit((currentClaimedMatchItemHit) =>
            currentClaimedMatchItemHit
              ? {
                  ...currentClaimedMatchItemHit,
                  searchTerms: res.data,
                }
              : currentClaimedMatchItemHit
          );
        });
      if (monitoringPlusListRef.current) {
        monitoringPlusListRef.current.scrollToItem(index);
      }
    },
    [axios, filteredClaimedMatchItemHits, setCurrentClaimedMatchItemHit]
  );

  return (
    <LayoutContext.Provider
      value={{
        deleteRequestTableScrollTop,
        isCommentOpen,
        isLast,
        isMatchItemHitVisible,
        isSearchTopicDialogOpen,
        monitoringPlusListRef,
        openMonitoringPlusHit,
        readingMinutes,
        setDeleteRequestTableScrollTop,
        setIsCommentOpen,
        setIsLast,
        setIsSearchTopicDialogOpen,
        setReadingMinutes,
        setShowPrint,
        setShowStatusApproving,
        setShowStatusNone,
        setShowStatusRejecting,
        setShowWeb,
        showPrint,
        showStatusApproving,
        showStatusNone,
        showStatusRejecting,
        showWeb,
        windowInnerHeight,
        windowOuterWidth,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export default Layout;
