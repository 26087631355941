import React from "react";
import { Button, Callout, FormGroup, InputGroup } from "@blueprintjs/core";
import { KunciContext } from "../../inc/kunci";
import { Intent } from "@blueprintjs/core/lib/esm/common/intent";
import { addYears, format } from "date-fns";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { chunk, flatten, intersection, pick, uniq } from "lodash";
import { SchemaObject } from "openapi3-ts";
import toaster from "../../inc/toaster";
import { components, paths } from "../../types/openapi";
import useSearchTopicMap from "../../hooks/useSearchTopicMap";
import SchemaTable, { IColumnConfig } from "../../components/SchemaTable";
import { dereferencedOpenApi } from "../../inc/schema";
import CenteredSpinner from "../../components/CenteredSpinner";
import { getName } from "../../inc/matchItem";
import { getStoredMatchItemHitActionResult } from "../../inc/matchItemHit";
import useMatchItemMap from "../../hooks/useMatchItemMap";
import { parseLocationSearch, serializeLocationSearch } from "../../inc/data";
import openapi from "../../openapi.json";
import { IHashMap } from "../../provider/apiData";
import DatePicker from "react-datepicker";
import type { ButtonProps } from "@blueprintjs/core/src/components/button/buttonProps";
import "./index.scss";

function isValid(
  matchItemHitCrudParams: paths["/matchItemHit/crud"]["get"]["parameters"]["query"]
) {
  let hasValidUrl = false;
  if (matchItemHitCrudParams.url) {
    try {
      let url = new URL(matchItemHitCrudParams.url);
      hasValidUrl = url.protocol === "http:" || url.protocol === "https:";
    } catch (_) {
      hasValidUrl = false;
    }
  }

  return (
    hasValidUrl ||
    matchItemHitCrudParams.isOnHold ||
    matchItemHitCrudParams.hasComment ||
    matchItemHitCrudParams.isInProgress ||
    matchItemHitCrudParams.isError ||
    matchItemHitCrudParams.articleId ||
    (matchItemHitCrudParams.publicationId &&
      matchItemHitCrudParams.publicationDate)
  );
}

const isFinishable = (
  matchItemHit: components["schemas"]["MatchItemHit"],
  actions: components["schemas"]["MatchItemHitAction"][]
): boolean => {
  if (
    !matchItemHit ||
    !actions?.length ||
    matchItemHit.matchItemHitStatus === "hold"
  ) {
    return false;
  }
  const hasComment = !!actions.filter((action) => !!action.comment).length;
  return (
    !matchItemHit.seenByAdmin &&
    (matchItemHit.matchItemHitStatus === "processError" || hasComment)
  );
};

const MatchItemHits = () => {
  const location = useLocation();
  const { auth, axios } = React.useContext(KunciContext);
  const { searchTopicMap } = useSearchTopicMap();
  const [matchItemHits, setMatchItemHits] = React.useState<
    components["schemas"]["MatchItemHit"][] | null
  >();
  const [matchItemHitActions, setMatchItemHitActions] = React.useState<
    IHashMap<components["schemas"]["MatchItemHitAction"][] | null>
  >({});

  const { hydrateMatchItem, matchItemMap } = useMatchItemMap();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!matchItemHits) {
      return;
    }
    const toBeHydratedMatchItemHitIds = uniq(
      matchItemHits
        .map((matchItemHit) => matchItemHit.matchItemHitId)
        .filter(
          (matchItemHitId) => matchItemHitActions[matchItemHitId] === undefined
        )
    );
    if (!toBeHydratedMatchItemHitIds.length) {
      return;
    }
    setMatchItemHitActions((matchItemHitActions) => {
      const newMatchItemHitActions = {
        ...matchItemHitActions,
      };
      toBeHydratedMatchItemHitIds.forEach((toBeHydratedMatchItemId) => {
        newMatchItemHitActions[toBeHydratedMatchItemId] = null;
      });
      return newMatchItemHitActions;
    });
    Promise.all(
      chunk(toBeHydratedMatchItemHitIds, 50).map(
        (groupedToBeHydratedMatchItemHitIds: string[]) =>
          axios.request<components["schemas"]["MatchItemHitAction"][]>({
            method: "get",
            url: `/matchItemHitAction/crud`,
            params: {
              matchItemHitIds: groupedToBeHydratedMatchItemHitIds.join(","),
            },
          })
      )
    ).then((responses) => {
      const mergedResponses = flatten(
        responses.map((response) => response.data)
      );
      setMatchItemHitActions((matchItemHitActions) => {
        const newMatchItemHitActions = {
          ...matchItemHitActions,
        };
        toBeHydratedMatchItemHitIds.forEach((toBeHydratedMatchItemHitId) => {
          newMatchItemHitActions[toBeHydratedMatchItemHitId] =
            mergedResponses.filter(
              (action) =>
                action.requestedMatchItemHitId === toBeHydratedMatchItemHitId
            );
        });
        return newMatchItemHitActions;
      });
    });
  }, [axios, hydrateMatchItem, matchItemHitActions, matchItemHits]);

  const { userRoles } = auth?.jwt as components["schemas"]["Jwt"];

  React.useEffect(() => {
    const params = pick(
      parseLocationSearch(location.search),
      openapi.paths["/matchItemHit/crud"].get.parameters.map(
        (parameter) => parameter.name
      )
    );
    if (!isValid(params)) {
      setMatchItemHits((matchItemHits) =>
        matchItemHits ? undefined : matchItemHits
      );
      return;
    }
    setMatchItemHits(null);
    axios
      .request<components["schemas"]["MatchItemHit"][]>({
        method: "get",
        url: `/matchItemHit/crud`,
        params,
      })
      .then((res) => {
        setMatchItemHits(res.data);
        return;
      })
      .catch((err) => {
        setMatchItemHits(undefined);
        toaster.show({
          message: `Laden lees-resultaten mislukt: ${err.message}`,
          intent: Intent.DANGER,
        });
      });
  }, [axios, location.search]);

  const matchItemHitCrudParams = React.useMemo(
    () =>
      pick<{
        isOpen?: "true";
        isOnHold?: "true";
        isInProgress?: "true";
        isError?: "true";
        hasComment?: "true";
        publicationId?: string;
        publicationDate?: string;
        articleId?: string;
        url?: string;
      }>(
        parseLocationSearch(location.search),
        openapi.paths["/matchItemHit/crud"].get.parameters.map(
          (parameter) => parameter.name
        )
      ),
    [location.search]
  );

  const statusValue = React.useMemo(() => {
    if (matchItemHitCrudParams.isOpen) {
      return "open";
    }
    if (matchItemHitCrudParams.isOnHold) {
      return "onHold";
    }
    if (matchItemHitCrudParams.hasComment) {
      return "comment";
    }
    if (matchItemHitCrudParams.isInProgress) {
      return "isInProgress";
    }
    if (matchItemHitCrudParams.isError) {
      return "isError";
    }
    return "all";
  }, [matchItemHitCrudParams]);

  const finish = React.useCallback(
    (
      finishMatchItemHit: components["schemas"]["MatchItemHit"]
    ): Promise<void> =>
      axios
        .request({
          method: "delete",
          url: `/matchItemHit/crud/${finishMatchItemHit.matchItemHitId}`,
        })
        .then(() => {
          // @ts-ignore
          setMatchItemHits((matchItemHits) =>
            matchItemHits
              ? (matchItemHits.filter(
                  // @ts-ignore
                  (matchItemHit) =>
                    matchItemHit.matchItemHitId !==
                    finishMatchItemHit.matchItemHitId
                ) as components["schemas"]["MatchItemHit"][])
              : matchItemHits
          );
        }),
    [axios, setMatchItemHits]
  );

  const hasFinishableMatchItemHit = React.useMemo<boolean>(
    () =>
      matchItemHits
        ? !!matchItemHits.find((hit) =>
            isFinishable(hit, matchItemHitActions[hit.matchItemHitId] || [])
          )
        : false,
    [matchItemHitActions, matchItemHits]
  );

  const config = React.useMemo<{
    [propName: string]: IColumnConfig<components["schemas"]["MatchItemHit"]>;
  }>(
    () => ({
      matchItemId: {
        order: -90,
        width: 250,
        title: "Artikel",
        renderData: (matchItemHit: components["schemas"]["MatchItemHit"]) => {
          const matchItem = matchItemMap
            ? matchItemMap[matchItemHit.matchItemId]
            : null;
          return matchItem ? getName(matchItem) : "-";
        },
      },
      clipTitle: {
        order: -80,
        width: 250,
        title: "Titel",
        renderData: (matchItemHit: components["schemas"]["MatchItemHit"]) => {
          const matchItem = matchItemMap[matchItemHit.matchItemId];
          return matchItem?.clipTitle || "-";
        },
      },
      searchTopicId: {
        order: -70,
        width: 250,
        title: "Searchtopic",
        renderData: (matchItemHit: components["schemas"]["MatchItemHit"]) => {
          const searchTopic =
            searchTopicMap && matchItemHit.searchTopicId
              ? searchTopicMap[matchItemHit.searchTopicId]
              : null;
          return searchTopic?.description || "...";
        },
      },
      matchItemHitId: {
        order: -60,
        title: "Actie(s) door",
        renderData: (matchItemHit: components["schemas"]["MatchItemHit"]) => {
          return (matchItemHitActions[matchItemHit.matchItemHitId] || [])
            .map((action) => action.userName)
            .join(", ");
        },
      },
      actions: {
        order: -50,
        title: "Actie(s) op",
        renderData: (matchItemHit: components["schemas"]["MatchItemHit"]) => {
          return (matchItemHitActions[matchItemHit.matchItemHitId] || [])
            .map((action) =>
              format(new Date(action.startDateTime), "dd-MM HH:mm")
            )
            .join(", ");
        },
      },
      monitoringPlusResult: {
        order: -40,
        title: "Opmerkingen",
        renderData: (matchItemHit: components["schemas"]["MatchItemHit"]) => {
          return (matchItemHitActions[matchItemHit.matchItemHitId] || [])
            .map((action) => action.comment)
            .filter((comment) => !!comment)
            .join(", ");
        },
      },
      "-": {
        order: -30,
        width: 100,
        title: "-",
        renderCell: (matchItemHit: components["schemas"]["MatchItemHit"]) => {
          const hitActions =
            matchItemHitActions[matchItemHit.matchItemHitId] || [];
          const checkVersion = hitActions.find((action) => action.version > 0);
          let status = checkVersion ? "Checken" : "Lezen";
          if (!matchItemHit.searchTopicId) {
            status = "Headlining";
          }

          let manageVersion = 0;
          if (matchItemHit.matchItemHitStatus === "hold") {
            const holdAction = hitActions.find(
              (action) =>
                action.matchItemHitActionResult === "skipped" && action.comment
            );
            if (holdAction) {
              manageVersion = holdAction.version;
            }
          }

          return matchItemHit.matchItemHitStatus === "processing" ? (
            <span>{status}</span>
          ) : (
            <Link
              to={{
                pathname: `/manage/${matchItemHit.matchItemHitId}/${manageVersion}`,
              }}
              state={{
                returnUrl: "/matchItemHits",
              }}
            >
              <Button intent={Intent.PRIMARY} text="Bekijken" small={true} />
            </Link>
          );
        },
      },
      mediaType: {
        hidden: true,
      },
      readOnly: {
        hidden: true,
      },
      searchTerms: {
        hidden: true,
      },
      seenByAdmin: {
        hidden: true,
      },
      matchItemHitStatus: {
        order: 999,
        width: 150,
        title:
          matchItemHits && hasFinishableMatchItemHit ? (
            <Button
              onClick={() => {
                Promise.all(matchItemHits.map(finish)).then(() => {
                  window.location.reload();
                });
              }}
              intent={Intent.DANGER}
              text="Alles gereed"
              small={true}
            />
          ) : (
            "-"
          ),
        renderCell: (matchItemHit) => {
          // once this cell is rendered, make sure the matchItem data is available...
          hydrateMatchItem(matchItemHit.matchItemId);
          return isFinishable(
            matchItemHit,
            matchItemHitActions[matchItemHit.matchItemHitId] || []
          ) ? (
            <Button
              onClick={() => {
                const isLast = matchItemHits && matchItemHits.length === 1;
                finish(matchItemHit).then(() => {
                  if (isLast) {
                    window.location.reload();
                  }
                });
              }}
              intent={Intent.DANGER}
              text="Gereed melden"
              small={true}
            />
          ) : null;
        },
      },
    }),
    [
      finish,
      hasFinishableMatchItemHit,
      hydrateMatchItem,
      matchItemHitActions,
      matchItemHits,
      matchItemMap,
      searchTopicMap,
    ]
  );

  const getRowClassName = React.useCallback(
    (matchItemHit: components["schemas"]["MatchItemHit"]) =>
      `routes__match-item-hits__row--${getStoredMatchItemHitActionResult(
        matchItemHit,
        matchItemHitActions[matchItemHit.matchItemHitId] || []
      )}`,
    [matchItemHitActions]
  );

  const DatePickerButton = React.forwardRef((props: ButtonProps, ref) => {
    return <Button {...props} ref={ref as any} />;
  });

  if (!searchTopicMap) {
    return <CenteredSpinner />;
  }

  const isPowerUser =
    intersection(userRoles, ["admin", "powerUser"]).length > 0;

  if (!isPowerUser) {
    return (
      <Callout title="Pagina niet beschikbaar" intent={Intent.WARNING}>
        <p>U heeft niet voldoende rechten voor het openen van deze pagina.</p>
      </Callout>
    );
  }

  return (
    <div>
      <form
        style={{ display: "flex", alignItems: "flex-end" }}
        onSubmit={(e) => {
          e.preventDefault();
          window.location.reload();
        }}
      >
        <FormGroup
          label="Status leesresultaat"
          labelFor="status"
          style={{ margin: 8, padding: 8, flex: 1, border: "1px solid white" }}
        >
          <div className="bp5-select" style={{ display: "block" }}>
            <select
              disabled={matchItemHits === null}
              name="status"
              id="status"
              value={statusValue}
              onChange={(e: React.FormEvent<HTMLSelectElement>) => {
                switch (e.currentTarget.value) {
                  case "all":
                    navigate({ pathname: "/matchItemHits", search: "?" });
                    break;

                  case "open":
                    navigate({
                      pathname: "/matchItemHits",
                      search: serializeLocationSearch({ isOpen: true }),
                    });
                    break;

                  case "onHold":
                    navigate({
                      pathname: "/matchItemHits",
                      search: serializeLocationSearch({ isOnHold: true }),
                    });
                    break;

                  case "comment":
                    navigate({
                      pathname: "/matchItemHits",
                      search: serializeLocationSearch({ hasComment: true }),
                    });
                    break;

                  case "isInProgress":
                    navigate({
                      pathname: "/matchItemHits",
                      search: serializeLocationSearch({ isInProgress: true }),
                    });
                    break;

                  case "isError":
                    navigate({
                      pathname: "/matchItemHits",
                      search: serializeLocationSearch({ isError: true }),
                    });
                    break;
                }
              }}
            >
              <option value="all">Alle</option>
              <option value="open">Open</option>
              <option value="onHold">Hold</option>
              <option value="comment">Met opmerking</option>
              <option value="isInProgress">In behandeling</option>
              <option value="isError">Met foutmelding</option>
            </select>
          </div>
        </FormGroup>
        <FormGroup
          style={{ margin: 8, padding: 8, flex: 1, border: "1px solid white" }}
          label="Publicatie"
          labelFor="publicationId"
          labelInfo="Geef zowel het publicatieID als de datum"
          disabled={
            !!(
              matchItemHits === null ||
              matchItemHitCrudParams.isError ||
              matchItemHitCrudParams.isInProgress ||
              matchItemHitCrudParams.isOnHold ||
              matchItemHitCrudParams.hasComment
            )
          }
        >
          <InputGroup
            id="publicationId"
            placeholder="Publicatie ID"
            type="number"
            disabled={
              !!(
                matchItemHitCrudParams.isError ||
                matchItemHitCrudParams.isInProgress ||
                matchItemHitCrudParams.isOnHold ||
                matchItemHitCrudParams.hasComment
              )
            }
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              navigate({
                pathname: "/matchItemHits",
                search: serializeLocationSearch({
                  ...matchItemHitCrudParams,
                  publicationId: parseInt(e.currentTarget.value, 10),
                }),
              });
            }}
            value={
              matchItemHitCrudParams.publicationId
                ? `${matchItemHitCrudParams.publicationId}`
                : ""
            }
            min={1}
            step={1}
            rightElement={
              <DatePicker
                selected={
                  matchItemHitCrudParams.publicationDate
                    ? new Date(matchItemHitCrudParams.publicationDate)
                    : undefined
                }
                maxDate={addYears(new Date(), 5)}
                onChange={(date) => {
                  navigate({
                    pathname: "/matchItemHits",
                    search: serializeLocationSearch({
                      ...matchItemHitCrudParams,
                      publicationDate: date
                        ? format(date, "yyyy-MM-dd")
                        : undefined,
                    }),
                  });
                }}
                customInput={
                  <DatePickerButton
                    style={{
                      marginRight: matchItemHitCrudParams.publicationDate
                        ? 28
                        : 0,
                    }}
                    disabled={
                      !!(
                        matchItemHitCrudParams.isError ||
                        matchItemHitCrudParams.isInProgress ||
                        matchItemHitCrudParams.isOnHold ||
                        matchItemHitCrudParams.hasComment
                      )
                    }
                    text={
                      matchItemHitCrudParams.publicationDate
                        ? format(
                            new Date(matchItemHitCrudParams.publicationDate),
                            "dd-MM-yyyy"
                          )
                        : "Kies een datum"
                    }
                  />
                }
                isClearable
              />
            }
          />
        </FormGroup>
        <FormGroup
          style={{ margin: 8, padding: 8, flex: 1, border: "1px solid white" }}
          label="Artikelnummer"
          labelFor="articleId"
          disabled={
            !!(
              matchItemHitCrudParams.isError ||
              matchItemHitCrudParams.isInProgress ||
              matchItemHitCrudParams.isOnHold ||
              matchItemHitCrudParams.isOpen ||
              matchItemHitCrudParams.hasComment
            )
          }
        >
          <InputGroup
            id="articleId"
            disabled={
              !!(
                matchItemHitCrudParams.isError ||
                matchItemHitCrudParams.isInProgress ||
                matchItemHitCrudParams.isOnHold ||
                matchItemHitCrudParams.isOpen ||
                matchItemHitCrudParams.hasComment
              )
            }
            placeholder="Artikelnummer"
            type="number"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              navigate({
                pathname: "/matchItemHits",
                search: serializeLocationSearch({
                  articleId: parseInt(e.currentTarget.value, 10),
                }),
              });
            }}
            value={
              matchItemHitCrudParams.articleId
                ? `${matchItemHitCrudParams.articleId}`
                : ""
            }
            min={1}
            step={1}
          />
        </FormGroup>
        <FormGroup
          style={{ margin: 8, padding: 8, flex: 1, border: "1px solid white" }}
          label="URL"
          labelFor="url"
          disabled={
            !!(
              matchItemHits === null ||
              matchItemHitCrudParams.isError ||
              matchItemHitCrudParams.isInProgress ||
              matchItemHitCrudParams.isOnHold ||
              matchItemHitCrudParams.isOpen ||
              matchItemHitCrudParams.hasComment
            )
          }
        >
          <InputGroup
            id="url"
            disabled={
              !!(
                matchItemHitCrudParams.isError ||
                matchItemHitCrudParams.isInProgress ||
                matchItemHitCrudParams.isOnHold ||
                matchItemHitCrudParams.isOpen ||
                matchItemHitCrudParams.hasComment
              )
            }
            placeholder="https://"
            type="url"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              navigate({
                pathname: "/matchItemHits",
                search: serializeLocationSearch({
                  url: e.currentTarget.value,
                }),
              });
            }}
            value={matchItemHitCrudParams.url || ""}
            min={1}
            step={1}
          />
        </FormGroup>
        <div style={{ margin: 8, padding: 8 }}>
          <Button
            type="submit"
            intent={Intent.PRIMARY}
            text="Zoek"
            disabled={matchItemHits === null}
          />
        </div>
      </form>
      {matchItemHits === null ? (
        <div style={{ marginTop: 40 }}>
          <CenteredSpinner />
        </div>
      ) : null}
      {matchItemHits ? (
        <SchemaTable
          data={matchItemHits}
          isFullScreen
          config={config}
          getRowClassName={getRowClassName}
          schema={
            dereferencedOpenApi.components.schemas.MatchItemHit as SchemaObject
          }
        />
      ) : null}
    </div>
  );
};

export default MatchItemHits;
