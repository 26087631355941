import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import ApiDataContextProvider from "./provider/apiData";
import LayoutContext from "./provider/layout";
import { KunciProvider } from "./inc/kunci";
import { WebSocketProvider } from "./provider/webSocket";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "react-mosaic-component/react-mosaic-component.css";
import "react-datepicker/dist/react-datepicker.css";
import "./index.scss";

const { NODE_ENV } = process.env;

if (NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://e871312b97e047d1b3050b562a171f40@o1065075.ingest.sentry.io/6532911",
  });
}

ReactDOM.render(
  <KunciProvider>
    <WebSocketProvider>
      <ApiDataContextProvider>
        <LayoutContext>
          <App />
        </LayoutContext>
      </ApiDataContextProvider>
    </WebSocketProvider>
  </KunciProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
