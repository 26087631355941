import React from "react";
import { KunciContext } from "../../inc/kunci";
import { MosaicWindow } from "react-mosaic-component";
import { components } from "../../types/openapi";
import CenteredSpinner from "../../components/CenteredSpinner";
import { matchItemHitWindowComponents } from "../../components/mosaicWindows";
import toaster from "../../inc/toaster";
import { Intent } from "@blueprintjs/core/lib/esnext";
import { ApiDataContext } from "../../provider/apiData";
import { KeyboardActions } from "../../inc/keyboard";
import { useNavigate, useParams } from "react-router-dom";
import useDeleteRequests from "../../hooks/useDeleteRequests";
import SpeedReaderMosaic from "../../components/SpeedReaderMosaic";

const DeleteRequest = () => {
  const { auth, axios } = React.useContext(KunciContext);
  const { claimHitById, setDeleteRequests } = React.useContext(ApiDataContext);
  const deleteRequests = useDeleteRequests();
  const params = useParams<{ deleteRequestId: string }>();
  const deleteRequest =
    deleteRequests && params.deleteRequestId
      ? deleteRequests[params.deleteRequestId]
      : undefined;
  const navigate = useNavigate();

  const processDeleteRequest = React.useCallback(
    (
      deleteRequestStatus: components["schemas"]["DeleteRequest"]["deleteRequestStatus"],
      rejectReason?: string
    ) => {
      if (!deleteRequest) {
        return;
      }
      axios
        .request<components["schemas"]["DeleteRequest"]>({
          method: "put",
          url: `/deleteRequest/crud/${deleteRequest.deleteRequestId}`,
          data: {
            ...deleteRequest,
            deleteRequestStatus,
            rejectReason,
          },
        })
        .then((res) => {
          const updatedDeleteRequest = res.data;
          setDeleteRequests((deleteRequests) => ({
            ...deleteRequests,
            [updatedDeleteRequest.deleteRequestId]: updatedDeleteRequest,
          }));
          toaster.show({
            message: "Verwijderverzoek opgeslagen",
            intent: Intent.SUCCESS,
          });
          navigate("/deleteRequests");
          return;
        })
        .catch((err) => {
          toaster.show({
            message: `Verwijderverzoek opslaan mislukt: ${err.message}`,
            intent: Intent.DANGER,
          });
          navigate("/deleteRequests");
        });
    },

    [axios, deleteRequest, navigate, setDeleteRequests]
  );

  const doKeyboardAction = React.useCallback(
    (actionId: keyof typeof KeyboardActions) => {
      switch (actionId) {
        case KeyboardActions.HIT_APPROVE:
          processDeleteRequest("creditApproved");
          break;

        case KeyboardActions.HIT_REJECT:
          processDeleteRequest("creditRejected");
          break;
      }
    },
    [processDeleteRequest]
  );

  React.useEffect(() => {
    if (deleteRequest?.matchItemHitId) {
      claimHitById(deleteRequest?.matchItemHitId);
    }
  }, [claimHitById, deleteRequest?.matchItemHitId]);

  if (!auth) {
    return <CenteredSpinner />;
  }
  const { userRoles } = auth.jwt as components["schemas"]["Jwt"];
  const isDeleteRequest = userRoles.indexOf("deleteRequestUser") >= 0;

  if (!isDeleteRequest) {
    return <div>Geen toegang</div>;
  }

  return (
    <SpeedReaderMosaic
      appDataNodeName="deleteRequestNode"
      doKeyboardAction={doKeyboardAction}
    >
      {(matchItemHitWindowType, path) => {
        const HitWindowComponent =
          matchItemHitWindowComponents[matchItemHitWindowType];
        return (
          <MosaicWindow<number>
            title={"-"}
            path={path}
            toolbarControls={[]}
            draggable={false}
            className={`mosaic-window--type${matchItemHitWindowType}`}
          >
            <HitWindowComponent processDeleteRequest={processDeleteRequest} />
          </MosaicWindow>
        );
      }}
    </SpeedReaderMosaic>
  );
};

export default DeleteRequest;
