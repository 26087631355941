import React from "react";
import { Route, Routes } from "react-router-dom";
import { Classes } from "@blueprintjs/core";
import SpeedreaderNavBar from "./components/SpeedreaderNavBar";
import Dashboard from "./routes/Dashboard";
import ReadMatchItemHit from "./routes/ReadMatchItemHit";
import Profile from "./routes/Profile";
import Users from "./routes/Users";
import User from "./routes/User";
import CheckPreferences from "./routes/CheckPreferences";
import CheckPreference from "./routes/CheckPreference";
import CenteredSpinner from "./components/CenteredSpinner";
import MatchItemHits from "./routes/MatchItemHits";
import useProfile from "./hooks/useProfile";
import MonitoringPlus from "./routes/MonitoringPlus";
import "./App.scss";
import "react-18-image-lightbox/style.css";
import DeleteRequests from "./routes/DeleteRequests";
import DeleteRequest from "./routes/DeleteRequest";
import ManageMatchItemHit from "./routes/ManageMatchItemHit";
import MonitoringPlusMatchItemHits from "./routes/MonitoringPlusMatchItemHits";
import {
  ChatProvider,
  MigChatWebSocketProvider,
  MigChatKunciProvider,
  IMigProviderConfig,
} from "@mediainfogroep/mig-chat-app";
import { channelList } from "./inc/constants";
import Chat from "./routes/Chat";
import SearchTopics from "./routes/SearchTopics";

const App = () => {
  const profile = useProfile();
  const chatProviderConfig: IMigProviderConfig = React.useMemo(
    () => ({
      dialogMode: true,
      propChatRoomIds: channelList,
    }),
    []
  );

  if (!profile) {
    return <CenteredSpinner />;
  }

  return (
    <MigChatKunciProvider>
      <MigChatWebSocketProvider>
        <ChatProvider config={chatProviderConfig}>
          <div
            className={`app mosaic-blueprint-theme ${
              profile.appData.theme.isDark ? Classes.DARK : ""
            } app--font-${profile.appData.theme.font} app--font-size-${
              profile.appData.theme.fontSize
            }`}
            style={{ display: "flex" }}
          >
            <SpeedreaderNavBar />
            <div
              style={{
                backgroundColor: profile.appData.theme.isDark
                  ? "#293742"
                  : "#fafafa",
                overflow: "auto",
                flex: 1,
              }}
            >
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/manage/:matchItemHitId">
                  <Route
                    path="/manage/:matchItemHitId/:version"
                    element={<ManageMatchItemHit />}
                  />
                  <Route index element={<ManageMatchItemHit />} />
                </Route>
                <Route
                  path="/read/:matchItemHitId"
                  element={<ReadMatchItemHit />}
                />
                <Route path="/users" element={<Users />} />
                <Route path="/user/:userId" element={<User />} />
                <Route
                  path="/checkPreferences"
                  element={<CheckPreferences />}
                />
                <Route
                  path="/checkPreference/:checkPreferenceId"
                  element={<CheckPreference />}
                />
                <Route path="/matchItemHits" element={<MatchItemHits />} />
                <Route path="/monitoringPlus" element={<MonitoringPlus />} />
                <Route
                  path="/monitoringPlusMatchItemHits"
                  element={<MonitoringPlusMatchItemHits />}
                />
                <Route path="/deleteRequests" element={<DeleteRequests />} />
                <Route
                  path="/deleteRequest/:deleteRequestId"
                  element={<DeleteRequest />}
                />
                <Route path="/searchTopics" element={<SearchTopics />} />
              </Routes>
            </div>
          </div>
          <Chat />
        </ChatProvider>
      </MigChatWebSocketProvider>
    </MigChatKunciProvider>
  );
};

export default App;
