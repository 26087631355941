import * as React from "react";
import { ApiDataContext } from "../../../provider/apiData";
import ReclipPage from "./ReclipPage";
import {
  Button,
  Classes,
  Dialog,
  FormGroup,
  Spinner,
  TextArea,
} from "@blueprintjs/core";
import { Intent } from "@blueprintjs/core/lib/esnext";
import toaster from "../../../inc/toaster";
import { components } from "../../../types/openapi";
import { useNavigate } from "react-router-dom";
import { KunciContext } from "../../../inc/kunci";
import { usePublicationIssue } from "../../../hooks/usePublicationIssue";

const ReclipView = () => {
  const {
    currentClaimedMatchItemHit,
    matchItemMap,
    setCurrentClaimedMatchItemHit,
    profile,
  } = React.useContext(ApiDataContext);
  const { axios } = React.useContext(KunciContext);
  const navigate = useNavigate();
  const [selectedPageIds, setSelectedPageIds] = React.useState<string[]>([]);
  const [pageCorrectionHash, setPageCorrectionHash] = React.useState<{
    [pageId: string]: components["schemas"]["PageCorrection"] | null;
  }>({});
  const [comment1, setComment1] = React.useState<string>("");
  const [comment2, setComment2] = React.useState<string>("");
  const [isPending, setIsPending] = React.useState<boolean>(false);
  const [isCommentDialogOpen, setIsCommentDialogOpen] =
    React.useState<boolean>(false);
  const matchItem =
    matchItemMap && currentClaimedMatchItemHit
      ? matchItemMap[currentClaimedMatchItemHit.hit.matchItemId]
      : undefined;
  const publicationIssue = usePublicationIssue(matchItem?.publicationIssueId);

  if (!publicationIssue || !currentClaimedMatchItemHit || isPending) {
    return <Spinner />;
  }
  const { pages = [] } = publicationIssue;
  const pageCorrections = Object.values(pageCorrectionHash).filter(
    (hash) => !!hash
  );
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          padding: 8,
        }}
      >
        {pages.map((page) => {
          const checked = selectedPageIds.indexOf(page.pageId) >= 0;
          return (
            <label
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: 16,
              }}
              key={page.pageId}
            >
              <img
                src={`${page.url}?size=8`}
                style={{ maxWidth: 100 }}
                alt={`Pagina ${page.pageSequence}`}
              />
              {page.pageSequence}{" "}
              <input
                type="checkbox"
                onChange={() => {
                  setSelectedPageIds((selectedPageIds) =>
                    checked
                      ? selectedPageIds.filter(
                          (pageId) => pageId !== page.pageId
                        )
                      : [...selectedPageIds, page.pageId]
                  );
                }}
                checked={checked}
              />
            </label>
          );
        })}
      </div>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {selectedPageIds.map((pageId) => (
          <ReclipPage
            page={
              pages.find(
                (page) => page.pageId === pageId
              ) as components["schemas"]["Page"]
            }
            onChange={(pageCorrection) => {
              setPageCorrectionHash((pageCorrectionHash) => ({
                ...pageCorrectionHash,
                [pageId]: pageCorrection,
              }));
            }}
            key={pageId}
          />
        ))}
      </div>
      <Button
        intent={Intent.PRIMARY}
        large
        style={{ margin: 40 }}
        disabled={
          selectedPageIds.length < 1 ||
          pageCorrections.length !== selectedPageIds.length
        }
        onClick={() => {
          setIsCommentDialogOpen(true);
        }}
      >
        Knipsel correctie insturen
      </Button>
      <Button
        intent={Intent.DANGER}
        large
        style={{ margin: 40 }}
        onClick={() => {
          if (!profile) {
            return;
          }
          setCurrentClaimedMatchItemHit({
            ...currentClaimedMatchItemHit,
            actions: currentClaimedMatchItemHit.actions.map((action) =>
              action.userName === profile.userName
                ? {
                    ...action,
                    matchItemHitActionResult: "none",
                  }
                : action
            ),
          });
        }}
      >
        Knipsel correctie annuleren
      </Button>
      <Dialog
        icon="info-sign"
        onClose={() => setIsCommentDialogOpen(false)}
        title="Knipsel correctie"
        isOpen={isCommentDialogOpen}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            const comment = `${comment1}\n${comment2}`.trim();
            if (comment.length < 3) {
              window.alert("Een opmerking is verplicht.");
              return;
            }
            setIsPending(true);
            axios
              .request({
                method: "post",
                url: "/correction/crud",
                data: {
                  comment,
                  matchItemHitId: currentClaimedMatchItemHit.hit.matchItemHitId,
                  pageCorrections,
                },
              })
              .then(() => {
                navigate("/", { replace: true });
              })
              .catch((err) => {
                setIsPending(false);
                toaster.show({
                  message: `Opslaan mislukt: ${err.message}`,
                  intent: Intent.DANGER,
                });
              });
          }}
        >
          <div className={Classes.DIALOG_BODY}>
            <FormGroup label="Opmerking" labelFor="comment">
              <div className="bp5-html-select bp5-fill">
                <select
                  onChange={(e) => {
                    setComment1(e.target.value);
                  }}
                  value={comment1}
                >
                  <option value="">Maak een keuze...</option>
                  <option>Bij elkaar geknipt moet los</option>
                  <option>Los genipt moet bij elkaar</option>
                  <option>Fout geknipt mist pagina’s</option>
                  <option>Tekst in de verkeerde volgorde geknipt</option>
                  <option>Foto vergeten te knippen</option>
                  <option>Verkeerde foto bij het artikel</option>
                  <option>Anders, namelijk:</option>
                </select>
                <span className="bp5-icon bp5-icon-double-caret-vertical" />
              </div>
            </FormGroup>
            <FormGroup label="Opmerking" labelFor="comment">
              <TextArea
                id="comment"
                large={true}
                style={{ display: "block", width: "100%", height: 80 }}
                intent={Intent.PRIMARY}
                onChange={(e) => {
                  setComment2(e.target.value);
                }}
                value={comment2}
                autoFocus={true}
              />
            </FormGroup>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button intent={Intent.PRIMARY} type="submit">
                Versturen
              </Button>
            </div>
          </div>
        </form>
      </Dialog>
    </>
  );
};

export default ReclipView;
