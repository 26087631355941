import actions from "./actions.json";
import { t } from "../i18n";
import { TUserProfile } from "../../types/extendedOpenapi";

export enum KeyboardActions {
  HIT_APPROVE = "HIT_APPROVE",
  HIT_REJECT = "HIT_REJECT",
  HIT_SKIP = "HIT_SKIP",
  HIT_START_COMMENT = "HIT_START_COMMENT",
  HIT_ADD_SEARCHTOPIC = "HIT_ADD_SEARCHTOPIC",
  HIT_MARK_LAST = "HIT_MARK_LAST",
  MONITORING_PLUS_PREVIOUS_HIT = "MONITORING_PLUS_PREVIOUS_HIT",
  MONITORING_PLUS_NEXT_HIT = "MONITORING_PLUS_NEXT_HIT",
}

export function getKeys(e: KeyboardEvent) {
  const keys = [e.keyCode];
  if (e.altKey) {
    keys.unshift(18);
  }
  if (e.ctrlKey) {
    keys.unshift(17);
  }
  if (e.shiftKey) {
    keys.unshift(16);
  }
  return keys;
}

export function getActionKeyLabelSuffix(
  profile: TUserProfile,
  actionId: keyof typeof KeyboardActions
): string {
  const keyBindings = getKeyBindings(profile);
  return ` (${keyBindings[actionId]
    .map((key) => t(`KEY_${key}`))
    .join(" + ")})`;
}

export function getKeyBindings(user: TUserProfile) {
  return Object.keys(KeyboardActions).reduce(
    (prev, actionId) => {
      if (!prev[actionId]) {
        prev[actionId] =
          actions[actionId as keyof typeof KeyboardActions].defaultKeys;
      }
      return prev;
    },
    { ...user.appData.keymap }
  );
}
