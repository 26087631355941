import * as React from "react";
import { Mosaic, MosaicNode } from "react-mosaic-component";
import { Classes } from "@blueprintjs/core";
import { ApiDataContext } from "../../provider/apiData";
import axios from "axios";
import CenteredSpinner from "../../components/CenteredSpinner";
import { useDebouncedCallback } from "use-debounce";
import toaster from "../../inc/toaster";
import { Intent } from "@blueprintjs/core/lib/esnext";
import { TileRenderer } from "react-mosaic-component/lib/types";
import { TUserProfile } from "../../types/extendedOpenapi";
import { getKeyBindings, getKeys, KeyboardActions } from "../../inc/keyboard";
import useProfile from "../../hooks/useProfile";
import { LayoutContext } from "../../provider/layout";

import "./index.scss";

interface ISpeedReaderMosaicProps {
  appDataNodeName:
    | "forcedTitleCheckNode"
    | "readHitNode"
    | "monitoringPlusNode"
    | "deleteRequestNode"
    | "manageHitNode";
  children: TileRenderer<number>;
  className?: string;
  doKeyboardAction: (actionId: keyof typeof KeyboardActions) => void;
}

const SpeedReaderMosaic = ({
  appDataNodeName,
  children,
  className,
  doKeyboardAction,
}: ISpeedReaderMosaicProps) => {
  const { setProfile } = React.useContext(ApiDataContext);
  const { setIsCommentOpen, setIsSearchTopicDialogOpen } =
    React.useContext(LayoutContext);
  const profile = useProfile();

  React.useEffect(() => {
    if (!profile) {
      return;
    }
    const keyBindings = getKeyBindings(profile);
    const keysIdToActionMap = Object.keys(keyBindings).reduce<{
      [keysId: string]: keyof typeof KeyboardActions;
    }>((prev, actionId) => {
      prev[keyBindings[actionId].join("_")] =
        actionId as keyof typeof KeyboardActions;
      return prev;
    }, {});

    const keydownHandler = (e: KeyboardEvent) => {
      // do not handle typing in an input or textarea
      if (e.target === window.document.body) {
        if (e.key === "Escape") {
          setIsCommentOpen(false);
          setIsSearchTopicDialogOpen(false);
          return;
        }

        const keysId = getKeys(e).join("_");
        const action = keysIdToActionMap[keysId];
        if (action) {
          e.preventDefault();
          doKeyboardAction(action);
        }
      }
    };
    document.addEventListener("keydown", keydownHandler);
    return () => {
      document.removeEventListener("keydown", keydownHandler);
    };
  }, [doKeyboardAction, profile, setIsCommentOpen, setIsSearchTopicDialogOpen]);

  const [debouncedProfileUpdate] = useDebouncedCallback(
    (profile: TUserProfile) => {
      axios.put(`/user/profile`, profile).catch((err) => {
        toaster.show({
          message: `Opslaan gebruikersprofiel mislukt: ${err.message}`,
          intent: Intent.DANGER,
        });
      });
    },
    1000
  );

  if (!profile) {
    return <CenteredSpinner />;
  }

  return (
    <Mosaic<number>
      resize={{ minimumPaneSizePercentage: 1 }}
      className={`mosaic-blueprint-theme${className ? ` ${className}` : ""} ${
        profile.appData.theme.isDark ? Classes.DARK : ""
      }`}
      renderTile={children}
      value={profile.appData[appDataNodeName]}
      onChange={(node: MosaicNode<number> | null) => {
        if (node) {
          const updatedProfile = {
            ...profile,
            appData: {
              ...profile.appData,
              [appDataNodeName]: node,
            },
          };
          setProfile(updatedProfile);
          debouncedProfileUpdate(updatedProfile);
        }
      }}
    />
  );
};

export default SpeedReaderMosaic;
