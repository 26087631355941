import { Intent } from "@blueprintjs/core";
import { KunciContext } from "../inc/kunci";
import React from "react";
import { ApiDataContext, IHashMap } from "../provider/apiData";
import toaster from "../inc/toaster";
import { components } from "../types/openapi";

const useCheckPreferences = () => {
  const { checkPreferences, setCheckPreferences } =
    React.useContext(ApiDataContext);
  const { axios } = React.useContext(KunciContext);

  React.useEffect(() => {
    if (checkPreferences !== undefined) {
      return;
    }

    setCheckPreferences(null);
    axios
      .get<components["schemas"]["CheckPreference"][]>("/checkPreference/crud")
      .then((res) => {
        setCheckPreferences(
          res.data.reduce<IHashMap<components["schemas"]["CheckPreference"]>>(
            (prev, checkPreference) => {
              prev[checkPreference.checkPreferenceId as string] =
                checkPreference;
              return prev;
            },
            {}
          )
        );
      })
      .catch((err) => {
        toaster.show({
          message: `Laden leesvoorkeuren mislukt: ${err.message}`,
          intent: Intent.DANGER,
        });
      });
  }, [axios, setCheckPreferences, checkPreferences]);

  return checkPreferences;
};

export default useCheckPreferences;
