import React from "react";
import { components } from "../types/openapi";
import { ApiDataContext } from "../provider/apiData";
import toaster from "../inc/toaster";
import { Intent } from "@blueprintjs/core";
import { KunciContext } from "../inc/kunci";

const useMatchItemMap = (requiredMatchItemId?: string) => {
  const { matchItemMap, setMatchItemMap } = React.useContext(ApiDataContext);
  const { axios } = React.useContext(KunciContext);

  const hydrateMatchItem = React.useCallback(
    (toBeHydratedMatchItemId: string): Promise<void> => {
      return new Promise((resolve, reject) => {
        if (matchItemMap[toBeHydratedMatchItemId] !== undefined) {
          resolve();
          return;
        }
        setMatchItemMap((matchItemMap) => {
          if (matchItemMap[toBeHydratedMatchItemId] !== undefined) {
            resolve();
            return matchItemMap;
          }

          axios
            .request<components["schemas"]["MatchItem"]>({
              method: "get",
              url: `/matchItem/crud/${toBeHydratedMatchItemId}`,
            })
            .then((matchItemResponse) => {
              setMatchItemMap((matchItemMap) => ({
                ...matchItemMap,
                [matchItemResponse.data.matchItemId]: matchItemResponse.data,
              }));
              resolve();
            })
            .catch((err) => {
              toaster.show({
                message: `Laden artikel mislukt: ${err.message}`,
                intent: Intent.DANGER,
              });
              reject(err);
            });
          return {
            ...matchItemMap,
            [toBeHydratedMatchItemId]: null,
          };
        });
      });
    },
    [axios, matchItemMap, setMatchItemMap]
  );

  React.useEffect(() => {
    if (requiredMatchItemId) {
      hydrateMatchItem(requiredMatchItemId);
    }
  }, [hydrateMatchItem, requiredMatchItemId]);

  return { hydrateMatchItem, matchItemMap };
};

export default useMatchItemMap;
