import React from "react";
import { components } from "../types/openapi";
import { ApiDataContext } from "../provider/apiData";
import { KunciContext } from "../inc/kunci";

const useSearchTopicMap = () => {
  const { searchTopicMap, setSearchTopicMap } =
    React.useContext(ApiDataContext);
  const { axios } = React.useContext(KunciContext);

  const hydrateById = React.useCallback(
    (requiredSearchTopicId: string) => {
      // An old searchTopic may not exist in the default searchtopic list, load it manually
      if (
        searchTopicMap &&
        searchTopicMap[requiredSearchTopicId] !== undefined
      ) {
        return;
      }
      setSearchTopicMap((searchTopicMap) => ({
        ...searchTopicMap,
        [requiredSearchTopicId]: null,
      }));
      axios
        .request<components["schemas"]["SearchTopic"]>({
          method: "get",
          url: `/searchTopic/crud/${requiredSearchTopicId}`,
        })
        .then((res) => {
          setSearchTopicMap((searchTopicMap) => ({
            ...searchTopicMap,
            [requiredSearchTopicId]: res.data,
          }));
        });
    },
    [axios, searchTopicMap, setSearchTopicMap]
  );

  return { searchTopicMap, hydrateById };
};

export default useSearchTopicMap;
