import * as React from "react";
import { KunciContext } from "../inc/kunci";
import { addUserActionIfNeeded } from "../inc/matchItemHit";
import toaster from "../inc/toaster";
import { Intent } from "@blueprintjs/core/lib/esm/common/intent";
import { components } from "../types/openapi";
import { IWebSocketMessage, TUserProfile } from "../types/extendedOpenapi";
import { useNavigate } from "react-router-dom";
import { Dispatch, SetStateAction } from "react";
import { AxiosError } from "axios";
import { emptyFn } from "../inc/data";
import { WebSocketContext } from "./webSocket";

export interface IHashMap<T> {
  [id: string]: T;
}

export interface IClaim {
  date: Date;
  hit: components["schemas"]["MatchItemHit"];
  actions: components["schemas"]["MatchItemHitAction"][];
  searchTerms: components["schemas"]["SearchTerm"][];
}

interface IApiDataContext {
  checkPreferences?: IHashMap<components["schemas"]["CheckPreference"]> | null;
  claimHitById: (matchItemHitId: string) => Promise<void>;
  claimNextHit: () => Promise<void>;
  counters: components["schemas"]["CountersResult"];
  currentClaimedMatchItemHit?: IClaim | null;
  currentSearchTopicClaim?: components["schemas"]["SearchTopicClaim"] & {
    isForced?: boolean;
  };
  claimedMatchItemHits?: components["schemas"]["MatchItemHit"][] | null;
  deleteRequests?: IHashMap<components["schemas"]["DeleteRequest"]> | null;
  domainTypeMap?: IHashMap<components["schemas"]["DomainType"]> | null;
  matchItemMap: IHashMap<components["schemas"]["MatchItem"] | null>;
  nextMatchItemId?: string | undefined;
  previousSearchTopicClaim?: components["schemas"]["SearchTopicClaim"];
  publicationIssueMap: IHashMap<
    components["schemas"]["PublicationIssue"] | null
  >;
  publicationTypeMap?: IHashMap<
    components["schemas"]["PublicationType"]
  > | null;
  searchTopicClaims?: components["schemas"]["SearchTopicClaim"][] | null;
  searchTopicResults?: IHashMap<
    components["schemas"]["SearchTopicResult"]
  > | null;
  searchTopicMap?: IHashMap<components["schemas"]["SearchTopic"] | null> | null;
  setCheckPreferences: Dispatch<
    SetStateAction<
      IHashMap<components["schemas"]["CheckPreference"]> | null | undefined
    >
  >;
  setCurrentClaimedMatchItemHit: Dispatch<
    SetStateAction<IClaim | undefined | null>
  >;
  setCurrentSearchTopicClaim: Dispatch<
    SetStateAction<components["schemas"]["SearchTopicClaim"] | undefined>
  >;
  setClaimedMatchItemHits: Dispatch<
    SetStateAction<components["schemas"]["MatchItemHit"][] | undefined | null>
  >;
  setDeleteRequests: Dispatch<
    SetStateAction<
      IHashMap<components["schemas"]["DeleteRequest"]> | undefined | null
    >
  >;
  setDomainTypeMap: (
    publicationTypeMap: IHashMap<components["schemas"]["DomainType"]> | null
  ) => void;
  setMatchItemMap: Dispatch<
    SetStateAction<IHashMap<components["schemas"]["MatchItem"] | null>>
  >;
  setNextSearchTopicClaim: (
    searchTopicClaim: components["schemas"]["SearchTopicClaim"],
    isTemporary?: boolean,
    isForced?: boolean
  ) => Promise<void>;
  setNextMatchItemId: Dispatch<SetStateAction<string | undefined>>;
  setPreviousSearchTopicClaim: Dispatch<
    SetStateAction<components["schemas"]["SearchTopicClaim"] | undefined>
  >;
  setPublicationIssueMap: Dispatch<
    SetStateAction<IHashMap<components["schemas"]["PublicationIssue"] | null>>
  >;
  setPrintPublicationTypeMap: (
    publicationTypeMap: IHashMap<
      components["schemas"]["PublicationType"]
    > | null
  ) => void;

  setSearchTopicMap: Dispatch<
    SetStateAction<
      IHashMap<components["schemas"]["SearchTopic"] | null> | null | undefined
    >
  >;
  setSearchTopicResults: Dispatch<
    SetStateAction<
      IHashMap<components["schemas"]["SearchTopicResult"]> | null | undefined
    >
  >;
  setProfile: (user: TUserProfile | undefined) => void;
  setSearchTopicClaims: Dispatch<
    SetStateAction<
      components["schemas"]["SearchTopicClaim"][] | null | undefined
    >
  >;
  setUsers: (user: IHashMap<components["schemas"]["User"]> | null) => void;
  submitClaimedHits: () => Promise<void>;
  submitMatchItemHits: (
    data: components["schemas"]["MatchItemHit"][],
    params?: { final: "true" }
  ) => Promise<void>;
  profile?: TUserProfile;
  updateCounters: (counters?: components["schemas"]["CountersResult"]) => void;
  updateMatchItemTitle: (newTitle: string) => void;
  users?: IHashMap<components["schemas"]["User"]> | null;
}

const initialCountersResult = {
  backSearchActive: false,
  backsearchToBeChecked: 0,
  backsearchToBeRead: 0,
  deleteRequestsToBeProcessed: 0,
  headLineActive: false,
  printActive: false,
  printToBeRead: 0,
  printToBeChecked: 0,
  printOnHold: 0,
  printOther: 0,
  webActive: false,
  webToBeRead: 0,
  webToBeChecked: 0,
  webOnHold: 0,
};

export const ApiDataContext = React.createContext<IApiDataContext>({
  claimHitById: emptyFn,
  claimNextHit: emptyFn,
  counters: initialCountersResult,
  matchItemMap: {},
  publicationIssueMap: {},
  setCheckPreferences: emptyFn,
  setMatchItemMap: emptyFn,
  setCurrentClaimedMatchItemHit: emptyFn,
  setClaimedMatchItemHits: emptyFn,
  setCurrentSearchTopicClaim: emptyFn,
  setDeleteRequests: emptyFn,
  setNextSearchTopicClaim: emptyFn,
  setNextMatchItemId: emptyFn,
  setPreviousSearchTopicClaim: emptyFn,
  setPrintPublicationTypeMap: emptyFn,
  setPublicationIssueMap: emptyFn,
  setSearchTopicMap: emptyFn,
  setProfile: emptyFn,
  setUsers: emptyFn,
  setDomainTypeMap: emptyFn,
  setSearchTopicClaims: emptyFn,
  setSearchTopicResults: emptyFn,
  submitMatchItemHits: emptyFn,
  submitClaimedHits: emptyFn,
  updateCounters: emptyFn,
  updateMatchItemTitle: emptyFn,
});

const ApiData = ({ children }: any) => {
  const { webSocket } = React.useContext(WebSocketContext);

  const [matchItemMap, setMatchItemMap] = React.useState<
    IHashMap<components["schemas"]["MatchItem"] | null>
  >({});
  const [profile, setProfile] = React.useState<TUserProfile | undefined>();
  const [users, setUsers] = React.useState<IHashMap<
    components["schemas"]["User"]
  > | null>();
  const [checkPreferences, setCheckPreferences] = React.useState<IHashMap<
    components["schemas"]["CheckPreference"]
  > | null>();
  const [deleteRequests, setDeleteRequests] = React.useState<IHashMap<
    components["schemas"]["DeleteRequest"]
  > | null>();
  const [publicationTypeMap, setPrintPublicationTypeMap] =
    React.useState<IHashMap<components["schemas"]["PublicationType"]> | null>();
  const [domainTypeMap, setDomainTypeMap] = React.useState<IHashMap<
    components["schemas"]["DomainType"]
  > | null>();
  const [publicationIssueMap, setPublicationIssueMap] = React.useState<
    IHashMap<components["schemas"]["PublicationIssue"] | null>
  >({});
  const [searchTopicMap, setSearchTopicMap] = React.useState<IHashMap<
    components["schemas"]["SearchTopic"] | null
  > | null>();
  const [currentSearchTopicClaim, setCurrentSearchTopicClaim] = React.useState<
    components["schemas"]["SearchTopicClaim"] & {
      isForced?: boolean;
    }
  >();
  const [claimedMatchItemHits, setClaimedMatchItemHits] = React.useState<
    components["schemas"]["MatchItemHit"][] | null
  >();
  const [currentClaimedMatchItemHit, setCurrentClaimedMatchItemHit] =
    React.useState<IClaim | null>();
  const [counters, setCounters] = React.useState<
    components["schemas"]["CountersResult"]
  >(initialCountersResult);
  const [searchTopicClaims, setSearchTopicClaims] = React.useState<
    components["schemas"]["SearchTopicClaim"][] | null
  >();
  const [nextMatchItemId, setNextMatchItemId] = React.useState<string>();
  const [previousSearchTopicClaim, setPreviousSearchTopicClaim] =
    React.useState<components["schemas"]["SearchTopicClaim"]>();
  const [searchTopicResults, setSearchTopicResults] = React.useState<IHashMap<
    components["schemas"]["SearchTopicResult"]
  > | null>();

  const { auth, axios } = React.useContext(KunciContext);
  const navigate = useNavigate();
  const userName = auth?.jwt.userName;

  const claimHitById = React.useCallback(
    (matchItemHitId: string) => {
      if (!userName) {
        throw new Error("Cannot claim without logged in user");
      }
      setCurrentClaimedMatchItemHit(null);
      return Promise.all([
        axios.request<components["schemas"]["MatchItemHit"]>({
          method: "get",
          url: `/matchItemHit/crud/${matchItemHitId}`,
        }),
        axios.request<components["schemas"]["MatchItemHitAction"][]>({
          method: "get",
          url: `/matchItemHitAction/crud`,
          params: {
            matchItemHitIds: matchItemHitId,
          },
        }),
        axios.request<components["schemas"]["SearchTerm"][]>({
          method: "get",
          url: `/matchItemHit/${matchItemHitId}/searchTerms`,
        }),
      ])
        .then(([matchItemRes, actionRes, searchTermsRes]) => {
          if (matchItemRes.data.matchItemHitId === matchItemHitId) {
            setCurrentClaimedMatchItemHit({
              date: new Date(),
              hit: matchItemRes.data,
              actions: addUserActionIfNeeded(
                actionRes.data,
                userName,
                matchItemHitId
              ),
              searchTerms: searchTermsRes.data,
            });
            return;
          }
          throw new Error("Failed to retrieve matchItemHit by id");
        })
        .catch((err) => {
          toaster.show({
            message: `Laden artikel mislukt: ${err.message}`,
            intent: Intent.DANGER,
          });
          navigate("/");
        });
    },
    [axios, navigate, userName]
  );

  const claimNextHit = React.useCallback(() => {
    const userName = auth?.jwt.userName;
    return axios
      .request<components["schemas"]["MatchItemHit"]>({
        method: "post",
        url: "/matchItemHit/claim",
      })
      .then((claimRes) => {
        const matchItemHit = claimRes.data;
        if (userName && matchItemHit.matchItemHitId) {
          const matchItemHitIds = matchItemHit.matchItemHitId;
          return Promise.all([
            axios.request<components["schemas"]["MatchItemHitAction"][]>({
              method: "get",
              url: `/matchItemHitAction/crud`,
              params: {
                matchItemHitIds,
              },
            }),
            axios.request<components["schemas"]["SearchTerm"][]>({
              method: "get",
              url: `/matchItemHit/${matchItemHitIds}/searchTerms`,
            }),
          ]).then(([actionRes, searchTermsRes]) => {
            setCurrentClaimedMatchItemHit({
              date: new Date(),
              hit: claimRes.data,
              actions: addUserActionIfNeeded(
                actionRes.data,
                userName,
                matchItemHitIds
              ),
              searchTerms: searchTermsRes.data,
            });
            navigate(`/read/${matchItemHit.matchItemHitId}`);
          });
        }
        throw new Error("Did not receive MatchItemHit");
      });
  }, [axios, auth?.jwt.userName, navigate]);

  const updateCounters = React.useCallback(
    (newCounters?: components["schemas"]["CountersResult"]) => {
      if (newCounters) {
        setCounters(newCounters);
        return;
      }
      axios.get("/matchItemHit/counters").then((res) => {
        setCounters({
          ...res.data,
        });
      });
    },
    [axios]
  );

  const updateMatchItemTitle = React.useCallback(
    (clipTitle: string) => {
      const matchItem =
        matchItemMap && currentClaimedMatchItemHit
          ? matchItemMap[currentClaimedMatchItemHit.hit.matchItemId]
          : undefined;
      if (
        !currentClaimedMatchItemHit ||
        !matchItem ||
        matchItem.clipTitle === clipTitle
      ) {
        return;
      }
      setMatchItemMap({
        ...matchItemMap,
        [matchItem.matchItemId]: {
          ...matchItem,
          clipTitle,
        },
      });
      axios
        .request<components["schemas"]["MatchItem"]>({
          method: "put",
          url: `/matchItem/crud/${matchItem.matchItemId}`,
          params: {
            newTitle: clipTitle,
          },
        })
        .then((res) => {
          if (res.data.matchItemId === matchItem.matchItemId) {
            toaster.show({
              message: "Artikelwijziging opgeslagen",
              intent: Intent.SUCCESS,
            });
            return;
          }
          throw new Error("Update MatchItem title failed");
        })
        .catch((err) => {
          toaster.show({
            message: `Artikelwijziging opslaan mislukt: ${err.message}`,
            intent: Intent.DANGER,
          });
        });
    },
    [axios, currentClaimedMatchItemHit, matchItemMap]
  );

  const submitMatchItemHits = React.useCallback(
    (
      data: components["schemas"]["MatchItemHit"][],
      params?: { final: "true" }
    ) => {
      setClaimedMatchItemHits(null);
      try {
        webSocket.send(
          JSON.stringify({
            type: "monitoringPlusSearchTopicClaimUpdate",
            userName,
          } as IWebSocketMessage)
        );
      } catch (e) {
        console.log(e);
      }

      return axios
        .request({
          url: "/monitoringPlus/process",
          method: "post",
          data,
          params,
        })
        .then(() => {
          setSearchTopicClaims(undefined);
          setCurrentSearchTopicClaim(undefined);
          setClaimedMatchItemHits(undefined);
          setMatchItemMap({});
          setCurrentClaimedMatchItemHit(undefined);
        })
        .catch((_err: AxiosError) => {
          if (
            window.confirm(
              "Kan niet opslaan: claim is tussentijds overgenomen door iemand anders. Wil je toch afsluiten zonder op te slaan?"
            )
          ) {
            setSearchTopicClaims(undefined);
            setCurrentSearchTopicClaim(undefined);
            setClaimedMatchItemHits(undefined);
            setMatchItemMap({});
            setCurrentClaimedMatchItemHit(undefined);
            return;
          }
          setClaimedMatchItemHits(data);
        });
    },
    [axios, setSearchTopicClaims, userName, webSocket]
  );

  const submitClaimedHits = React.useCallback(
    (e?: React.MouseEvent<HTMLElement>) => {
      if (!claimedMatchItemHits) {
        return Promise.resolve();
      }
      const params =
        e && e.currentTarget?.dataset?.final
          ? { final: "true" as "true" }
          : undefined;

      if (params && !window.confirm("Weet u het zeker?")) {
        return Promise.resolve();
      }

      return submitMatchItemHits(claimedMatchItemHits, params);
    },
    [claimedMatchItemHits, submitMatchItemHits]
  );

  const setNextSearchTopicClaim = React.useCallback(
    async (
      nextSearchTopicClaim: components["schemas"]["SearchTopicClaim"],
      isTemporary?: boolean,
      isForced?: boolean
    ) => {
      const displayName = auth?.jwt.displayName || "";
      if (
        currentSearchTopicClaim &&
        currentSearchTopicClaim.searchTopicId ===
          nextSearchTopicClaim.searchTopicId
      ) {
        if (currentSearchTopicClaim.printClaimedByUserName === displayName) {
          delete nextSearchTopicClaim.printClaimedByUserName;
        }
        if (currentSearchTopicClaim.webClaimedByUserName === displayName) {
          delete nextSearchTopicClaim.webClaimedByUserName;
        }
      }
      setPreviousSearchTopicClaim((previousSearchTopicClaim) =>
        isTemporary
          ? previousSearchTopicClaim || currentSearchTopicClaim
          : undefined
      );
      setCurrentSearchTopicClaim({
        ...nextSearchTopicClaim,
        isForced,
      });
    },
    [auth?.jwt.displayName, currentSearchTopicClaim]
  );

  const updateSearchTopicList = React.useCallback(
    (force?: boolean) => {
      if (searchTopicMap === undefined) {
        setSearchTopicMap(null);
      } else {
        if (!force) {
          return;
        }
      }
      axios
        .request<components["schemas"]["SearchTopicList"]>({
          method: "get",
          url: "/searchTopic/crud",
        })
        .then((res) => {
          if (!res.data.searchTopics) {
            throw new Error("Missing searchtopics in response");
          }

          // Do not "forget" any (old?) searchTopics that may have been loaded on request
          setSearchTopicMap((searchTopicMap) =>
            res.data.searchTopics.reduce<
              IHashMap<components["schemas"]["SearchTopic"] | null>
            >((prev, searchTopic) => {
              prev[searchTopic.searchTopicId] = searchTopic;
              return prev;
            }, searchTopicMap || {})
          );
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") {
            console.log(err);
            setSearchTopicMap(
              {} as IHashMap<components["schemas"]["SearchTopic"]>
            );
          }
          toaster.show({
            message: `Ophalen zoekopdrachten mislukt: ${err.message}`,
            intent: Intent.DANGER,
          });
        });
    },
    [axios, searchTopicMap, setSearchTopicMap]
  );

  React.useEffect(() => {
    if (searchTopicMap === undefined) {
      updateSearchTopicList();
    }
    const interval = setInterval(() => updateSearchTopicList(true), 3600000);
    return () => {
      clearInterval(interval);
    };
  }, [searchTopicMap, updateSearchTopicList]);

  return (
    <ApiDataContext.Provider
      value={{
        checkPreferences,
        claimHitById,
        claimNextHit,
        claimedMatchItemHits,
        counters,
        currentClaimedMatchItemHit,
        currentSearchTopicClaim,
        deleteRequests,
        matchItemMap,
        nextMatchItemId,
        previousSearchTopicClaim,
        profile,
        publicationIssueMap,
        publicationTypeMap,
        domainTypeMap,
        searchTopicClaims,
        searchTopicMap,
        searchTopicResults,
        setCheckPreferences,
        setClaimedMatchItemHits,
        setCurrentClaimedMatchItemHit,
        setCurrentSearchTopicClaim,
        setDeleteRequests,
        setDomainTypeMap,
        setMatchItemMap,
        setNextMatchItemId,
        setNextSearchTopicClaim,
        setPreviousSearchTopicClaim,
        setProfile,
        setPublicationIssueMap,
        setPrintPublicationTypeMap,
        setSearchTopicClaims,
        setSearchTopicMap,
        setSearchTopicResults,
        setUsers,
        submitClaimedHits,
        submitMatchItemHits,
        updateCounters,
        updateMatchItemTitle,
        users,
      }}
    >
      {children}
    </ApiDataContext.Provider>
  );
};

export default ApiData;
