import React from "react";
import { KunciContext } from "../../inc/kunci";
import { MosaicWindow } from "react-mosaic-component";
import { components } from "../../types/openapi";
import CenteredSpinner from "../../components/CenteredSpinner";
import { matchItemHitWindowComponents } from "../../components/mosaicWindows";
import { ApiDataContext } from "../../provider/apiData";
import { KeyboardActions } from "../../inc/keyboard";
import { LayoutContext } from "../../provider/layout";
import SpeedReaderMosaic from "../../components/SpeedReaderMosaic";
import useClaimedMatchItemHits from "../../hooks/useClaimedMatchItemHits";
import { WebSocketContext } from "../../provider/webSocket";
import { IWebSocketMessage } from "../../types/extendedOpenapi";

const MonitoringPlus = () => {
  const { auth } = React.useContext(KunciContext);
  const {
    currentClaimedMatchItemHit,
    nextMatchItemId,
    previousSearchTopicClaim,
    setClaimedMatchItemHits,
    setCurrentClaimedMatchItemHit,
    setNextMatchItemId,
    setNextSearchTopicClaim,
    setSearchTopicClaims,
    submitMatchItemHits,
  } = React.useContext(ApiDataContext);
  const { isMatchItemHitVisible, openMonitoringPlusHit } =
    React.useContext(LayoutContext);
  const { webSocket } = React.useContext(WebSocketContext);

  const claimedMatchItemHits = useClaimedMatchItemHits();

  const filteredClaimedMatchItemHits = React.useMemo(
    () =>
      claimedMatchItemHits
        ? claimedMatchItemHits.filter(isMatchItemHitVisible)
        : [],
    [claimedMatchItemHits, isMatchItemHitVisible]
  );

  const openNextHit = React.useCallback(
    (isPrevious?: boolean) => {
      if (!filteredClaimedMatchItemHits || previousSearchTopicClaim) {
        return;
      }
      const currentIndex = filteredClaimedMatchItemHits.findIndex(
        (claimedMatchItemHit) =>
          claimedMatchItemHit.matchItemHitId ===
          currentClaimedMatchItemHit?.hit.matchItemHitId
      );
      const nextIndex = currentIndex + (isPrevious ? -1 : 1);
      if (
        currentIndex >= 0 &&
        nextIndex >= 0 &&
        filteredClaimedMatchItemHits.length > nextIndex
      ) {
        openMonitoringPlusHit(
          filteredClaimedMatchItemHits[nextIndex].matchItemHitId
        );
      }
    },
    [
      filteredClaimedMatchItemHits,
      currentClaimedMatchItemHit?.hit.matchItemHitId,
      openMonitoringPlusHit,
      previousSearchTopicClaim,
    ]
  );

  const processHit = React.useCallback(
    async (result: components["schemas"]["MatchItemHitActionResult"]) => {
      if (!claimedMatchItemHits) {
        return;
      }
      let monitoringPlusResult = result;
      if (monitoringPlusResult === "approved") {
        monitoringPlusResult = "approving";
      }
      if (monitoringPlusResult === "rejected") {
        monitoringPlusResult = "rejecting";
      }
      const matchItemHitId = currentClaimedMatchItemHit?.hit.matchItemHitId;
      if (previousSearchTopicClaim) {
        await submitMatchItemHits(
          claimedMatchItemHits.map((claimedMatchItemHit) =>
            claimedMatchItemHit.matchItemHitId === matchItemHitId
              ? {
                  ...claimedMatchItemHit,
                  monitoringPlusResult,
                }
              : claimedMatchItemHit
          )
        );
        setNextSearchTopicClaim(previousSearchTopicClaim, false);
        setNextMatchItemId(currentClaimedMatchItemHit?.hit.matchItemId);
        return;
      }
      setClaimedMatchItemHits((claimedMatchItemHits) =>
        claimedMatchItemHits
          ? claimedMatchItemHits.map((claimedMatchItemHit) =>
              claimedMatchItemHit.matchItemHitId === matchItemHitId
                ? {
                    ...claimedMatchItemHit,
                    monitoringPlusResult,
                  }
                : claimedMatchItemHit
            )
          : claimedMatchItemHits
      );
      openNextHit();
    },
    [
      claimedMatchItemHits,
      currentClaimedMatchItemHit?.hit.matchItemHitId,
      currentClaimedMatchItemHit?.hit.matchItemId,
      openNextHit,
      previousSearchTopicClaim,
      setClaimedMatchItemHits,
      setNextMatchItemId,
      setNextSearchTopicClaim,
      submitMatchItemHits,
    ]
  );

  const doKeyboardAction = React.useCallback(
    (actionId: keyof typeof KeyboardActions) => {
      switch (actionId) {
        case KeyboardActions.HIT_APPROVE:
          processHit("approving");
          break;

        case KeyboardActions.HIT_REJECT:
          processHit("rejecting");
          break;

        case KeyboardActions.HIT_SKIP:
          processHit("none");
          break;

        case KeyboardActions.MONITORING_PLUS_PREVIOUS_HIT:
          openNextHit(true);
          break;

        case KeyboardActions.MONITORING_PLUS_NEXT_HIT:
          openNextHit();
          break;
      }
    },
    [openNextHit, processHit]
  );

  React.useEffect(() => {
    setCurrentClaimedMatchItemHit(undefined);
  }, [setCurrentClaimedMatchItemHit]);

  React.useEffect(() => {
    if (!currentClaimedMatchItemHit && claimedMatchItemHits?.length) {
      const nextHit = claimedMatchItemHits.find(
        (hit) => hit.matchItemId === nextMatchItemId
      );
      openMonitoringPlusHit(
        nextHit
          ? nextHit.matchItemHitId
          : claimedMatchItemHits[0].matchItemHitId
      );
      if (nextMatchItemId !== undefined) {
        setNextMatchItemId(undefined);
      }
    }
  }, [
    claimedMatchItemHits,
    currentClaimedMatchItemHit,
    nextMatchItemId,
    openMonitoringPlusHit,
    setNextMatchItemId,
  ]);

  const userName = auth?.jwt.userName || "";

  React.useEffect(() => {
    const onMessage = async (webSocketMessage: MessageEvent<Blob>) => {
      if (!userName) {
        return;
      }
      let message: IWebSocketMessage;
      try {
        message = JSON.parse(await webSocketMessage.data.text());
      } catch (e) {
        console.log(e);
        return;
      }

      if (message.userName !== userName) {
        setSearchTopicClaims(undefined);
      }
    };
    webSocket.addEventListener("message", onMessage);
    return () => {
      webSocket.removeEventListener("message", onMessage);
    };
  }, [setSearchTopicClaims, userName, webSocket]);

  if (!auth) {
    return <CenteredSpinner />;
  }
  const { userRoles } = auth.jwt as components["schemas"]["Jwt"];
  const isMonitoringPlus = userRoles.indexOf("monitoringPlusUser") >= 0;

  if (!isMonitoringPlus) {
    return <div>Geen toegang</div>;
  }

  return (
    <SpeedReaderMosaic
      appDataNodeName="monitoringPlusNode"
      className="monitoring-plus"
      doKeyboardAction={doKeyboardAction}
    >
      {(matchItemHitWindowType, path) => {
        const HitWindowComponent =
          matchItemHitWindowComponents[matchItemHitWindowType];
        return (
          <MosaicWindow<number>
            title={"-"}
            path={path}
            toolbarControls={[]}
            draggable={false}
            className={`mosaic-window--type${matchItemHitWindowType}`}
          >
            <HitWindowComponent processHit={processHit} />
          </MosaicWindow>
        );
      }}
    </SpeedReaderMosaic>
  );
};

export default MonitoringPlus;
