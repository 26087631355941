import { Spinner } from "@blueprintjs/core";
import * as React from "react";

const CenteredSpinner = () => (
  <div
    style={{
      display: "flex",
      height: "100%",
      width: "100%",
      justifyContent: "center",
    }}
  >
    <Spinner />
  </div>
);

export default CenteredSpinner;
