import Text from "./Text";
import Form from "./Form";
import HitSource from "./HitSource";
import SearchTopics from "./SearchTopics";
import MonitoringPlus from "./MonitoringPlus";
import CheckTitle from "./CheckTitle";
import DeleteRequest from "./DeleteRequest";
import "./index.scss";
import { components } from "../../types/openapi";
import ReadResult from "./ReadResult";

export enum HITWINDOWTYPE {
  TEXT,
  FORM,
  HITSOURCE,
  SEARCHTOPIC,
  CHECK_TITLE,
  MONITORING_PLUS,
  DELETE_REQUEST,
  READ_RESULT,
}

export const matchItemHitWindowComponents = [
  Text,
  Form,
  HitSource,
  SearchTopics,
  CheckTitle,
  MonitoringPlus,
  DeleteRequest,
  ReadResult,
];

export interface IReadHitWindowProps {
  processDeleteRequest?: (
    action: components["schemas"]["DeleteRequest"]["deleteRequestStatus"],
    comment?: string
  ) => void;
  processHit?: (
    action: "approved" | "skipped" | "rejected" | "isError" | "isNoAction",
    comment?: string
  ) => void;
  onForcedTitleCheckSubmit?: (newTitle: string) => void;
}
