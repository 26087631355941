import React, { CSSProperties } from "react";
import "./index.scss";
import { SchemaObject } from "openapi3-ts";
import { uniq } from "lodash";
import { localeFormat } from "../../inc/date";
import {
  VariableSizeList,
  VariableSizeGrid,
  GridOnScrollProps,
} from "react-window";
import { LayoutContext } from "../../provider/layout";
import { Button, FormGroup, InputGroup } from "@blueprintjs/core";
import { t } from "../../inc/i18n";
import ColumnResizer from "./ColumnResizer";

export interface IColumnConfig<T> {
  align?: "left" | "center" | "right";
  flexGrow?: number;
  hidden?: boolean;
  hoverTitle?: string;
  renderCell?: (rowData: T) => React.ReactElement | null;
  renderData?: (rowData: T, index: number) => string;
  sort?: (a: T, b: T, sortAsc: boolean) => number;
  sortable?: boolean;
  title?: string | React.ReactElement;
  width?: number;
  order?: number;
}

interface ISchemaTableProps<T> {
  actionWidth?: number;
  config?: {
    [propName: string]: IColumnConfig<T>;
  };
  data: T[];
  defaultSortAsc?: boolean;
  defaultSortColumn?: string;
  getRowClassName?: (object: T) => string;
  isFullScreen?: boolean;
  isSearchable?: boolean;
  isSortable?: boolean;
  onScroll?: (props: GridOnScrollProps) => any;
  rowHeight?: number;
  schema: SchemaObject;
  scrollTop?: number;
  style?: CSSProperties;
}

export interface IRenderData {
  _index: number;
  [key: string]: any;
}

function SchemaTable<T>({
  actionWidth = 150,
  config,
  data,
  defaultSortAsc = false,
  defaultSortColumn,
  getRowClassName,
  isFullScreen,
  isSearchable,
  isSortable,
  onScroll,
  rowHeight = 40,
  schema,
  scrollTop,
  style,
}: ISchemaTableProps<T>) {
  const { windowInnerHeight, windowOuterWidth } =
    React.useContext(LayoutContext);
  const [renderData, setRenderData] = React.useState<IRenderData[]>();
  const [sortColumn, setSortColumn] = React.useState<string | undefined>(
    defaultSortColumn
  );
  const [sortAsc, setSortAsc] = React.useState<boolean>(defaultSortAsc);
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const [columnWidths, setColumnWidths] = React.useState<number[]>();

  const { properties = {} } = schema;
  const columnNames = React.useMemo<string[]>(() => {
    const columns = uniq([
      ...Object.keys(properties),
      ...Object.keys(config || {}),
    ]) as string[];
    if (!config) {
      return columns;
    }

    const invisibleColumns = Object.entries(config).reduce<string[]>(
      (prev, [propName, propConfig]) => {
        if (propConfig.hidden) {
          prev.push(propName);
        }
        return prev;
      },
      []
    );

    return columns
      .filter((key) => !invisibleColumns.includes(key))
      .sort((columnA, columnB) => {
        let orderA = config[columnA] ? config[columnA].order : undefined;
        if (orderA === undefined) {
          orderA = Object.keys(properties).findIndex(
            (propName) => propName === columnA
          );
        }
        let orderB = config[columnB] ? config[columnB].order : undefined;
        if (orderB === undefined) {
          orderB = Object.keys(properties).findIndex(
            (propName) => propName === columnB
          );
        }
        if (orderA === -1) {
          return 1;
        }
        if (orderB === -1) {
          return -1;
        }
        return orderA - orderB;
      });
  }, [config, properties]);

  React.useEffect(() => {
    setRenderData(
      data
        ? data.map((object, rowIndex) =>
            columnNames.reduce(
              (prev: IRenderData, propName) => {
                const schema: SchemaObject = properties[propName];
                const rawValue = object[propName as keyof T] as any;
                const propConfig = config ? config[propName] : undefined;

                if (propConfig && propConfig.renderData) {
                  prev[propName] = propConfig.renderData(
                    object,
                    rowIndex
                  ) as string;
                  return prev;
                }

                if (!schema) {
                  prev[propName] = "?";
                  return prev;
                }

                // @ts-ignore
                switch (schema.type) {
                  case "array":
                    prev[propName] = rawValue
                      .map((value: string) => t(value))
                      .join(", ");
                    return prev;

                  case "boolean":
                    prev[propName] = rawValue ? "Ja" : "Nee";
                    return prev;

                  case "integer":
                    prev[propName] = rawValue ? `${rawValue}` : "";
                    return prev;

                  case "number":
                    prev[propName] = rawValue || 0;
                    return prev;

                  // @ts-ignore
                  case "string":
                    if (schema.format === "date" && rawValue) {
                      prev[propName] =
                        (rawValue as string) === "2999-12-31"
                          ? "-"
                          : localeFormat(new Date(rawValue), "dd-MM-yyyy");
                      return prev;
                    }
                    if (schema.format === "date-time" && rawValue) {
                      prev[propName] = localeFormat(
                        new Date(rawValue),
                        "dd-MM-yyyy HH:mm"
                      );
                      return prev;
                    }
                    if (schema.enum) {
                      prev[propName] = t(rawValue);
                      return prev;
                    }
                  // fallthrough

                  default:
                    prev[propName] = rawValue ? `${rawValue}` : "";
                    return prev;
                }
              },
              { _index: rowIndex }
            )
          )
        : undefined
    );
  }, [columnNames, config, data, properties]);

  const gridWidth = windowOuterWidth - 22;
  const columnCount = columnNames.length;

  const { dynamicWidthColumnCount, fixedWidthColumnsWidth } =
    React.useMemo(() => {
      let fixedWidthColumnsWidth = 0;
      let dynamicWidthColumnCount = 0;
      columnNames.forEach((propName) => {
        const propConfig = config ? config[propName] : undefined;
        if (propConfig?.width) {
          fixedWidthColumnsWidth += propConfig.width + 8;
        } else {
          dynamicWidthColumnCount += 1;
        }
      }, 0);

      return { dynamicWidthColumnCount, fixedWidthColumnsWidth };
    }, [columnNames, config]);

  React.useEffect(() => {
    if (columnWidths) {
      // only needed for initial width detection, don't update e.g. after resizing
      return;
    }
    const dynamicColumnWidth =
      Math.floor(
        (gridWidth - fixedWidthColumnsWidth) / dynamicWidthColumnCount
      ) - 8;
    const newColumnWidths = columnNames.map((propName) => {
      const propConfig = config ? config[propName] : undefined;
      return propConfig?.width || dynamicColumnWidth;
    });
    setColumnWidths(newColumnWidths);
  }, [
    actionWidth,
    columnNames,
    columnWidths,
    config,
    dynamicWidthColumnCount,
    fixedWidthColumnsWidth,
    gridWidth,
  ]);

  const getColumnWidth = React.useCallback(
    (columnIndex: number) => (columnWidths ? columnWidths[columnIndex] : 1),
    [columnWidths]
  );

  const Th = React.useCallback(
    ({ index, style }: any) => {
      const propName = columnNames[index];
      const schema: SchemaObject = properties[propName];
      const propConfig = config ? config[propName] : undefined;
      const thDivProps = {
        key: propName,
        style,
        className: `p-2 components__schema-table__th`,
      };

      const setColumnDelta = (columnDelta: number) => {
        if (!columnWidths) {
          throw new Error("Missing column widths?");
        }
        const newColumnWidths = columnWidths.map(
          (columnWidth, columnWidthIndex) => {
            if (columnWidthIndex === index) {
              return columnWidth + columnDelta;
            }
            if (columnWidthIndex - 1 === index) {
              return columnWidth - columnDelta;
            }
            return columnWidth;
          }
        );
        setColumnWidths(newColumnWidths);
      };

      if (propConfig?.title) {
        return (
          <div {...thDivProps}>
            {propConfig?.title}
            {columnNames.length - 1 === index ? null : (
              <ColumnResizer setColumnDelta={setColumnDelta} />
            )}
          </div>
        );
      }
      if (!schema) {
        return (
          <div {...thDivProps}>
            {columnNames.length - 1 === index ? null : (
              <ColumnResizer setColumnDelta={setColumnDelta} />
            )}
          </div>
        );
      }

      switch (propConfig?.align) {
        case "left":
          thDivProps.className += " text-start";
          break;

        case "center":
          thDivProps.className += " text-center";
          break;

        case "right":
          thDivProps.className += " text-end";
          break;

        default:
          switch (schema.type) {
            case "boolean":
              thDivProps.className += " text-center";
              break;
            case "integer":
            case "number":
              thDivProps.className += " text-end";
              break;
            case "string":
              if (
                schema.format &&
                ["date", "date-time"].indexOf(schema.format) >= 0
              ) {
                thDivProps.className += " text-end";
              }
          }
      }

      return (
        <div {...thDivProps}>
          {isSortable ? (
            <Button
              className="px-0"
              onClick={() => {
                setSortColumn(propName);
                setSortAsc((sortAsc) => !sortAsc);
              }}
            >
              {t(propName)}
              {sortColumn === propName ? (sortAsc ? "↑" : "↓") : null}
            </Button>
          ) : (
            t(propName)
          )}
          {columnNames.length - 1 === index ? null : (
            <ColumnResizer setColumnDelta={setColumnDelta} />
          )}
        </div>
      );
    },
    [
      columnNames,
      columnWidths,
      config,
      isSortable,
      properties,
      sortAsc,
      sortColumn,
    ]
  );

  // Filter the data based on the searchQuery
  const filteredRenderData = React.useMemo(() => {
    if (!renderData || searchQuery === "") {
      return renderData;
    }
    const lcQuery = searchQuery.toLowerCase();
    return renderData.filter((item) => {
      return !!columnNames.find((columnName) =>
        // @ts-ignore
        `${item[columnName]}`.toLowerCase().includes(lcQuery)
      );
    });
  }, [columnNames, renderData, searchQuery]);

  // Sort the filtered data
  const sortedRenderData = React.useMemo(() => {
    if (!sortColumn || !filteredRenderData) {
      return filteredRenderData;
    }
    const sortSchema = properties[sortColumn as string] as
      | SchemaObject
      | undefined;
    return filteredRenderData.sort((a, b) => {
      const sortByValue =
        !sortSchema ||
        sortSchema.type === "boolean" ||
        sortSchema.format === "date" ||
        sortSchema.format === "date-time";
      const x = sortByValue
        ? // @ts-ignore
          data[a._index][sortColumn]
        : a[sortColumn as string];
      const y: any = sortByValue
        ? // @ts-ignore
          data[b._index][sortColumn]
        : b[sortColumn as string];
      if (x === y) {
        return 0;
      }
      return (x < y ? 1 : -1) * (sortAsc ? 1 : -1);
    });
  }, [data, filteredRenderData, properties, sortAsc, sortColumn]);

  const Td = React.useCallback(
    ({ columnIndex, rowIndex, style }: any) => {
      if (!sortedRenderData) {
        return null;
      }
      const propName = columnNames[columnIndex];
      const row = sortedRenderData[rowIndex];
      const schema: SchemaObject = properties[propName];
      const propConfig = config ? config[propName] : undefined;
      const object = data[row._index];
      const rowClassName =
        getRowClassName && object ? getRowClassName(object) : undefined;
      const tdDivProps = {
        key: propName,
        style,
        className: `${
          rowClassName ? `${rowClassName} ` : ""
        }p-2 components__schema-table__td components__schema-table__td--${
          rowIndex % 2 ? "odd" : "even"
        }`,
      };

      switch (propConfig?.align) {
        case "left":
          tdDivProps.className += " text-start";
          break;

        case "center":
          tdDivProps.className += " text-center";
          break;

        case "right":
          tdDivProps.className += " text-end";
          break;

        default:
          switch (schema?.type) {
            case "boolean":
              tdDivProps.className += " text-center";
              break;
            case "number":
            case "integer":
              tdDivProps.className += " text-end";
              break;

            case "string":
              if (schema.format === "date" || schema.format === "date-time") {
                tdDivProps.className += " text-end";
              }
          }
      }

      if (propConfig?.renderCell) {
        return (
          <div {...tdDivProps}>
            {data[row._index] ? propConfig.renderCell(data[row._index]) : null}
          </div>
        );
      }

      if (!propName) {
        return null;
      }

      return (
        <div {...tdDivProps} title={row[propName]}>
          {row[propName]}
        </div>
      );
    },
    [columnNames, config, data, getRowClassName, properties, sortedRenderData]
  );

  const getRowHeight = React.useCallback(() => rowHeight, [rowHeight]);
  const margin = 20;
  const width =
    (dynamicWidthColumnCount ? gridWidth : fixedWidthColumnsWidth) - 2 * margin;

  // Leave this! This needed for scroll pos restoration
  if (!sortedRenderData) {
    return null;
  }
  return (
    <div
      className="components__schema-table"
      style={{
        ...style,
        marginLeft: margin,
        width,
      }}
    >
      {isSearchable ? (
        <FormGroup
          className="mb-3"
          labelFor="searchQuery"
          style={{ width: width - 42 }}
        >
          <InputGroup
            leftIcon="filter"
            id="searchQuery"
            type="text"
            placeholder="Zoeken..."
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.currentTarget.value);
            }}
          />
        </FormGroup>
      ) : null}
      <div
        key={`thead_${width}_${sortColumn}_${sortAsc}_${searchQuery}_${
          columnWidths ? columnWidths.join("") : ""
        }`}
      >
        <VariableSizeList
          height={64}
          itemCount={columnCount}
          itemSize={getColumnWidth}
          layout="horizontal"
          width={width}
        >
          {Th}
        </VariableSizeList>
        <VariableSizeGrid
          className="components__schema-table__tbody"
          columnCount={columnCount}
          columnWidth={getColumnWidth}
          height={
            isFullScreen
              ? windowInnerHeight - (isSearchable ? 230 : 210)
              : data.length * rowHeight
          }
          onScroll={onScroll}
          rowCount={sortedRenderData.length}
          rowHeight={getRowHeight}
          initialScrollTop={scrollTop}
          width={width}
        >
          {Td}
        </VariableSizeGrid>
      </div>
    </div>
  );
}

export default React.memo(SchemaTable) as typeof SchemaTable;
