export const parseLocationSearch = <T extends {}>(search: string): T => {
  if (!search.length) {
    return {} as T;
  }
  const result = {} as T;
  search
    .substring(1)
    .split("&")
    .forEach((pair) => {
      if (!pair) {
        return;
      }
      const splitPair = pair.split("=");
      // @ts-ignore
      if (splitPair.length !== 2) {
        return;
      }

      // @ts-ignore
      result[decodeURIComponent(splitPair[0])] = decodeURIComponent(
        splitPair[1]
      );
    });
  return result;
};

export const serializeLocationSearch = (params: {}) =>
  `?${Object.entries(params)
    .map(([key, value]) =>
      value ? `${key}=${encodeURIComponent(value as string)}` : ""
    )
    .join("&")}`;

export const emptyFn = (): any => {};
