import React from "react";
import { Button, Callout } from "@blueprintjs/core";
import { Link } from "react-router-dom";
import { KunciContext } from "../../inc/kunci";
import { Intent } from "@blueprintjs/core/lib/esm/common/intent";

import { ApiDataContext } from "../../provider/apiData";
import CenteredSpinner from "../../components/CenteredSpinner";
import toaster from "../../inc/toaster";
import { components } from "../../types/openapi";
import useUsers from "../../hooks/useUsers";
import SchemaTable, { IColumnConfig } from "../../components/SchemaTable";
import openapi from "../../openapi.json";
import { SchemaObject } from "openapi3-ts";

const Users = () => {
  const { setUsers } = React.useContext(ApiDataContext);
  const { auth, axios } = React.useContext(KunciContext);

  const { userRoles } = auth?.jwt as components["schemas"]["Jwt"];
  const users = useUsers();
  const data = React.useMemo(
    () => (users ? Object.values(users) : []),
    [users]
  );

  const config = React.useMemo<{
    [propName: string]: IColumnConfig<components["schemas"]["User"]>;
  }>(
    () => ({
      userId: {
        hidden: true,
      },
      userName: {
        order: -10,
        width: 150,
      },
      displayName: {
        width: 200,
      },
      mustBeChecked: {
        width: 100,
      },
      excludedPublicationTypeIds: {
        align: "center",
        width: 150,
        renderData: (user: components["schemas"]["User"]) =>
          `${user.excludedPublicationTypeIds?.length || 0}`,
      },
      excludedDomainTypeIds: {
        align: "center",
        width: 150,
        renderData: (user: components["schemas"]["User"]) =>
          `${user.excludedDomainTypeIds?.length || 0}`,
      },
      "#": {
        width: 250,
        renderCell: (user: components["schemas"]["User"]) => {
          return (
            <>
              <Link to={`/user/${user.userId}`}>
                <Button intent={Intent.PRIMARY} text="Bewerken" small={true} />
              </Link>
              <Button
                intent={Intent.DANGER}
                text="Verwijderen"
                style={{ marginLeft: 8 }}
                small={true}
                onClick={() => {
                  if (
                    window.confirm(
                      "Weet je zeker dat je deze gebruiker wilt verwijderen?"
                    )
                  ) {
                    axios
                      .request({
                        method: "delete",
                        url: `/user/crud/${user.userId}`,
                      })
                      .then(() => {
                        toaster.show({
                          message: `Gebruiker verwijderd`,
                          intent: Intent.SUCCESS,
                        });
                        const newUsers = { ...users };
                        delete newUsers[user.userId as string];
                        setUsers(newUsers);
                      })
                      .catch((err) => {
                        toaster.show({
                          message: `Verwijderen gebruiker mislukt: ${err.message}`,
                          intent: Intent.DANGER,
                        });
                      });
                  }
                }}
              />
            </>
          );
        },
      },
    }),
    [axios, setUsers, users]
  );

  if (userRoles.indexOf("admin") === -1) {
    return (
      <Callout
        title="Pagina niet beschikbaar"
        intent={Intent.WARNING}
        style={{ margin: 8 }}
      >
        <p>U heeft niet voldoende rechten voor het openen van deze pagina.</p>
      </Callout>
    );
  }

  if (!users) {
    return <CenteredSpinner />;
  }

  return (
    <div style={{ paddingTop: 40 }}>
      <SchemaTable
        schema={openapi.components.schemas.User as SchemaObject}
        data={data}
        isFullScreen
        isSearchable
        isSortable
        config={config}
      />
    </div>
  );
};

export default Users;
